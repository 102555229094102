import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full mb-5" }
const _hoisted_2 = { class: "space-y-1 text-center" }
const _hoisted_3 = { class: "flex justify-center text-sm text-gray-600" }
const _hoisted_4 = {
  key: 0,
  class: "text-xs text-gray-500"
}
const _hoisted_5 = {
  key: 0,
  class: "text-theme-error mt-1"
}
const _hoisted_6 = { class: "w-full mb-5" }
const _hoisted_7 = { class: "w-full mb-5" }
const _hoisted_8 = { class: "w-full mb-5" }
const _hoisted_9 = { class: "w-full mb-5" }
const _hoisted_10 = { class: "w-full mb-5" }
const _hoisted_11 = { class: "w-full mb-5" }
const _hoisted_12 = { class: "w-full mb-5" }
const _hoisted_13 = { class: "w-full mb-5" }
const _hoisted_14 = { class: "w-full mb-5" }
const _hoisted_15 = { class: "flex" }

import { ref, onMounted } from 'vue'
import Admin from '@/composables/admin/index'
import { resetErrors, handleApiError, isImageFile, isVideoFile } from '@/utils/common'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'


export default /*@__PURE__*/_defineComponent({
  __name: 'SavePage',
  props: ['page'],
  emits: ['success', 'error'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const { createCar, updateCar } = Admin()
const isLoading = ref<boolean>(false)
const fileInput = ref<HTMLInputElement | null>(null)
const fileName = ref<string>('')
const { createPage, updatePage } = Admin()
const editorConfig : any = {
   
}
const editor = ClassicEditor
const form = ref({
   name: '',
   banner: {
      media: {
         type: '',
         data: ''
      },
      heading: '',
      subHeading: '',
      text: '',
      button: {
         title: '',
         url: ''
      }
   },
   section: {
      title: '',
      text: ''
   },
   showSupplierForm: '1'
})
const errors = ref({
   name: false,
   banner: {
      media: '',
      heading: false
   }
})

onMounted(() => {
   mounted() 
})

const mounted = async () => {

   if (props.page) {
      
      fileName.value = props.page.media_source ? props.page.media_source : ''
      form.value = {
         name: props.page.title,
         banner: {
            media: {
               type: '',
               data: ''
            },
            heading: props.page.banner_heading,
            subHeading: props.page.banner_sub_heading,
            text: props.page.banner_text,
            button: {
               title: props.page.banner_button_text,
               url: props.page.banner_button_url
            }
         },
         section: {
            title: props.page.section_title,
            text: props.page.section_info_text
         },
         showSupplierForm: props.page.show_supplier_form
      }
   }
}

const handleChoosePhoto = () => {
   fileInput.value?.click()
}

const uploadPhoto = (e: Event) => {

   form.value.banner.media.data = ''
   form.value.banner.media.type = ''
   fileName.value = ''
   errors.value.banner.media = ''

   const inputElement = e.target as HTMLInputElement

   if (!inputElement.files || inputElement.files.length === 0) {
      return
   }

   const file = inputElement.files[0]
   const reader = new FileReader()

   if (!isImageFile(file) && !isVideoFile(file)) {
      errors.value.banner.media = 'File is not a valid image or video'
   } else if (isImageFile(file) && file.size > 10485760) {
      errors.value.banner.media = 'Image must be under 10MB in size'
   } else if (isVideoFile(file) && file.size > 20971520) {
      errors.value.banner.media = 'Video must be under 20MB in size'
   }

   fileName.value = file.name

   reader.onloadend = () => {
      form.value.banner.media.data = reader.result as string
      form.value.banner.media.type = isImageFile(file) ? 'image' : 'video'
   }

   reader.readAsDataURL(file)
}

const validateForm = () : boolean => {

   let response = true

   if (form.value.name === '') {
      errors.value.name = true
      response = false
   }

   if (form.value.banner.heading === '') {
      errors.value.banner.heading = true
      response = false
   }
   
   if (form.value.banner.media.type == '' && props.page == null) {
      errors.value.banner.media = 'true'
      response = false
   }

   return response 
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid) {
      
      if (props.page == null) {

         isLoading.value = true
         const response = await createPage(form.value)
         isLoading.value = false
         if (response.status == 201) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }
      } else {

         isLoading.value = true
         const response = await updatePage(props.page.id, form.value)
         isLoading.value = false
         if (response.status == 200) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }
      }
   }
}


return (_ctx: any,_cache: any) => {
  const _component_ckeditor = _resolveComponent("ckeditor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        for: "document-file",
        class: _normalizeClass([errors.value.banner.media == '' ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
      }, " Banner Media ", 2),
      _createElementVNode("div", {
        class: _normalizeClass([errors.value.banner.media == '' ? 'border-gray-300' : 'border-theme-error', "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md"])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[11] || (_cache[11] = _createElementVNode("svg", {
            class: "mx-auto h-12 w-12 text-gray-400",
            stroke: "currentColor",
            fill: "none",
            viewBox: "0 0 48 48",
            "aria-hidden": "true"
          }, [
            _createElementVNode("path", {
              d: "M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02",
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", {
              onClick: handleChoosePhoto,
              class: "relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 outline-none"
            }, [
              _cache[10] || (_cache[10] = _createElementVNode("button", { type: "button" }, "Click to upload a file", -1)),
              _createElementVNode("input", {
                class: "hidden",
                ref_key: "fileInput",
                ref: fileInput,
                type: "file",
                onChange: uploadPhoto,
                accept: "image/*, video/*"
              }, null, 544)
            ])
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-xs text-gray-500" }, [
            _createElementVNode("span", null, "(Files up to 3MB)")
          ], -1)),
          (fileName.value != '')
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(fileName.value), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2),
      (errors.value.banner.media != '')
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(errors.value.banner.media), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", {
        for: "name",
        class: _normalizeClass([!errors.value.name ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
      }, "Page Name", 2),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.name) = $event)),
        type: "text",
        class: _normalizeClass([!errors.value.name ? 'border-gray-300' : 'border-theme-error', "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm"])
      }, null, 2), [
        [_vModelText, form.value.name]
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("label", {
        for: "name",
        class: _normalizeClass([!errors.value.banner.heading ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
      }, "Banner Heading", 2),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.banner.heading) = $event)),
        class: "mt-1 outline-none border rounded-sm h-24 w-full block p-2 text-gray-500 text-sm border-gray-30"
      }, null, 512), [
        [_vModelText, form.value.banner.heading]
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[13] || (_cache[13] = _createElementVNode("label", {
        for: "name",
        class: "block text-sm font-medium text-gray-700"
      }, "Banner Sub Heading", -1)),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.banner.subHeading) = $event)),
        type: "text",
        class: "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm border-gray-300"
      }, null, 512), [
        [_vModelText, form.value.banner.subHeading]
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _cache[14] || (_cache[14] = _createElementVNode("label", {
        for: "name",
        class: "block text-sm font-medium text-gray-700"
      }, "Banner Text", -1)),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.banner.text) = $event)),
        type: "text",
        class: "mt-1 outline-none border rounded-sm h-24 w-full block p-2 text-gray-500 text-sm border-gray-30"
      }, null, 512), [
        [_vModelText, form.value.banner.text]
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _cache[15] || (_cache[15] = _createElementVNode("label", {
        for: "name",
        class: "block text-sm font-medium text-gray-700"
      }, "Banner Button Text", -1)),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.banner.button.title) = $event)),
        type: "text",
        class: "border-gray-300 mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm"
      }, null, 512), [
        [_vModelText, form.value.banner.button.title]
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _cache[16] || (_cache[16] = _createElementVNode("label", {
        for: "name",
        class: "text-gray-700 block text-sm font-medium"
      }, "Banner Button URL", -1)),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((form.value.banner.button.url) = $event)),
        type: "text",
        class: "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm border-gray-300"
      }, null, 512), [
        [_vModelText, form.value.banner.button.url]
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _cache[17] || (_cache[17] = _createElementVNode("label", {
        for: "name",
        class: "text-gray-700 block text-sm font-medium"
      }, "Section title", -1)),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((form.value.section.title) = $event)),
        type: "text",
        class: "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm border-gray-300"
      }, null, 512), [
        [_vModelText, form.value.section.title]
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _cache[18] || (_cache[18] = _createElementVNode("label", {
        for: "name",
        class: "block text-sm font-medium text-gray-700"
      }, "Section Text", -1)),
      _createVNode(_component_ckeditor, {
        editor: _unref(editor),
        modelValue: form.value.section.text,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((form.value.section.text) = $event)),
        config: editorConfig
      }, null, 8, ["editor", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _cache[20] || (_cache[20] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-700" }, "Show partner form", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((form.value.showSupplierForm) = $event)),
        class: "block overflow-hidden mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6"
      }, _cache[19] || (_cache[19] = [
        _createElementVNode("option", { value: "1" }, "Yes", -1),
        _createElementVNode("option", { value: "0" }, "No", -1)
      ]), 512), [
        [_vModelSelect, form.value.showSupplierForm]
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("button", {
        onClick: _cache[9] || (_cache[9] = ($event: any) => (handleSubmit())),
        type: "button",
        class: "ml-auto mt-20 bg-indigo-600 py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 outline-none"
      }, " Save ")
    ])
  ], 64))
}
}

})