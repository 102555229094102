import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "bg-theme-gray-8",
  style: {"display":"none"}
}
const _hoisted_2 = { class: "absolute top-0 left-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-4 w-full" }
const _hoisted_3 = { class: "text-head-md md:text-head-xxl text-white font-light" }
const _hoisted_4 = { class: "text-white text-size-1 md:text-size-2 flex flex-row items-center gap-3" }
const _hoisted_5 = { class: "uppercase" }

import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TopBanner',
  props: ['layout', 'currentPage', 'heading', 'bannerClass'],
  setup(__props) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative", __props.layout == 'full-width' ? 'w-full' : 'w-[80%] mx-auto lg:w-theme-dw'])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["bg-center bg-no-repeat bg-cover", __props.bannerClass])
      }, null, 2),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "bg-[#858C9266] w-full h-full absolute top-0 left-0" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(__props.heading), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'Home' }))),
            class: "uppercase hover:underline cursor-pointer"
          }, "Home"),
          _cache[1] || (_cache[1] = _createTextVNode(" | ")),
          _createElementVNode("div", _hoisted_5, _toDisplayString(__props.currentPage), 1)
        ])
      ])
    ], 2)
  ]))
}
}

})