import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "space-y-10 divide-y divide-gray-900/10 py-10 px-4 sm:px-6 lg:px-24" }
const _hoisted_2 = { class: "grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3" }
const _hoisted_3 = { class: "bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" }
const _hoisted_4 = { class: "px-4 py-6 sm:p-8" }
const _hoisted_5 = { class: "grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6" }
const _hoisted_6 = { class: "sm:col-span-3" }
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = {
  key: 0,
  class: "mt-2 text-sm text-red-600"
}
const _hoisted_9 = { class: "sm:col-span-3" }
const _hoisted_10 = { class: "mt-2" }
const _hoisted_11 = { class: "sm:col-span-3" }
const _hoisted_12 = {
  for: "photo",
  class: "block text-sm font-medium leading-6 text-gray-900"
}
const _hoisted_13 = {
  key: 0,
  class: "text-red-600"
}
const _hoisted_14 = { class: "mt-2 flex items-center gap-x-3" }
const _hoisted_15 = ["src"]
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 2,
  class: "h-8 w-8 rounded-full bg-gray-50",
  src: "/assets/avatars/default.jpg",
  alt: ""
}
const _hoisted_18 = { class: "sm:col-span-3" }
const _hoisted_19 = { class: "mt-2" }
const _hoisted_20 = {
  key: 0,
  class: "mt-2 text-sm text-red-600"
}
const _hoisted_21 = { class: "sm:col-span-3" }
const _hoisted_22 = { class: "mt-2" }
const _hoisted_23 = {
  key: 0,
  class: "mt-2 text-sm text-red-600"
}
const _hoisted_24 = { class: "flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8" }
const _hoisted_25 = { class: "grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3" }
const _hoisted_26 = { class: "bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" }
const _hoisted_27 = { class: "px-4 py-6 sm:p-8" }
const _hoisted_28 = { class: "grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6" }
const _hoisted_29 = { class: "sm:col-span-6" }
const _hoisted_30 = { class: "mt-2" }
const _hoisted_31 = {
  key: 0,
  class: "mt-2 text-sm text-red-600"
}
const _hoisted_32 = { class: "sm:col-span-3" }
const _hoisted_33 = { class: "mt-2" }
const _hoisted_34 = {
  key: 0,
  class: "mt-2 text-sm text-red-600"
}
const _hoisted_35 = { class: "sm:col-span-3" }
const _hoisted_36 = { class: "mt-2" }
const _hoisted_37 = {
  key: 0,
  class: "mt-2 text-sm text-red-600"
}
const _hoisted_38 = { class: "flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8" }

import { ref, onMounted } from 'vue'
import Agent from '@/composables/admin/agent'
import Corporate from '@/composables/admin/corporate'
import { handleApiError, isImageFile, isLoggedIn, resetErrors, saveAuthCredentials, truncateText, updateAuthUser } from '@/utils/common'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Settings',
  props: ['userType'],
  setup(__props) {

const props = __props
const authUser = isLoggedIn()
const { agentProfile, agentProfileSave } = Agent()
const { corporateProfile, corporateProfileSave } = Corporate()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const isLoading = ref<boolean>(true)
const fileInput = ref<HTMLInputElement | null>(null)
const userAccount = ref<any>({
   personal: {
      fullName: '',
      email: '',
      photo: null,
      photoName: '',
      commission: 0,
      phone: '',
      company: ''
   },
   password: {
      current: '',
      new: '',
      confirmNew: ''
   }
})
const errors = ref<any>({
   personal: {
      fullName: null,
      commission: null,
      phone: null,
      company: null
   },
   password: {
      current: null,
      new: null,
      confirmNew: null
   }
})

onMounted(async () => {

   if (authUser && authUser.user.type == 'agent') {

      await fetchAgentProfile()
   } else if (authUser && authUser.user.type == 'corporate'){

      await fetchCorporateProfile()
   }
})

const fetchAgentProfile = async () => {

   isLoading.value = true
   const response = await agentProfile()
   isLoading.value = false

   if (response.status == 200) {
      userAccount.value.personal = {
         fullName: response.data.data.name,
         email: response.data.data.email,
         photoName: response.data.data.photo,
         commission: response.data.data.commission,
         phone: response.data.data.phone,
         company: response.data.data.company
      }
   } else {
      const error = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      setTimeout(() => { notifClosed() }, 4000)
   }
}

const fetchCorporateProfile = async () => {

   isLoading.value = true
   const response = await corporateProfile()
   isLoading.value = false

   if (response.status == 200) {
      userAccount.value.personal = {
         fullName: response.data.data.name,
         email: response.data.data.email,
         photoName: response.data.data.photo,
         commission: response.data.data.commission,
         phone: response.data.data.phone,
         company: response.data.data.company
      }
   } else {
      const error = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      setTimeout(() => { notifClosed() }, 4000)
   }
}

const handlePersonalSubmit = async () => {

   if (!isLoading.value) {

      resetErrors(errors.value)
      const isValid = validateForm('personal')
   
      if (isValid) {
   
         isLoading.value = true
         let response : any

         if (authUser && authUser.user.type == 'agent') {

            response = await agentProfileSave(userAccount.value.personal, 'personal')
         } else if (authUser && authUser.user.type == 'corporate') {

            response = await corporateProfileSave(userAccount.value.personal, 'personal')
         }

         isLoading.value = false
         if (response.status == 200) {
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Profile updated'}
            setTimeout(() => { notifClosed() }, 4000)
            
            if (authUser && authUser.user.type == 'agent') {

               await fetchAgentProfile()
            } else if (authUser && authUser.user.type == 'corporate') {

               await fetchCorporateProfile()
            }

            updateAuthUser(response.data.user)
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
            setTimeout(() => { notifClosed() }, 4000)
         } 
      }
   }
}

const handlePasswordSubmit = async () => {

   if (!isLoading.value) {

      resetErrors(errors.value)
      const isValid = validateForm('password')
   
      if (isValid) {
   
         isLoading.value = true
         let response : any

         if (authUser && authUser.user.type == 'agent') {

            response = await agentProfileSave(userAccount.value.password, 'password')
         } else if (authUser && authUser.user.type == 'corporate') {

            response = await corporateProfileSave(userAccount.value.password, 'password')
         }

         isLoading.value = false
         if (response.status == 200) {
            userAccount.value.password = {
               current: null,
               new: null,
               confirmNew: null
            }
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Password updated'}
            setTimeout(() => { notifClosed() }, 4000)
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
            setTimeout(() => { notifClosed() }, 4000)
         }
      }
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}

const validateForm = (type: string) : boolean => {

   let response = true

   if (type == 'personal') {

      if (userAccount.value.personal.fullName == '') {
         errors.value.personal.fullName = true
         response = false
      }
      if (userAccount.value.personal.commission < 0 || userAccount.value.personal.commission > 50) {
         errors.value.personal.commission = 'Invalid value. It must be between 1 and 50'
         response = false
      }
      if (userAccount.value.personal.phone == '') {
         errors.value.personal.phone = true
         response = false
      }
      if (userAccount.value.personal.company == '') {
         errors.value.personal.company = true
         response = false
      }
   } else if (type == 'password'){

      if (userAccount.value.password.current == '') {
         errors.value.password.current = true
         response = false
      }

      if (userAccount.value.password.new == '') {
         errors.value.password.new = 'This field is required'
         response = false
      } else if (userAccount.value.password.new.length < 6) {
         errors.value.password.new = 'Password must be at least 6 characters long'
         response = false
      }

      if (userAccount.value.password.confirmNew == '') {
         errors.value.password.confirmNew = 'This field is required'
         response = false
      } else if (userAccount.value.password.new != '' && userAccount.value.password.new != userAccount.value.password.confirmNew){
         errors.value.password.confirmNew = 'Confirm password must be the same as new password'
         response = false
      }
   }

   return response
}

const handleChooseAvatar = () => {
   fileInput.value?.click()
}

const uploadMedia = (e: Event) => {

   userAccount.value.personal.photo = null
   userAccount.value.personal.photoName = ''
   const inputElement = e.target as HTMLInputElement

   if (!inputElement.files || inputElement.files.length === 0) {
      return;
   }

   const file = inputElement.files[0]
   const reader = new FileReader()

   if (!isImageFile(file)) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'File must be an image of type JPG or PNG'}
   } else if (file.size > 3145728) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'Image must be 3MB or less'}
   }

   userAccount.value.personal.photoName = file.name

   reader.onloadend = () => {
      userAccount.value.personal.photo = reader.result as string
   }

   reader.readAsDataURL(file)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "px-4 sm:px-0" }, [
          _createElementVNode("h2", { class: "text-base font-semibold leading-7 text-gray-900" }, "Account Information"),
          _createElementVNode("p", { class: "mt-1 text-sm leading-6 text-gray-600" }, "Setup your personal info and commission")
        ], -1)),
        _createElementVNode("form", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[7] || (_cache[7] = _createElementVNode("label", {
                  for: "full-name",
                  class: "block text-sm font-medium leading-6 text-gray-900"
                }, "Full name *", -1)),
                _createElementVNode("div", _hoisted_7, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((userAccount.value.personal.fullName) = $event)),
                    type: "text",
                    id: "full-name",
                    class: _normalizeClass([errors.value.personal.fullName ? 'ring-red-600' : 'ring-gray-300', "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2"]),
                    autocomplete: "given-name"
                  }, null, 2), [
                    [_vModelText, userAccount.value.personal.fullName]
                  ])
                ]),
                (errors.value.personal.fullName)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, "This field is required"))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[8] || (_cache[8] = _createElementVNode("label", {
                  for: "email",
                  class: "block text-sm font-medium leading-6 text-gray-900"
                }, "Email", -1)),
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((userAccount.value.personal.email) = $event)),
                    type: "text",
                    readonly: "",
                    disabled: "",
                    id: "email",
                    autocomplete: "given-email",
                    class: "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2"
                  }, null, 512), [
                    [_vModelText, userAccount.value.personal.email]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("input", {
                  class: "hidden",
                  ref_key: "fileInput",
                  ref: fileInput,
                  type: "file",
                  onChange: uploadMedia,
                  accept: "image/*"
                }, null, 544),
                _createElementVNode("label", _hoisted_12, [
                  _cache[9] || (_cache[9] = _createTextVNode(" Photo ")),
                  (userAccount.value.personal.photo)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, "(" + _toDisplayString(_unref(truncateText)(userAccount.value.personal.photoName, 20)) + ")", 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  (userAccount.value.personal.photo)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "h-8 w-8 rounded-full bg-gray-50",
                        src: userAccount.value.personal.photo,
                        alt: ""
                      }, null, 8, _hoisted_15))
                    : (userAccount.value.personal.photoName != '')
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          class: "h-8 w-8 rounded-full bg-gray-50",
                          src: userAccount.value.personal.photoName,
                          alt: ""
                        }, null, 8, _hoisted_16))
                      : (_openBlock(), _createElementBlock("img", _hoisted_17)),
                  _createElementVNode("button", {
                    onClick: handleChooseAvatar,
                    type: "button",
                    class: "rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  }, _toDisplayString(userAccount.value.personal.photoName == '' ? 'Upload' : 'Change'), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _cache[10] || (_cache[10] = _createElementVNode("label", {
                  for: "full-name",
                  class: "block text-sm font-medium leading-6 text-gray-900"
                }, "Phone *", -1)),
                _createElementVNode("div", _hoisted_19, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((userAccount.value.personal.phone) = $event)),
                    type: "text",
                    id: "phone",
                    autocomplete: "given-phone",
                    class: _normalizeClass([errors.value.personal.fullName ? 'ring-red-600' : 'ring-gray-300', "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2"])
                  }, null, 2), [
                    [_vModelText, userAccount.value.personal.phone]
                  ])
                ]),
                (errors.value.personal.phone)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_20, "This field is required"))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_21, [
                _cache[11] || (_cache[11] = _createElementVNode("label", {
                  for: "company-name",
                  class: "block text-sm font-medium leading-6 text-gray-900"
                }, "Company Name *", -1)),
                _createElementVNode("div", _hoisted_22, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((userAccount.value.personal.company) = $event)),
                    type: "text",
                    id: "company-name",
                    class: _normalizeClass([errors.value.personal.fullName ? 'ring-red-600' : 'ring-gray-300', "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2"]),
                    autocomplete: "given-company"
                  }, null, 2), [
                    [_vModelText, userAccount.value.personal.company]
                  ])
                ]),
                (errors.value.personal.company)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_23, "This field is required"))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _cache[12] || (_cache[12] = _createElementVNode("p", { class: "mt-2 text-sm text-gray-900" }, "* required field", -1)),
            _createElementVNode("button", {
              onClick: handlePersonalSubmit,
              type: "button",
              class: _normalizeClass([isLoading.value ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer', "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 outlinne-none"])
            }, "Save", 2)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_25, [
        _cache[17] || (_cache[17] = _createElementVNode("div", { class: "px-4 sm:px-0" }, [
          _createElementVNode("h2", { class: "text-base font-semibold leading-7 text-gray-900" }, "Update Password"),
          _createElementVNode("p", { class: "mt-1 text-sm leading-6 text-gray-600" }, "You can update your account password")
        ], -1)),
        _createElementVNode("form", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                _cache[14] || (_cache[14] = _createElementVNode("label", {
                  for: "current-password",
                  class: "block text-sm font-medium leading-6 text-gray-900"
                }, "Current password", -1)),
                _createElementVNode("div", _hoisted_30, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((userAccount.value.password.current) = $event)),
                    type: "password",
                    id: "current-password",
                    autocomplete: "given-current-password",
                    class: _normalizeClass([errors.value.password.current ? 'ring-red-600' : 'ring-gray-300', "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2"])
                  }, null, 2), [
                    [_vModelText, userAccount.value.password.current]
                  ])
                ]),
                (errors.value.password.current)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_31, "This field is required"))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_32, [
                _cache[15] || (_cache[15] = _createElementVNode("label", {
                  for: "new-password",
                  class: "block text-sm font-medium leading-6 text-gray-900"
                }, "New password", -1)),
                _createElementVNode("div", _hoisted_33, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((userAccount.value.password.new) = $event)),
                    type: "password",
                    id: "new-password",
                    autocomplete: "given-new-password",
                    class: _normalizeClass([errors.value.password.new ? 'ring-red-600' : 'ring-gray-300', "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2"])
                  }, null, 2), [
                    [_vModelText, userAccount.value.password.new]
                  ])
                ]),
                (errors.value.password.new)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_34, _toDisplayString(errors.value.password.new), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_35, [
                _cache[16] || (_cache[16] = _createElementVNode("label", {
                  for: "password-confirmation",
                  class: "block text-sm font-medium leading-6 text-gray-900"
                }, "Confirm password", -1)),
                _createElementVNode("div", _hoisted_36, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((userAccount.value.password.confirmNew) = $event)),
                    type: "password",
                    id: "password-confirmation",
                    autocomplete: "given-new-password-confirm",
                    class: _normalizeClass([errors.value.password.confirmNew ? 'ring-red-600' : 'ring-gray-300', "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2"])
                  }, null, 2), [
                    [_vModelText, userAccount.value.password.confirmNew]
                  ])
                ]),
                (errors.value.password.confirmNew)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_37, _toDisplayString(errors.value.password.confirmNew), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("button", {
              onClick: handlePasswordSubmit,
              type: "button",
              class: _normalizeClass([isLoading.value ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer', "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 outlinne-none"])
            }, "Save", 2)
          ])
        ])
      ])
    ]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"])
  ], 64))
}
}

})