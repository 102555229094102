import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0"
}
const _hoisted_2 = { class: "flex flex-col w-full lg:w-theme-auth mx-auto gap-5" }
const _hoisted_3 = { class: "flex flex-col items-center gap-4" }
const _hoisted_4 = {
  key: 1,
  class: "flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0"
}
const _hoisted_5 = {
  key: 2,
  class: "flex flex-col items-center justify-center gap-4 py-12"
}

import { onMounted, ref } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import Auth from '@/composables/Auth'
import { handleApiError } from '@/utils/common'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
import router from '@/router'


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentActivateAccount',
  props: ['token'],
  setup(__props) {

const props = __props
const password = ref<string>('')
const passwordConfirmationn = ref<string>('')
const isLoading = ref<boolean>(false) 
const status = ref<boolean | string>(false) 
const { checkAgentActivateAccountToken }  = Auth()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})

const notifClosed = () => {
   notif.value.show = false
}

const resetForm = () :void => {
   password.value = ''
   passwordConfirmationn.value = ''
}

const mounted = async () => {

   if (props.token == '') {
      status.value = 'not-found'
   } else {

      isLoading.value = true
      const response = await checkAgentActivateAccountToken(props.token)
      if (response.status == 200) {
         status.value = true
      } else {
         status.value = 'not-found'
      }

      isLoading.value = false
   }
}

onMounted(() => {
   mounted()
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TopBanner, {
      layout: "full-width",
      currentPage: "Setup Password",
      heading: "Setup Password",
      bannerClass: "banner-auth"
    }),
    (status.value === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[1] || (_cache[1] = _createElementVNode("div", null, "You have verified your email. You can now login to your account", -1)),
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({name: 'Login'}))),
                class: "gap-4 uppercase rounded-full px-4 w-full md:w-auto md:px-8 py-2 md:py-4 border-2 border-theme-active text-white bg-theme-active hover:text-theme-active hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer mx-auto transition-all ease-in-out duration-500"
              }, " Login ")
            ])
          ])
        ]))
      : (status.value === 'not-found')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
            _createStaticVNode("<div class=\"flex flex-col md:flex-row items-center bg-theme-gray-7 px-12 py-10 gap-6\"><div class=\"relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center\"><span class=\"font-icon theme-icon warning-icon text-theme-error absolute -top-[23px] -left-[13px] text-[47px]\"></span></div><div class=\"flex flex-col gap-3\"><h4 class=\"font-bold text-size-6 text-theme-error\">Invalid token</h4><p class=\"font-light text-size-6 text-theme-error\">The token provided is either invalid or expired</p></div></div>", 1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[3] || (_cache[3] = [
            _createElementVNode("img", {
              class: "w-12",
              src: "/assets/loading.svg",
              alt: ""
            }, null, -1),
            _createElementVNode("span", null, "...Loading please wait", -1)
          ]))),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"])
  ], 64))
}
}

})