import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, resolveComponent as _resolveComponent, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0"
}
const _hoisted_3 = { class: "flex flex-col w-full lg:w-theme-auth mx-auto gap-6" }
const _hoisted_4 = { class: "flex flex-col gap-2" }
const _hoisted_5 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_6 = {
  key: 0,
  class: "flex flex-col gap-2"
}
const _hoisted_7 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_8 = { class: "flex flex-col gap-2" }
const _hoisted_9 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_10 = {
  key: 1,
  class: "flex flex-col gap-2"
}
const _hoisted_11 = ["type"]
const _hoisted_12 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_13 = {
  key: 2,
  class: "flex flex-col gap-2"
}
const _hoisted_14 = ["type"]
const _hoisted_15 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_16 = {
  key: 3,
  class: "flex flex-col gap-2"
}
const _hoisted_17 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_18 = { class: "relative w-full" }
const _hoisted_19 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_20 = {
  key: 4,
  class: "flex flex-col gap-2"
}
const _hoisted_21 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_22 = ["type"]
const _hoisted_23 = { class: "flex flex-col justify-end gap-2" }
const _hoisted_24 = {
  key: 0,
  class: "text-ef-error ml-auto"
}
const _hoisted_25 = { class: "flex flex-col md:flex-row md:items-center gap-4 mb-2" }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0" }
const _hoisted_28 = { class: "flex flex-col md:flex-row items-center bg-green-100 px-12 py-10 gap-6" }
const _hoisted_29 = { class: "relative bg-green-300 rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center" }
const _hoisted_30 = { class: "flex flex-col" }
const _hoisted_31 = { class: "font-light text-size-6 text-green-600 font-semibold" }
const _hoisted_32 = { key: 1 }

import { onMounted, ref } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import CountrySearchablePhoneField from '@/components/CountrySearchablePhoneField.vue'
import { handleApiError, isValidEmail, loadScript } from '@/utils/common'
import Auth from '@/composables/Auth'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
// @ts-ignore
import $ from 'jquery'
import router from '@/router'


export default /*@__PURE__*/_defineComponent({
  __name: 'Signup',
  props: ['type'],
  setup(__props) {

const props = __props
const { registerSupplier, registerAgent, registerUser }  = Auth()
const isLoading = ref<boolean>(false)
const invalidType = ref<boolean>(false)
const recaptchaError = ref<any>(null)
const success = ref<string>('')
const passwordFieldtype = ref<'password' | 'text'>('password')
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const form = ref<any>({
   fullName: { value: '', error: false, errorMessage: '' },
   companyName: { value: '', error: false, errorMessage: '' },
   email: { value: '', error: false, errorMessage: '' },
   phone: { value: '', error: false, errorMessage: '' },
   password: { value: '', error: false, errorMessage: '' },
   passwordConfirmation: { value: '', error: false, errorMessage: '' },
   message: { value: '', error: false, errorMessage: '' },
})

const togglePasswordFieldType = () => {
   passwordFieldtype.value = passwordFieldtype.value == 'password' ? 'text' : 'password'
}

const handlePhoneInput = (input: any) => {

   if (input === false) {

      form.value.phone.value = ''
      form.value.phone.error = true
      form.value.phone.errorMessage = 'Phone is required'
   } else {

      form.value.phone.value = input
      form.value.phone.error = false
   }
}

const validateForm = () : boolean => {
   let error : boolean = false
   const recaptcha = $('textarea[name="g-recaptcha-response"]')

   if (!recaptcha.length || recaptcha.val() == '' || !recaptcha.val()) {
      error = true
      recaptchaError.value = 'Please validate the recaptcha'
   } else {
      recaptchaError.value = null
   }

   if (form.value.fullName.value == '') {
      form.value.fullName.error = true
      form.value.fullName.errorMessage = 'Full name is required'
      error = true
   }

   if (form.value.email.value == '') {
      form.value.email.error = true
      form.value.email.errorMessage = 'Email is required'
      error = true
   } else if (!isValidEmail(form.value.email.value)) {
      form.value.email.error = true
      form.value.email.errorMessage = 'Email is invalid'
      error = true
   }

   if (props.type !== 'web') {
      
      if (form.value.phone.value === '') {
         form.value.phone.error = true
         form.value.phone.errorMessage = 'Phone number is required'
      }else if (form.value.phone.value.length < 11) {
         form.value.phone.error = true
         form.value.phone.errorMessage = 'Phone number is invalid'
      }

      if (form.value.companyName.value == '') {
         form.value.companyName.error = true
         form.value.companyName.errorMessage = 'Company name is required'
         error = true
      }
   }

   if (props.type !== 'supplier') {

      if (form.value.password.value == '') {
         form.value.password.error = true
         form.value.password.errorMessage = 'Password is required'
         error = true
      } else if (form.value.password.value.length < 6) {
         form.value.password.error = true
         form.value.password.errorMessage = 'Password must be at least 6 characters long'
         error = true
      }else if (form.value.passwordConfirmation.value == '') {
         form.value.passwordConfirmation.error = true
         form.value.passwordConfirmation.errorMessage = 'Password confirmation is required'
         error = true
      } else if (form.value.passwordConfirmation.value != form.value.password.value) {
         form.value.password.error = true
         form.value.passwordConfirmation.error = true
         form.value.password.errorMessage = 'Password does not match with password confirmation'
         error = true
      }
   }

   if (form.value.fullName.value == '') {
      form.value.fullName.error = true
      form.value.fullName.errorMessage = 'Full name is required'
      error = true
   }

   return error
}

const resetFormErrors = () :void => {
   Object.keys(form.value).forEach((key: string) => {
      form.value[key].error = false
      form.value[key].errorMessage = ''
   })

   success.value = ''
}

const resetForm = () :void => {
   Object.keys(form.value).forEach((key: string) => {
      form.value[key].value = ''
   })
}

const handleSignup = async () => {

   resetFormErrors()
   const error = validateForm()
   if (!error && !isLoading.value) {
      isLoading.value = true
      const recaptcha = $('textarea[name="g-recaptcha-response"]').val()

      if (props.type == 'supplier') {
         const response = await registerSupplier(form.value.fullName.value, form.value.companyName.value, form.value.email.value, form.value.phone.value, form.value.message.value, recaptcha)
         
         if (response.status == 201) {
            const messageDetails = props.type == 'supplier' ? 'We have notified our support team. You will hear from us soon' : 'You have created an account with us'
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: messageDetails}
            setTimeout(() => { notifClosed() }, 4000)
            resetForm()
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
         }

         isLoading.value = false
      } else {
         const response = props.type == 'agent' 
            ? await registerAgent(form.value.fullName.value, form.value.companyName.value, form.value.email.value, form.value.phone.value, form.value.password.value, form.value.passwordConfirmation.value, recaptcha)
            : await registerUser(form.value.fullName.value, form.value.email.value, form.value.phone.value, form.value.companyName.value, form.value.password.value, form.value.passwordConfirmation.value, props.type, recaptcha)
         if (response.status == 201) {
            if (props.type == 'agent') {
               success.value = 'You have created an account with us. Please check your email to activate your account'
               setTimeout(() => { notifClosed() }, 4000)
               resetForm()
            } else {
               router.push({name: 'Login'})
            }
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
         }

         isLoading.value = false
      }
   }
}

const notifClosed = () => {
   notif.value.show = false
}

onMounted(() => {

   var recaptchaScriptUrl = 'https://www.google.com/recaptcha/api.js'
   $('.google-recaptcha').html('<div class="g-recaptcha" data-sitekey="6LdgLVspAAAAAH4Y109CAIfw26hm_onZFQsycv1v"></div>')
   loadScript(recaptchaScriptUrl, function () {})

   if (props.type != 'agent' && props.type != 'supplier' && props.type != 'web') {
      invalidType.value = true
      notif.value.show = true
   }
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (!invalidType.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(TopBanner, {
          layout: "full-width",
          currentPage: "Signup",
          heading: "Signup",
          bannerClass: "banner-auth"
        }),
        (success.value == '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", {
                    for: "signup-fullname",
                    class: _normalizeClass([form.value.fullName.error ? 'text-ef-text' : 'text-theme-gray-4', "uppercase text-small"])
                  }, "Full name *", 2),
                  _createElementVNode("div", {
                    class: _normalizeClass([form.value.fullName.error ? 'border-ef-outline' : 'border-theme-gray-7', "flex flex-row items-center border"])
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.fullName.value) = $event)),
                      type: "text",
                      id: "signup-fullname",
                      class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
                    }, null, 512), [
                      [_vModelText, form.value.fullName.value]
                    ])
                  ], 2),
                  (form.value.fullName.error)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(form.value.fullName.errorMessage), 1))
                    : _createCommentVNode("", true)
                ]),
                (__props.type != 'web')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("label", {
                        for: "signup-companyname",
                        class: _normalizeClass([form.value.companyName.error ? 'text-ef-text' : 'text-theme-gray-4', "uppercase text-small"])
                      }, "Company name *", 2),
                      _createElementVNode("div", {
                        class: _normalizeClass([form.value.companyName.error ? 'border-ef-outline' : 'border-theme-gray-7', "flex flex-row items-center border"])
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.companyName.value) = $event)),
                          type: "text",
                          id: "signup-companyname",
                          class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
                        }, null, 512), [
                          [_vModelText, form.value.companyName.value]
                        ])
                      ], 2),
                      (form.value.companyName.error)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(form.value.companyName.errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", {
                    for: "signup-email",
                    class: _normalizeClass([form.value.email.error ? 'text-ef-text' : 'text-theme-gray-4', "uppercase text-small"])
                  }, "Email *", 2),
                  _createElementVNode("div", {
                    class: _normalizeClass([form.value.email.error ? 'border-ef-outline' : 'border-theme-gray-7', "flex flex-row items-center border"])
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.email.value) = $event)),
                      type: "email",
                      id: "signup-email",
                      class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
                    }, null, 512), [
                      [_vModelText, form.value.email.value]
                    ])
                  ], 2),
                  (form.value.email.error)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(form.value.email.errorMessage), 1))
                    : _createCommentVNode("", true)
                ]),
                (__props.type != 'supplier')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("label", {
                        for: "signup-password",
                        class: _normalizeClass([form.value.password.error ? 'text-ef-text' : 'text-theme-gray-4', "uppercase text-small"])
                      }, "Password *", 2),
                      _createElementVNode("div", {
                        class: _normalizeClass([form.value.password.error ? 'border-ef-outline' : 'border-theme-gray-7', "flex flex-row items-center border"])
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.password.value) = $event)),
                          type: passwordFieldtype.value,
                          id: "signup-password",
                          class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
                        }, null, 8, _hoisted_11), [
                          [_vModelDynamic, form.value.password.value]
                        ]),
                        _createElementVNode("div", { onClick: togglePasswordFieldType }, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: "fa-solid fa-eye",
                            class: "text-theme-gray-4 cursor-pointer ml-auto mr-1"
                          })
                        ])
                      ], 2),
                      (form.value.password.error)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(form.value.password.errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (__props.type !== 'supplier')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("label", {
                        for: "signup-password-confirmaiton",
                        class: _normalizeClass([form.value.passwordConfirmation.error ? 'text-ef-text' : 'text-theme-gray-4', "uppercase text-small"])
                      }, "Confirm Password *", 2),
                      _createElementVNode("div", {
                        class: _normalizeClass([form.value.passwordConfirmation.error ? 'border-ef-outline' : 'border-theme-gray-7', "flex flex-row items-center border"])
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.passwordConfirmation.value) = $event)),
                          type: passwordFieldtype.value,
                          id: "signup-password-confirmation",
                          class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
                        }, null, 8, _hoisted_14), [
                          [_vModelDynamic, form.value.passwordConfirmation.value]
                        ]),
                        _createElementVNode("div", { onClick: togglePasswordFieldType }, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: "fa-solid fa-eye",
                            class: "text-theme-gray-4 cursor-pointer ml-auto mr-1"
                          })
                        ])
                      ], 2),
                      (form.value.passwordConfirmation.error)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(form.value.passwordConfirmation.errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (__props.type != 'web')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createElementVNode("label", {
                        for: "signup-phonenumber",
                        class: _normalizeClass([form.value.phone.error ? 'text-ef-text' : 'text-theme-gray-4', "uppercase text-small"])
                      }, "Phone Number *", 2),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createVNode(CountrySearchablePhoneField, {
                            onPhoneNumber: handlePhoneInput,
                            class: "h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6 px-2"
                          })
                        ])
                      ]),
                      (form.value.phone.error)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(form.value.phone.errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (__props.type == 'supplier')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _cache[7] || (_cache[7] = _createElementVNode("label", {
                        for: "signup-message",
                        class: "uppercase text-small text-theme-gray-4"
                      }, "Message *", -1)),
                      _createElementVNode("div", _hoisted_21, [
                        _withDirectives(_createElementVNode("textarea", {
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((form.value.message.value) = $event)),
                          type: passwordFieldtype.value,
                          id: "signup-message",
                          class: "w-full outline-none text-theme-gray-5 p-2 h-[200px] md:h-[300px] font-bold text-size-4 md:text-size-6"
                        }, null, 8, _hoisted_22), [
                          [_vModelText, form.value.message.value]
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_23, [
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "ml-auto google-recaptcha my-4" }, null, -1)),
                  (recaptchaError.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_24, _toDisplayString(recaptchaError.value), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_25, [
                  (__props.type != 'supplier')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.push({ name: 'Login' }))),
                        class: "order-2 md:order-1 gap-4 uppercase rounded-full w-full md:w-auto px-4 md:px-8 py-2 md:py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer transition-all ease-in-out duration-500"
                      }, " I already have an account "))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    onClick: handleSignup,
                    class: _normalizeClass([isLoading.value ? 'cursor-not-allowed opacity-40' : 'cursor-pointer hover:text-theme-active hover:bg-white', "order-1 md:order-2 gap-4 uppercase rounded-full w-full md:w-auto px-4 md:px-8 py-2 md:py-4 border-2 border-theme-active text-white bg-theme-active text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto transition-all ease-in-out duration-500"])
                  }, _toDisplayString(__props.type == 'supplier' ? 'Submit' : 'Signup'), 3)
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    _createVNode(_component_font_awesome_icon, {
                      size: "lg",
                      icon: "fa-solid fa-check-double",
                      class: "text-green-800"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "font-bold text-size-6 text-green-600" }, "Successful", -1)),
                    _createElementVNode("p", _hoisted_31, _toDisplayString(success.value), 1)
                  ])
                ])
              ])
            ]))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_32, _cache[10] || (_cache[10] = [
        _createStaticVNode("<div class=\"flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0\"><div class=\"flex flex-col md:flex-row items-center bg-theme-gray-7 px-12 py-10 gap-6\"><div class=\"relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center\"><span class=\"font-icon theme-icon warning-icon text-theme-error absolute -top-[23px] -left-[13px] text-[47px]\"></span></div><div class=\"flex flex-col gap-3\"><h4 class=\"font-bold text-size-6 text-theme-error\">404 Error</h4><p class=\"font-light text-size-6 text-theme-error font-semibold\">The url you are visiting is invalid and cannot be used to display anything</p></div></div></div>", 1)
      ])))
}
}

})