import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "car-info grid grid-cols-5 items-start gap-10 pb-6 pt-10" }
const _hoisted_2 = { class: "col-span-5 md:col-span-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "col-span-5 md:col-span-3 flex flex-col gap-10" }
const _hoisted_5 = { class: "flex flex-col md:items-center md:flex-row" }
const _hoisted_6 = { class: "md:w-2/3 flex flex-col w-full items-start" }
const _hoisted_7 = { class: "text-theme-gray-5 text-size-6 font-bold text-center md:text-left w-full" }
const _hoisted_8 = {
  key: 0,
  class: "text-center text-size-7 font-bold text-theme-active w-full"
}
const _hoisted_9 = { class: "flex flex-col w-full items-center justify-center md:items-start md:justify-start" }
const _hoisted_10 = { style: {"float":"left"} }
const _hoisted_11 = {
  key: 0,
  class: "pricinglabel"
}
const _hoisted_12 = {
  key: 0,
  style: {"float":"left"}
}
const _hoisted_13 = { class: "font-size:13px !important; font-weight:bold; color:black" }
const _hoisted_14 = {
  key: 1,
  class: "rounded rounded-bg text-center min-w-[85px] py-2 px-2 my-2 uppercase text-xs bg-theme-button2-bg text-theme-button2-txt"
}
const _hoisted_15 = {
  key: 0,
  class: "ml-auto"
}
const _hoisted_16 = {
  key: 0,
  class: "gap-2 uppercase rounded-full px-8 py-3 border-2 border-theme-gray-7 bg-theme-active text-white text-size-2 flex items-center justify-center cursor-pointer w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500"
}
const _hoisted_17 = { class: "flex flex-row" }
const _hoisted_18 = { class: "p-2 flex items-center justify-center rounded-full border border-theme-gray-7 bg-white group-hover:bg-theme-gray-7" }
const _hoisted_19 = { class: "flex flex-col md:flex-row ml-auto gap-2 md:gap-6" }
const _hoisted_20 = { class: "flex flex-row items-center gap-5" }
const _hoisted_21 = { class: "w-[30px] h-[30px] flex items-center justify-center rounded-full border border-theme-gray-7 bg-white text-theme-gray-6 text-size-2" }
const _hoisted_22 = { class: "flex flex-row items-center gap-5" }
const _hoisted_23 = { class: "w-[30px] h-[30px] flex items-center justify-center rounded-full border border-theme-gray-7 bg-white text-theme-gray-6 text-size-2" }
const _hoisted_24 = {
  class: "flex flex-col gap-10 car-more-info",
  style: {"display":"none"}
}
const _hoisted_25 = ["innerHTML"]

import { computed, ref, watch } from 'vue'
// @ts-ignore
import $ from 'jquery'
import { Car as CarData, UserSearch } from '@/types'
import { retrieveUserSearch } from '@/services/search-service'
import { getHoursPerDay } from '@/utils/common'


export default /*@__PURE__*/_defineComponent({
  __name: 'CarResult',
  props: ['canSelect', 'car', 'selectedCar', 'isLead'],
  emits: ['selected'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const userSearch = ref<UserSearch>(retrieveUserSearch())
const days = ref<number>(0)

if (userSearch.value !== undefined && userSearch.value.hours) {
   days.value = userSearch.value.hours / getHoursPerDay()
}

const carr = computed(() => {
   const data = { ...props.car }

   if (userSearch.value) {

      data.dailyrate = 0
      data.price = 0

      if (userSearch.value.type == 'hourly' && props.car.chaufferLocation) {
         data.price = parseFloat(props.car.chaufferLocation.rate_per_hour) * (days.value * props.car.supplier.hours ?? 0)
         data.dailyrate = parseFloat(props.car.chaufferLocation.rate_per_hour) * (props.car.supplier.hours ?? 0)
      } else if (userSearch.value.type == 'distance' && props.car.transferLocation) {
         data.price = parseFloat(props.car.transferLocation.rate)
      }

      if (data.price > 0 && props.car.priceFactor > 0) {
         data.price = data.price / ((100-props.car.priceFactor)/100)
         data.dailyrate = data.dailyrate  / ((100-props.car.priceFactor)/100)
      }
   }

   return data
})

const toggleCarMoreInfo = (event: any) => {
   $(event.currentTarget).closest('.car-info').find('.car-more-info').slideToggle()
}

const handleSelectCar = (car: CarData) => {
   emit('selected', car)
}


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "rounded-lg",
        src: carr.value.image && carr.value.image != '' ? carr.value.image : '/assets/nocarfound.jpeg',
        alt: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(__props.isLead && __props.isLead == '1' ? carr.value.type.name : (carr.value ? carr.value.name : '')), 1),
          (carr.value && carr.value.price)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("ul", _hoisted_9, [
                  _createElementVNode("li", _hoisted_10, [
                    (userSearch.value.type == 'hourly')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Price for " + _toDisplayString((userSearch.value.hours ?? 0) / 10) + " day(s)", 1))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(`$${carr.value.price.toFixed(2)}`), 1)
                  ]),
                  _cache[2] || (_cache[2] = _createElementVNode("li", { class: "clearfix" }, null, -1)),
                  (userSearch.value.type == 'hourly')
                    ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "pricinglabel" }, "Daily Rate ", -1)),
                        _createElementVNode("span", _hoisted_13, _toDisplayString(`$${carr.value.dailyrate.toFixed(2)}`), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, " On Request "))
        ]),
        (__props.canSelect)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              (__props.selectedCar && carr.value && __props.selectedCar.id == carr.value.id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _cache[3] || (_cache[3] = _createElementVNode("span", null, "Select", -1)),
                    _createElementVNode("span", null, [
                      _createVNode(_component_font_awesome_icon, {
                        size: "lg",
                        icon: "fa-solid fa-check",
                        class: "text-white"
                      })
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (handleSelectCar(carr.value))),
                    class: "uppercase rounded-full px-8 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer py-3 mt-4 md:mt-0 md:ml-auto transition-all ease-in-out duration-500"
                  }, " Select "))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", {
          onClick: toggleCarMoreInfo,
          class: "flex flex-row items-center gap-3 group cursor-pointer"
        }, [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_font_awesome_icon, {
              size: "xs",
              icon: "fa-solid fa-chevron-down",
              class: "text-theme-gray-6"
            })
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "uppercase text-theme-gray-2 text-size-2 tracking-wide group-hover:text-theme-gray-3" }, "More info", -1))
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "" }, [
              _createElementVNode("span", { class: "font-icon theme-icon users-icon text-theme-gray-9 text-[22px] md:text-[47px]" })
            ], -1)),
            _createElementVNode("div", _hoisted_21, _toDisplayString(carr.value ? carr.value.type.passengers : ''), 1)
          ]),
          _createElementVNode("div", _hoisted_22, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "" }, [
              _createElementVNode("span", { class: "font-icon theme-icon suitcase-icon text-theme-gray-9 text-[22px] md:text-[47px]" })
            ], -1)),
            _createElementVNode("div", _hoisted_23, _toDisplayString(carr.value ? carr.value.type.bags : ''), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", {
          innerHTML: carr.value && carr.value.type && carr.value.type.description ? carr.value.type.description.replace(/\n/g, '<br>') : '',
          class: "font-light text-size-4 text-theme-gray-4"
        }, null, 8, _hoisted_25),
        _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"grid grid-cols-1 gap-1 bg-theme-gray-1\"><div class=\"col-span-1 md:col-span-1 py-6 px-4\"><div class=\"flex flex-col divide-y divide-theme-gray-9 gap-3\"><div class=\"flex flex-row items-center gap-6 py-2\"><div class=\"text-theme-gray-2 text-small uppercase\">Cancellation</div><div class=\"ml-auto text-theme-gray-5 text-size-3 text-right\">Free Cancellation upto 72 hours before Pickup</div></div></div></div></div>", 1))
      ])
    ])
  ]))
}
}

})