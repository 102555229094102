import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "map",
  style: {"height":"530px","width":"100%"}
}

import { ref, onMounted, watch } from 'vue'
import LocationService from '@/services/location-service'


export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleMap',
  props: ['origin', 'destination', 'driveType'],
  emits: ['distance', 'time', 'match'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const { getPlaceLocality } = LocationService()

let map : any
let directionsService : any
let directionsRenderer : any
let featureLayer: any

const initMap = async () => {

   const center = { lat: -34.397, lng: 150.644 }
   map = new (window as any).google.maps.Map(document.getElementById('map'), {
      center: center,
      zoom: 8,
   })

   const citymap = {
      chicago: {
         center: { lat: 41.878, lng: -87.629 }
      }
   }

   if (props.driveType == 'distance') {

      directionsService = new (window as any).google.maps.DirectionsService()
      directionsRenderer = new (window as any).google.maps.DirectionsRenderer()
      directionsRenderer.setMap(map)
   } else if (props.driveType == 'hourly') {
      
      if (props.origin) {

         const placesService = new (window as any).google.maps.places.PlacesService(map)
         placesService.getDetails({ placeId: props.origin }, function (place: any, status: any) {
            if (status === "OK" && place.geometry && place.geometry.location) {

               const location = place.geometry.location
               getPlaceLocality(props.origin, location.lat(), location.lng()).then((data) => {

                  if (!data.data.success) {
                     console.log('Error in fetching location bounds')
                     var map = new (window as any).google.maps.Map(document.getElementById('map'), {
                        zoom: 13,
                        center: location
                     })

                     emit('match', 'not-found')
                  } else {

                     const bd = data.data.match.bounds
   
                     const centerLat = (parseFloat(bd.north_east_lat) + parseFloat(bd.south_west_lat)) / 2;
                     const centerLng = (parseFloat(bd.north_east_lng) + parseFloat(bd.south_west_lng)) / 2;
                     const center = new (window as any).google.maps.LatLng(centerLat, centerLng)
   
                     var bounds: any = new (window as any).google.maps.LatLngBounds(
                        new (window as any).google.maps.LatLng(parseFloat(bd.south_west_lat), parseFloat(bd.south_west_lng)),
                        new (window as any).google.maps.LatLng(parseFloat(bd.north_east_lat), parseFloat(bd.north_east_lng))
                     )
   
                     var map = new (window as any).google.maps.Map(document.getElementById('map'), {
                        zoom: 10,
                        center: bounds.getCenter()
                     })

                     // new (window as any).google.maps.Marker({
                     //    map,
                     //    position: location,
                     // })

                     new (window as any).google.maps.Marker({
                        map,
                        position: center,
                     })

                     emit('match', data.data.match)
                  }
               })
            }
         })
      }
   }

   if (props.driveType == 'distance') {
      calculateAndDisplayRoute()
   }
}

const calculateDistanceAndTime = (response: any) => {

   const route = response.routes[0];
   const leg = route.legs[0];

   const distanceText = leg.distance.text
   const durationText = leg.duration.text

   emit('distance', distanceText, 'distance')
   emit('time', durationText, 'time')
}

const calculateAndDisplayRoute = () => {
   const startPlaceId = props.origin
   const endPlaceId = props.destination

   if (startPlaceId && endPlaceId && startPlaceId != '' && endPlaceId != '') {

      directionsService.route({
            origin: { placeId: startPlaceId },
            destination: { placeId: endPlaceId },
            travelMode: 'DRIVING',
         },(response: any, status: any) => {
            if (status === 'OK') {
   
               directionsRenderer.setDirections(response);
               calculateDistanceAndTime(response)
            } else {
               
               window.alert('Directions request failed due to ' + status)
            }
         }
      )
   }
}

watch(() => props.origin, (newVallue, oldValue) => {
   initMap()
})

watch(() => props.destination, (newVallue, oldValue) => {
   initMap()
})

onMounted(function(){

   initMap()
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})