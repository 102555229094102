import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col bg-white main-header" }
const _hoisted_2 = { class: "hidden md:block bg-theme-gray-1 text-theme-gray-2" }
const _hoisted_3 = { class: "flex flex-col md:flex-row md:items-center md:mx-auto w-full lg:w-theme-dw pt-5 pb-2 md:space-y-0 space-y-3 md:px-0 px-11 md:space-x-12" }
const _hoisted_4 = { class: "flex flex-row items-start space-x-4" }
const _hoisted_5 = { class: "text-theme-gray-2" }
const _hoisted_6 = { class: "flex flex-row items-center mx-auto w-full lg:w-theme-dw py-5 md:px-0 px-11 z-20" }
const _hoisted_7 = { class: "hidden md:flex flex-row items-center ml-auto text-theme-gray-4 text-size-4 uppercase space-x-7 cursor-pointer" }
const _hoisted_8 = ["onClick", "index"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "flex flex-col absolute left-0 top-6 w-[150%] pt-2"
}
const _hoisted_11 = ["onClick", "index"]
const _hoisted_12 = { class: "z-20 relative bg-white h-fit flex flex-col items-start divide-y divide-theme-gray-7" }
const _hoisted_13 = ["onClick", "index"]

import { ref } from 'vue'
import 'animate.css'
import router from '@/router'
import { isLoggedIn, logout, getFirstName } from '@/utils/common'


export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  setup(__props) {

const isMenuOpen = ref(false)
const auth = isLoggedIn()
const expandedItemId = ref<number | null>(null)

const menuItems : any = [
   { id: 1, title: 'Home', component: 'Home' },
   { id: 2, title: 'About us', component: 'AboutUs' },
   { id: 3, title: 'Our services', component: 'OurServices' },
   { id: 4, title: 'Contact us', component: 'ContactUs' }
]

if (auth) {

   menuItems.push({
      id: 6, title: 'Hello ' + getFirstName(auth.user.name), component: null, children: [
         {id: 1, title: 'My Dashboard', href: { name: 'AccountDashboard' }},
         {id: 2, title: 'Logout', href: 'logout'}
      ]
   })
} else {
   menuItems.push(
      { id: 5, title: 'Log in', component: 'Login' },
      {
         id: 6, title: 'Signup', component: null, children: [
            {id: 1, title: 'User', href: { name: 'Signup', params: { type: 'web' } }},
            {id: 2, title: 'Agent', href: { name: 'Signup', params: { type: 'agent' } }}
         ]
      }
   )
}

const handleTopNav = (item: any) => {

   if (item.component) {
      router.push({name: item.component})
   } else if (item.children && item.children.length) {
      expandedItemId.value = expandedItemId.value === item.id ? null : item.id
      console.log(expandedItemId.value)
   }
}

const handleSignupRoute = (child: any) => {

   if (child.href == 'logout') {
      logout(router)
   } else {
      router.push(child.href)
   }
}

const handleMenuItemClick = (item: any) => {

   if (item.component) {
      router.push({name: item.component})
      isMenuOpen.value = false
   }
}


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isMenuOpen.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isMenuOpen.value=false)),
          class: "bg-black opacity-70 fixed top-0 left-0 w-full h-screen z-10"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex flex-row items-start space-x-4" }, [
            _createElementVNode("span", { class: "font-icon theme-icon location-icon text-theme-gray-3" }),
            _createElementVNode("span", { class: "font-light text-size-4" }, "312 Falcon Tower Diplomatic Area, Manama, Kingdom of Bahrain")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_font_awesome_icon, {
                size: "sm",
                icon: "fa-solid fa-mobile-screen"
              })
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-size-3" }, "+973 3214 1440", -1))
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex flex-row items-start space-x-4" }, [
            _createElementVNode("span", { class: "font-icon theme-icon clock-icon text-theme-gray-3 text-[16px]" }),
            _createElementVNode("span", { class: "text-size-3" }, "24/7 Available")
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({name: 'Home'}))),
          class: "w-[235px] cursor-pointer"
        }, _cache[7] || (_cache[7] = [
          _createElementVNode("img", {
            src: "/assets/logo.png",
            alt: "Site logo"
          }, null, -1)
        ])),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(menuItems, (item) => {
            return _createElementVNode("div", {
              onClick: ($event: any) => (handleTopNav(item)),
              index: item.id,
              class: "group relative"
            }, [
              _createTextVNode(_toDisplayString(item.title) + " ", 1),
              (item.children)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "fa-solid fa-chevron-down",
                      class: "text-theme-gray-4 cursor-pointer ml-auto mr-1"
                    })
                  ]))
                : _createCommentVNode("", true),
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "h-[1px] opacity-0 w-1 bg-theme-active mb-4 group-hover:w-full group-hover:opacity-100 transition-all ease-in-out duration-500" }, null, -1)),
              (item.children && expandedItemId.value === item.id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                      return (_openBlock(), _createElementBlock("div", {
                        onClick: ($event: any) => (handleSignupRoute(child)),
                        index: child.id,
                        class: "border-b border-gray-100 bg-white py-4 pl-2 text-sm hover:bg-gray-50"
                      }, _toDisplayString(child.title), 9, _hoisted_11))
                    }), 256))
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_8)
          }), 64))
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["w-full text-center absolute top-0 left-0 w-full", isMenuOpen.value ? 'animate__animated animate__slideInDown' : 'hidden'])
        }, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (isMenuOpen.value=false)),
              class: "uppercase text-size-4 text-theme-gray-4 px-2 py-3 w-full text-left flex flex-row items-center justify-center"
            }, [
              _createVNode(_component_font_awesome_icon, {
                size: "lg",
                icon: "fa-solid fa-chevron-up",
                class: "bottom-1"
              })
            ]),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(menuItems, (item) => {
              return _createElementVNode("div", {
                onClick: ($event: any) => (handleMenuItemClick(item)),
                index: item.id,
                class: "group uppercase text-size-4 text-theme-gray-4 px-2 py-3 w-full text-left"
              }, [
                _createTextVNode(_toDisplayString(item.title) + " ", 1),
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500" }, null, -1))
              ], 8, _hoisted_13)
            }), 64))
          ])
        ], 2),
        _createElementVNode("div", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (isMenuOpen.value=true)),
          class: "md:hidden ml-auto"
        }, _cache[10] || (_cache[10] = [
          _createStaticVNode("<div class=\"p-3 rounded-full flex flex-row items-center justify-center border\"><svg width=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><g stroke-width=\"0\"></g><g stroke-linecap=\"round\" stroke-linejoin=\"round\"></g><g><path d=\"M1 12C1 11.4477 1.44772 11 2 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H2C1.44772 13 1 12.5523 1 12Z\" fill=\"#556677\"></path> <path d=\"M1 4C1 3.44772 1.44772 3 2 3H22C22.5523 3 23 3.44772 23 4C23 4.55228 22.5523 5 22 5H2C1.44772 5 1 4.55228 1 4Z\" fill=\"#556677\"></path> <path d=\"M1 20C1 19.4477 1.44772 19 2 19H22C22.5523 19 23 19.4477 23 20C23 20.5523 22.5523 21 22 21H2C1.44772 21 1 20.5523 1 20Z\" fill=\"#556677\"></path></g></svg></div>", 1)
        ]))
      ])
    ])
  ], 64))
}
}

})