import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-0 md:w-[50%] mx-auto"
}
const _hoisted_2 = { class: "flex flex-row items-center gap-3 justify-end" }

import { onMounted, ref } from 'vue'
import Voucher from '@/components/booking/Voucher.vue'
import { Booking } from '@/types'
import BookingService from '@/services/booking-service'
import { getSessionPrefix, isMakePaymentVisible, saveBookingNumber } from '@/utils/common'
import router from '@/router'
// @ts-ignore
import html2pdf from 'html2pdf.js'
import Modal from '@/widgets/Modal.vue'
import { saveUserSearch } from '@/services/search-service'
import CarService from '@/services/car-service'


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingVoucher',
  props: ['number'],
  setup(__props) {

const props = __props
const data = ref<Booking | null>(null)
const isLoading = ref<boolean>(true)
const { readBooking } = BookingService()
const pdfContent = ref(null)
const openConfirmModal = ref<boolean>(false)
const showMakePaymentButton = ref<boolean>(false)
const { saveSelectedCar } = CarService()

onMounted(async () => {

   const response = await readBooking(props.number)
   isLoading.value = false
   
   if (response.success) {
      data.value = response.data.data
   } else {
      console.log(response.error)
   }

   showMakePaymentButton.value = isMakePaymentVisible(response.data.data.search.pickUp.date)
})

const downloadPDF = () => {

   const content = pdfContent.value
   html2pdf(content).from(content).save()
}

const handleCancelBooking = () => {

   openConfirmModal.value = true
}

const handleMakePayment = () => {
   if (data.value) {
      saveUserSearch(data.value.search)
      saveBookingNumber(props.number)
      saveSelectedCar(data.value.car)
      router.push({name: 'BookingWizard', params: {step: 'contact-details'}})
   }
}

const handleConfirmClose = () => openConfirmModal.value = false


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            ref_key: "pdfContent",
            ref: pdfContent
          }, [
            _createVNode(Voucher, { data: data.value }, null, 8, ["data"])
          ], 512),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              onClick: handleCancelBooking,
              type: "button",
              class: "rounded-md bg-theme-button5-bg px-2.5 py-1.5 text-sm font-semibold text-theme-button5-txt shadow-sm"
            }, " Cancel Booking "),
            (showMakePaymentButton.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: handleMakePayment,
                  type: "button",
                  class: "rounded-md bg-theme-button1-bg px-2.5 py-1.5 text-sm font-semibold text-theme-button1-txt shadow-sm"
                }, " Make Payment "))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              onClick: downloadPDF,
              type: "button",
              class: "rounded-md bg-theme-button2-bg px-2.5 py-1.5 text-sm font-semibold text-theme-button2-txt shadow-sm"
            }, " Download PDF ")
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(Modal, {
      show: openConfirmModal.value,
      onClosed: handleConfirmClose
    }, {
      content: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "mt-8" }, " To cancel this booking contact our support representative at support@vehitraworld.com ", -1)
      ])),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})