import { getBackendBaseUrl } from "@/utils/common"
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Location = () => {

   const getHeaders = () => { 
      return {
         'Content-Type': 'application/json',
      }
   }

   const searchLocation = async (input: string) : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/search/places', {params: {query: input},headers: getHeaders()})
         return response
      } catch (err : any) {
         console.log(err)
         return err
      }
   }

   const searchLocality = async (input: string, mode: string) : Promise<any> => {
      try {
         const response = await axios.get(`${baseUrl}api/search/localities/${mode}`, {params: {query: input},headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const placeLocality = async (placeId: string, lat: number, lng: number) : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/search/place-locality', {params: {place_id: placeId, lat: lat, lng: lng},headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const placeDetails = async (placeId: string) : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + `api/search/place-details/${placeId}`, {params: {},headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      searchLocation,

      searchLocality,
      placeLocality,
      placeDetails
   }
}

export default Location