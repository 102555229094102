import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  "aria-live": "assertive",
  class: "fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none xs:p-6 xs:items-start"
}
const _hoisted_2 = { class: "flex flex-col items-center w-full space-y-4 xs:items-end" }
const _hoisted_3 = {
  key: 0,
  class: "w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5"
}
const _hoisted_4 = { class: "p-4" }
const _hoisted_5 = { class: "flex items-start" }
const _hoisted_6 = { class: "flex-shrink-0" }
const _hoisted_7 = { class: "ml-3 w-0 flex-1 pt-0.5" }
const _hoisted_8 = { class: "text-sm text-gray-900" }
const _hoisted_9 = {
  key: 0,
  class: "mt-1 text-sm text-gray-500"
}
const _hoisted_10 = { class: "flex flex-shrink-0 ml-4" }

import { ref } from 'vue'
 
 
export default /*@__PURE__*/_defineComponent({
  __name: 'Notification',
  props: ['show', 'type', 'message', 'messageDetails'],
  emits: ['closed'],
  setup(__props, { emit: __emit }) {

 const props = __props
 const emit = __emit
 const closed = () => emit('closed')

 
return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        "enter-active-class": "transition duration-300 ease-out transform",
        "enter-from-class": "translate-y-2 opacity-0 xs:translate-y-0 xs:translate-x-2",
        "enter-to-class": "translate-y-0 opacity-100 xs:translate-x-0",
        "leave-active-class": "transition duration-100 ease-in",
        "leave-from-class": "opacity-100",
        "leave-to-class": "opacity-0"
      }, {
        default: _withCtx(() => [
          (__props.show)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      (__props.type == 'success')
                        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 0,
                            icon: "fa-solid fa-check-circle",
                            class: "icon text-theme-active"
                          }))
                        : (__props.type == 'error')
                          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                              key: 1,
                              icon: "fa-solid fa-times-circle",
                              class: "icon text-theme-error"
                            }))
                          : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("p", _hoisted_8, _toDisplayString(__props.message), 1),
                      (__props.messageDetails != '')
                        ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(__props.messageDetails), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("button", {
                        type: "button",
                        onClick: closed,
                        class: "inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      }, [
                        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "sr-only" }, "Close", -1)),
                        _createVNode(_component_font_awesome_icon, {
                          icon: "fa-solid fa-xmark",
                          class: "icon text-black"
                        })
                      ])
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})