import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex",
  "aria-label": "Breadcrumb"
}
const _hoisted_2 = {
  role: "list",
  class: "px-4 sm:px-6 lg:px-24 flex items-center space-x-4"
}
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "py-10 px-4 sm:px-6 lg:px-24" }
const _hoisted_6 = { class: "flex flex-col gap-12" }
const _hoisted_7 = { class: "flex flex-wrap items-center justify-end gap-6 sm:flex-nowrap mb-6" }
const _hoisted_8 = { class: "order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-r sm:border-gray-200 sm:pr-6 sm:leading-7" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "flex flex-col w-full"
}
const _hoisted_11 = { class: "flex items-center" }
const _hoisted_12 = { class: "text-gray-500 mt-1 text-sm" }
const _hoisted_13 = {
  key: 1,
  class: "ml-auto cursor-auto bg-theme-button1-bg text-theme-button1-txt p-2 text-sm rounded"
}
const _hoisted_14 = { class: "mt-5 grid grid-cols-1 gap-5" }
const _hoisted_15 = { class: "w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 col-span-1" }
const _hoisted_16 = { class: "mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4" }
const _hoisted_17 = { class: "relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6" }
const _hoisted_18 = { class: "absolute rounded-md bg-theme-active p-3" }
const _hoisted_19 = { class: "ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase" }
const _hoisted_20 = { class: "ml-16 flex items-baseline pb-6 sm:pb-7" }
const _hoisted_21 = { class: "text-2xl font-semibold text-gray-900" }
const _hoisted_22 = { class: "absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6" }
const _hoisted_23 = { class: "text-sm" }
const _hoisted_24 = { class: "relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6" }
const _hoisted_25 = { class: "absolute rounded-md bg-theme-active p-3" }
const _hoisted_26 = { class: "ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase" }
const _hoisted_27 = { class: "ml-16 flex items-baseline pb-6 sm:pb-7" }
const _hoisted_28 = { class: "text-2xl font-semibold text-gray-900" }
const _hoisted_29 = { class: "absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6" }
const _hoisted_30 = { class: "text-sm" }
const _hoisted_31 = { class: "relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6" }
const _hoisted_32 = { class: "absolute rounded-md bg-theme-active p-3" }
const _hoisted_33 = { class: "ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase" }
const _hoisted_34 = { class: "ml-16 flex items-baseline pb-6 sm:pb-7" }
const _hoisted_35 = { class: "text-2xl font-semibold text-gray-900" }
const _hoisted_36 = { class: "absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6" }
const _hoisted_37 = { class: "text-sm" }
const _hoisted_38 = {
  key: 0,
  class: "relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
}
const _hoisted_39 = { class: "absolute rounded-md bg-theme-active p-3" }
const _hoisted_40 = { class: "ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase" }
const _hoisted_41 = { class: "ml-16 flex items-baseline pb-6 sm:pb-7" }
const _hoisted_42 = { class: "text-2xl font-semibold text-gray-900" }
const _hoisted_43 = { class: "absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6" }
const _hoisted_44 = { class: "text-sm" }
const _hoisted_45 = {
  key: 1,
  class: "relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
}
const _hoisted_46 = { class: "absolute rounded-md bg-theme-active p-3" }
const _hoisted_47 = { class: "ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase" }
const _hoisted_48 = { class: "ml-16 flex items-baseline pb-6 sm:pb-7" }
const _hoisted_49 = { class: "text-2xl font-semibold text-gray-900" }
const _hoisted_50 = { class: "absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6" }
const _hoisted_51 = { class: "text-sm" }
const _hoisted_52 = { class: "mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-2 md:divide-x" }
const _hoisted_53 = { class: "px-4 py-5 sm:p-6" }
const _hoisted_54 = { class: "text-base font-normal text-gray-900" }
const _hoisted_55 = { class: "mt-1 flex items-baseline justify-between md:block lg:flex" }
const _hoisted_56 = { class: "flex items-baseline text-2xl font-semibold text-theme-active" }
const _hoisted_57 = { class: "px-4 py-5 sm:p-6" }
const _hoisted_58 = { class: "text-base font-normal text-gray-900" }
const _hoisted_59 = { class: "mt-1 flex items-baseline justify-between md:block lg:flex" }
const _hoisted_60 = { class: "flex items-baseline text-2xl font-semibold text-theme-active" }
const _hoisted_61 = { class: "flex flex-row items-center justify-between" }
const _hoisted_62 = {
  key: 0,
  class: "mt-8"
}
const _hoisted_63 = { class: "flex flex-row items-center justify-between" }
const _hoisted_64 = {
  key: 1,
  class: "mt-8"
}
const _hoisted_65 = { class: "flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0" }
const _hoisted_66 = { class: "flex flex-col w-full mx-auto gap-5" }
const _hoisted_67 = { class: "flex flex-col gap-2" }
const _hoisted_68 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_69 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_70 = { class: "flex flex-col gap-2" }
const _hoisted_71 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_72 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_73 = { class: "flex flex-col gap-2" }
const _hoisted_74 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_75 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_76 = { class: "flex flex-col gap-2" }
const _hoisted_77 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_78 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_79 = { class: "flex flex-row items-center gap-4" }
const _hoisted_80 = {
  key: 0,
  class: "text-ef-error"
}

import { computed, ref, watch, onMounted } from 'vue'
import { BarChart } from 'vue-chart-3'
import { Chart, registerables } from "chart.js"
import ListBox from '@/widgets/ListBox.vue'
import { logout, isLoggedIn, handleApiError, disableScroll, enableScroll } from '@/utils/common'
import Dashboard from '@/composables/admin/Dashboard'
import Loader from '@/widgets/Loader.vue'
import router from '@/router'
import 'flatpickr/dist/flatpickr.css'
import Modal from '@/widgets/Modal.vue'
import flatPickr from 'vue-flatpickr-component'
import { NotificationType } from '@/types/index'
import Agent from '@/composables/admin/agent'
import { LOADER_MINIMUM_TIME } from '@/utils/constants'

type ChartOption = 'daily' | 'monthly' | 'weekly'

export default /*@__PURE__*/_defineComponent({
  __name: 'dashboard',
  props: ['user', 'agent', 'breadcrump'],
  setup(__props) {

const pages = [
  { name: 'Projects', href: '#', current: false },
  { name: 'Project Nero', href: '#', current: true },
]
const props = __props
Chart.register(...registerables)
const bookingsData = ref<any>(null)
const salesData = ref<any>(null)
const isLoading = ref<boolean>(true)
const timeElapsed = ref<number>(0)
const { fetchData }  = Dashboard()
const dates = ref<string | null>(null)
const subAgent = ref<any>(props.agent ? props.agent : '')
const auth = isLoggedIn()
const loyaltyData = ref<any>()
const openModal = ref<boolean>(false)
const isBusy = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { requestRedeemLoyalty } = Agent()
const loyaltyRedeemForm = ref<any>({
   bankName: { value: '', error: false, errorMessage: '' },
   accountName: { value: '', error: false, errorMessage: '' },
   accountNumber: { value: '', error: false, errorMessage: '' },
   countryName: { value: '', error: false, errorMessage: '' }
})

const dateRangeConfig : any = {
   mode: 'range',
   altFormat: 'd-m-Y',
   altInput: true,
   dateFormat: 'd-m-Y'
}

onMounted(async () => {

   disableScroll()
   const startTime = performance.now()
   await fetchAllData()
   timeElapsed.value = performance.now() - startTime
   if (timeElapsed.value < LOADER_MINIMUM_TIME) {
      const diff = LOADER_MINIMUM_TIME - timeElapsed.value
      setTimeout(() => {
         enableScroll()
         isLoading.value = false
      }, diff)
   } else {
      enableScroll()
      isLoading.value = false
   }
})

const handleRedeemPoints = () => {

   openModal.value = true
}

const handleModalClose = () => openModal.value = false

const scopes = ref<any>(
   [
      {id: 'today', name: 'Today', isCurrent: false},
      {id: 'yesterday', name: 'Yesterday', isCurrent: false},
      {id: 'last_7_days', name: 'Last 7 Days', isCurrent: false},
      {id: 'this_month', name: 'This Month', isCurrent: false},
      {id: 'last_month', name: 'Last Month', isCurrent: false},
      {id: 'all_time', name: 'All Time', isCurrent: true},
      {id: 'custom', name: 'Custom Duration', isCurrent: false}
   ]
)

const validateForm = () : boolean => {

   let response = true
   loyaltyRedeemForm.value.bankName.error = false
   loyaltyRedeemForm.value.accountNumber.error = false
   loyaltyRedeemForm.value.countryName.error = false

   if (loyaltyRedeemForm.value.bankName.value == '') {
      loyaltyRedeemForm.value.bankName.error = true
      loyaltyRedeemForm.value.bankName.errorMessage = 'Required'
      response = false
   }

   if (loyaltyRedeemForm.value.accountNumber.value == '') {
      loyaltyRedeemForm.value.accountNumber.error = true
      loyaltyRedeemForm.value.accountNumber.errorMessage = 'Required'
      response = false
   }

   if (loyaltyRedeemForm.value.countryName.value == '') {
      loyaltyRedeemForm.value.countryName.error = true
      loyaltyRedeemForm.value.countryName.errorMessage = 'Required'
      response = false
   }
   
   if (loyaltyRedeemForm.value.accountName.value == '') {
      loyaltyRedeemForm.value.accountName.error = true
      loyaltyRedeemForm.value.accountName.errorMessage = 'Required'
      response = false
   }

   return response
}

const handleModalSubmit = async () => {

   const isValid = validateForm()

   if (isValid && !isBusy.value && auth && auth.user) {
      isBusy.value = true
      const response = await requestRedeemLoyalty(loyaltyRedeemForm.value)

      if (response.status == 200) {
      
         loyaltyData.value.has_redeemed = 1
         openModal.value = false
      } else {
         const error = handleApiError(response)
         loyaltyRedeemForm.value.error = error
      }

      isBusy.value = false
   }  
}

const dataOptions = [
   { title: 'Daily', description: '', value: 'daily', current: true },
   { title: 'Weekly', description: '', value: 'weekly', current: false },
   { title: 'Monthly', description: '', value: 'monthly', current: false },
]

const bookingStats = ref<any>({
      total: { id: 1, name: 'Total Bookings', stat: 0 },
      confirmed: { id: 2, name: 'Confirmed', stat: 0 },
      pending: { id: 3, name: 'Pending', stat: 0 },
      subAgents: { id: 4, name: 'Agent Bookings', stat: 0 },
      cancelled: { id: 5, name: 'Cancelled Bookings', stat: 0 }
})

const notifClosed = () : void => {
   notif.value.show = false
}

const salesStats = ref<any>(
   {
      total: { id: 1, name: 'Total Sales', stat: 0 },
      commission: { id: 2, name: 'Total Commission', stat: 0 },
      pending: { id: 3, name: 'Pending Payments', stat: 0 },
      average: { id: 4, name: 'Avg Booking Value', stat: 0 }
   }
)

const handleSelectedOption = async (name: string, value: ChartOption) => {

   await fetchChartData(name, value) 
}

const redirectBookings = () => {

   if (props.user.type == 'admin') {

      router.push({name: 'AdminBookings'})
   } else if (props.user.type == 'agent') {

      router.push({name: 'UserBookings', params: {userType: 'agent', subUserId: ''}})
   } else if (props.user.type == 'corporate') {

      router.push({name: 'UserBookings', params: {userType: 'corporate', subUserId: ''}})
   }
}

const fetchChartData = async (chartName: string, option: ChartOption) => {

   const response = await fetchData(chartName, option, subAgent.value)

   if (response.status == 200) {
      
      if (chartName == 'bookings_chart') {

         prepareChartData('bookings', response)
      } else if (chartName == 'sales_chart') {
         
         prepareChartData('sales', response)
      }
   }
}

const fetchAllData = async () => {

   const response = await fetchData('all', 0, subAgent.value)
   if (response.status == 200) {
      prepareChartData('bookings', response)
      prepareChartData('sales', response)
      prepareBookingStats(response)
      prepareSalesStats(response)
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
   
   loyaltyData.value = response.data.loyalty_summary
}

const fetchStats = async () => {

   const activeScope = scopes.value.find((scope: any) => scope.isCurrent)

   if (activeScope) {

      const response = await fetchData('stats', activeScope.id, subAgent.value)
      if (response.status == 200) {
         
         prepareBookingStats(response)
         prepareSalesStats(response)
      }
   }
}

const prepareChartData = (chartName: string, response: any) => {

   if (chartName == 'bookings') {

      bookingsData.value = {
         labels: response.data.bookings_chart.data.labels,
         datasets: [
            {
               label: 'Bookings',
               data: response.data.bookings_chart.data.sets,
               backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED', '#77CEFF','#77CEFF','#77CEFF','#77CEFF'],
            },
         ]
      }
   } else if (chartName == 'sales') {

      salesData.value = {
         labels: response.data.sales_chart.data.labels,
         datasets: [
            {
               label: 'Sales',
               data: response.data.sales_chart.data.sets,
               backgroundColor: ['#f87979', '#f87979', '#f87979', '#f87979', '#f87979', '#f87979','#f87979','#f87979','#f87979'],
            },
         ]
      }
   }
}

const prepareBookingStats = (response: any) => {

   bookingStats.value.total.stat = response.data.bookings_summary.total_bookings
   bookingStats.value.confirmed.stat = response.data.bookings_summary.confirmed_bookings
   bookingStats.value.pending.stat = response.data.bookings_summary.pending_bookings
   bookingStats.value.subAgents.stat = response.data.bookings_summary.sub_agent_bookings
}

const prepareSalesStats = (response: any) => {

   salesStats.value.total.stat = response.data.sales_summary.total_sales
   salesStats.value.commission.stat = response.data.sales_summary.commission
   salesStats.value.pending.stat = response.data.sales_summary.pending_sales
   salesStats.value.average.stat = response.data.sales_summary.average
}

const handleScopeUpdate = async (item: any) => {

   scopes.value.forEach((scope: any) => {
      scope.isCurrent = false
   })

   scopes.value.find((scope: any) => scope.id === item.id).isCurrent = true
   dates.value = ''

   await fetchStats()
}

watch(() => props.agent, (newVallue, oldValue) => {
   
   subAgent.value = newVallue
   refetchData()
})

const refetchData = async () => {
   await fetchAllData()
}

watch(dates, (newValue, oldValue) => {

   if (newValue?.includes('to')) {
      
      scopes.value.forEach((scope: any) => {
         if (!scope.id.includes('custom')) {
            scope.isCurrent = false
         } else {
            scope.isCurrent = true
            const dateRange = `${newValue.split(' to ')[0]}_${newValue.split(' to ')[1]}`
            scope.id = `custom_${dateRange}`
         }
      })

      fetchStats().then(() => {
         
      })
   }
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.breadcrump)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
          _createElementVNode("ol", _hoisted_2, [
            _createElementVNode("li", null, [
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({name: 'Home'}))),
                  class: "text-gray-400 :hover:text-gray-500 cursor-pointer"
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    size: "lg",
                    icon: "fa-solid fa-home",
                    class: "text-gray-500"
                  }),
                  _cache[11] || (_cache[11] = _createElementVNode("span", { class: "sr-only" }, "Home", -1))
                ])
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.breadcrump, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.title
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _cache[12] || (_cache[12] = _createElementVNode("svg", {
                    class: "h-5 w-5 flex-shrink-0 text-gray-300",
                    fill: "currentColor",
                    viewBox: "0 0 20 20",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("path", { d: "M5.555 17.776l8-16 .894.448-8 16-.894-.448z" })
                  ], -1)),
                  _createElementVNode("div", {
                    onClick: ($event: any) => (item.link ? _ctx.$router.push(item.link) : null),
                    class: _normalizeClass([item.link ? 'cursor-pointer hover:text-gray-500' : '', "ml-4 text-sm font-medium text-gray-500"])
                  }, _toDisplayString(item.title), 11, _hoisted_4)
                ])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("main", _hoisted_6, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scopes.value, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: _normalizeClass([item.isCurrent ? 'text-theme-active' : 'text-gray-700', "cursor-pointer"])
                }, [
                  (!item.id.includes('custom'))
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        onClick: ($event: any) => (handleScopeUpdate(item))
                      }, _toDisplayString(item.name), 9, _hoisted_9))
                    : (_openBlock(), _createBlock(_unref(flatPickr), {
                        key: 1,
                        modelValue: dates.value,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((dates).value = $event)),
                        config: dateRangeConfig,
                        class: _normalizeClass([item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active', "border px-3 outline-none"]),
                        placeholder: "Custom date range"
                      }, null, 8, ["modelValue", "class"]))
                ], 2))
              }), 128))
            ]),
            _cache[13] || (_cache[13] = _createElementVNode("h1", { class: "text-base font-semibold leading-7 text-gray-900" }, "Set Time Duration", -1))
          ]),
          (_unref(auth) && _unref(auth).user && _unref(auth).user.type == 'agent' && _unref(auth).user.sub_type == null && loyaltyData.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _cache[14] || (_cache[14] = _createElementVNode("h3", { class: "text-base font-semibold leading-6 text-gray-900" }, "Loyalty Program Points", -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("h5", _hoisted_12, "You have earned " + _toDisplayString(loyaltyData.value.points_earned) + " points. Earn at least " + _toDisplayString(loyaltyData.value.total_points) + " points to win a cashback gift from vehitra", 1),
                  (loyaltyData.value.points_earned >= loyaltyData.value.total_points && loyaltyData.value.has_redeemed == 0)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        onClick: handleRedeemPoints,
                        class: "ml-auto bg-theme-button2-bg text-theme-button2-txt p-2 text-sm rounded"
                      }, "Redeem Points"))
                    : (loyaltyData.value.has_redeemed == 1)
                      ? (_openBlock(), _createElementBlock("button", _hoisted_13, "Points Redeemed"))
                      : _createCommentVNode("", true)
                ]),
                _createElementVNode("dl", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", {
                      class: "bg-blue-600 h-2.5 rounded-full max-w-full",
                      style: _normalizeStyle({ width: `${(loyaltyData.value.points_earned/loyaltyData.value.total_points) * 100}%` })
                    }, null, 4)
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "text-base font-semibold leading-6 text-gray-900 mt-12" }, "Bookings Summary", -1)),
          _createElementVNode("dl", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("dt", null, [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_font_awesome_icon, {
                    size: "1x",
                    icon: "fa-solid fa-book",
                    class: "text-white"
                  })
                ]),
                _createElementVNode("p", _hoisted_19, _toDisplayString(bookingStats.value.total.name), 1)
              ]),
              _createElementVNode("dd", _hoisted_20, [
                _createElementVNode("p", _hoisted_21, _toDisplayString(bookingStats.value.total.stat), 1),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (redirectBookings())),
                      class: "cursor-pointer font-medium text-theme-active"
                    }, " View all ")
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("dt", null, [
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_font_awesome_icon, {
                    size: "1x",
                    icon: "fa-solid fa-book",
                    class: "text-white"
                  })
                ]),
                _createElementVNode("p", _hoisted_26, _toDisplayString(bookingStats.value.confirmed.name), 1)
              ]),
              _createElementVNode("dd", _hoisted_27, [
                _createElementVNode("p", _hoisted_28, _toDisplayString(bookingStats.value.confirmed.stat), 1),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (redirectBookings())),
                      class: "cursor-pointer font-medium text-theme-active"
                    }, " View all ")
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("dt", null, [
                _createElementVNode("div", _hoisted_32, [
                  _createVNode(_component_font_awesome_icon, {
                    size: "1x",
                    icon: "fa-solid fa-book",
                    class: "text-white"
                  })
                ]),
                _createElementVNode("p", _hoisted_33, _toDisplayString(bookingStats.value.pending.name), 1)
              ]),
              _createElementVNode("dd", _hoisted_34, [
                _createElementVNode("p", _hoisted_35, _toDisplayString(bookingStats.value.pending.stat), 1),
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("div", {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (redirectBookings())),
                      class: "cursor-pointer font-medium text-theme-active"
                    }, " View all ")
                  ])
                ])
              ])
            ]),
            (__props.user.type == 'agent' && __props.user.sub_type == null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                  _createElementVNode("dt", null, [
                    _createElementVNode("div", _hoisted_39, [
                      _createVNode(_component_font_awesome_icon, {
                        size: "1x",
                        icon: "fa-solid fa-book",
                        class: "text-white"
                      })
                    ]),
                    _createElementVNode("p", _hoisted_40, _toDisplayString(bookingStats.value.subAgents.name), 1)
                  ]),
                  _createElementVNode("dd", _hoisted_41, [
                    _createElementVNode("p", _hoisted_42, _toDisplayString(bookingStats.value.subAgents.stat), 1),
                    _createElementVNode("div", _hoisted_43, [
                      _createElementVNode("div", _hoisted_44, [
                        _createElementVNode("div", {
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (redirectBookings())),
                          class: "cursor-pointer font-medium text-theme-active"
                        }, " View all ")
                      ])
                    ])
                  ])
                ]))
              : ((__props.user.type == 'corporate' || 'admin') || (__props.user.type == 'agent' && __props.user.sub_type == 'sub_agent'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                    _createElementVNode("dt", null, [
                      _createElementVNode("div", _hoisted_46, [
                        _createVNode(_component_font_awesome_icon, {
                          size: "1x",
                          icon: "fa-solid fa-book",
                          class: "text-white"
                        })
                      ]),
                      _createElementVNode("p", _hoisted_47, _toDisplayString(bookingStats.value.cancelled.name), 1)
                    ]),
                    _createElementVNode("dd", _hoisted_48, [
                      _createElementVNode("p", _hoisted_49, _toDisplayString(bookingStats.value.cancelled.stat), 1),
                      _createElementVNode("div", _hoisted_50, [
                        _createElementVNode("div", _hoisted_51, [
                          _createElementVNode("div", {
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (redirectBookings())),
                            class: "cursor-pointer font-medium text-theme-active"
                          }, " View all ")
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", null, [
          _cache[16] || (_cache[16] = _createElementVNode("h3", { class: "text-base font-semibold leading-6 text-gray-900" }, "Sales Summary", -1)),
          _createElementVNode("dl", _hoisted_52, [
            _createElementVNode("div", _hoisted_53, [
              _createElementVNode("dt", _hoisted_54, _toDisplayString(salesStats.value.total.name), 1),
              _createElementVNode("dd", _hoisted_55, [
                _createElementVNode("div", _hoisted_56, _toDisplayString(salesStats.value.total.stat), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_57, [
              _createElementVNode("dt", _hoisted_58, _toDisplayString(salesStats.value.average.name), 1),
              _createElementVNode("dd", _hoisted_59, [
                _createElementVNode("div", _hoisted_60, _toDisplayString(salesStats.value.average.stat), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_61, [
          _cache[17] || (_cache[17] = _createElementVNode("div", null, [
            _createElementVNode("h3", { class: "text-base font-semibold leading-6 text-gray-900" }, "Bookings Growth"),
            _createElementVNode("h5", { class: "text-gray-500 mt-1 text-sm" }, "Showing growth by booking date")
          ], -1)),
          _createElementVNode("div", null, [
            _createVNode(ListBox, {
              name: "bookings_chart",
              data: dataOptions,
              onSelected: handleSelectedOption
            })
          ])
        ]),
        (bookingsData.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
              _createVNode(_unref(BarChart), { chartData: bookingsData.value }, null, 8, ["chartData"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_63, [
          _cache[18] || (_cache[18] = _createElementVNode("div", null, [
            _createElementVNode("h3", { class: "text-base font-semibold leading-6 text-gray-900" }, "Sales Growth"),
            _createElementVNode("h5", { class: "text-gray-500 mt-1 text-sm" }, "Showing sales growth")
          ], -1)),
          _createElementVNode("div", null, [
            _createVNode(ListBox, {
              name: "sales_chart",
              data: dataOptions,
              onSelected: handleSelectedOption
            })
          ])
        ]),
        (salesData.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
              _createVNode(_unref(BarChart), { chartData: salesData.value }, null, 8, ["chartData"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(Modal, {
      show: openModal.value,
      canClose: true,
      onClosed: handleModalClose
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_65, [
          _createElementVNode("div", _hoisted_66, [
            _cache[23] || (_cache[23] = _createElementVNode("h2", { class: "mb-2" }, "Enter your bank account details to receive the cashback", -1)),
            _createElementVNode("div", _hoisted_67, [
              _cache[19] || (_cache[19] = _createElementVNode("label", {
                for: "reset-password",
                class: "uppercase text-small text-theme-gray-4"
              }, "Your Bank Name *", -1)),
              _createElementVNode("div", _hoisted_68, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((loyaltyRedeemForm.value.bankName.value) = $event)),
                  type: "text",
                  class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
                }, null, 512), [
                  [_vModelText, loyaltyRedeemForm.value.bankName.value]
                ])
              ]),
              (loyaltyRedeemForm.value.bankName.error)
                ? (_openBlock(), _createElementBlock("span", _hoisted_69, _toDisplayString(loyaltyRedeemForm.value.bankName.errorMessage), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_70, [
              _cache[20] || (_cache[20] = _createElementVNode("label", {
                for: "reset-password",
                class: "uppercase text-small text-theme-gray-4"
              }, "Your Account Title *", -1)),
              _createElementVNode("div", _hoisted_71, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((loyaltyRedeemForm.value.accountName.value) = $event)),
                  type: "text",
                  class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
                }, null, 512), [
                  [_vModelText, loyaltyRedeemForm.value.accountName.value]
                ])
              ]),
              (loyaltyRedeemForm.value.accountName.error)
                ? (_openBlock(), _createElementBlock("span", _hoisted_72, _toDisplayString(loyaltyRedeemForm.value.accountName.errorMessage), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_73, [
              _cache[21] || (_cache[21] = _createElementVNode("label", {
                for: "reset-password",
                class: "uppercase text-small text-theme-gray-4"
              }, "Your Bank Account Number or IBAN *", -1)),
              _createElementVNode("div", _hoisted_74, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((loyaltyRedeemForm.value.accountNumber.value) = $event)),
                  type: "text",
                  class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
                }, null, 512), [
                  [_vModelText, loyaltyRedeemForm.value.accountNumber.value]
                ])
              ]),
              (loyaltyRedeemForm.value.accountNumber.error)
                ? (_openBlock(), _createElementBlock("span", _hoisted_75, _toDisplayString(loyaltyRedeemForm.value.accountNumber.errorMessage), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_76, [
              _cache[22] || (_cache[22] = _createElementVNode("label", {
                for: "reset-password",
                class: "uppercase text-small text-theme-gray-4"
              }, "Bank Account Country Name *", -1)),
              _createElementVNode("div", _hoisted_77, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((loyaltyRedeemForm.value.countryName.value) = $event)),
                  type: "text",
                  class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
                }, null, 512), [
                  [_vModelText, loyaltyRedeemForm.value.countryName.value]
                ])
              ]),
              (loyaltyRedeemForm.value.countryName.error)
                ? (_openBlock(), _createElementBlock("span", _hoisted_78, _toDisplayString(loyaltyRedeemForm.value.countryName.errorMessage), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_79, [
              _createElementVNode("div", {
                onClick: handleModalSubmit,
                class: _normalizeClass([isBusy.value ? 'cursor-not-allowed opacity-70' : 'bg-theme-active hover:bg-white cursor-pointer', "gap-4 uppercase rounded-full px-4 w-full hover:text-theme-active md:w-auto md:px-8 py-2 md:py-4 border-2 border-theme-active text-white text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto transition-all ease-in-out duration-500"])
              }, " Submit ", 2)
            ]),
            (loyaltyRedeemForm.value.error)
              ? (_openBlock(), _createElementBlock("p", _hoisted_80, _toDisplayString(loyaltyRedeemForm.value.error), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(Loader, { show: isLoading.value }, null, 8, ["show"])
  ], 64))
}
}

})