import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, unref as _unref } from "vue"

const _hoisted_1 = { class: "w-full mb-5" }
const _hoisted_2 = { class: "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md" }
const _hoisted_3 = { class: "space-y-1 text-center" }
const _hoisted_4 = { class: "flex justify-center text-sm text-gray-600" }
const _hoisted_5 = {
  key: 0,
  class: "text-xs text-gray-500"
}
const _hoisted_6 = { class: "w-full mb-5" }
const _hoisted_7 = { class: "w-full mb-5" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "w-full mb-5" }
const _hoisted_10 = ["onUpdate:modelValue"]
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "w-full mb-5" }
const _hoisted_13 = { class: "w-full mb-5" }
const _hoisted_14 = { class: "w-full mb-5" }
const _hoisted_15 = { class: "flex" }

import { ref, onMounted } from 'vue'
import { CarType, Supplier } from '@/types/index'
import Admin from '@/composables/admin/index'
import { truncateText, resetErrors, handleApiError, isImageFile } from '@/utils/common'


export default /*@__PURE__*/_defineComponent({
  __name: 'SaveCar',
  props: ['car', 'carTypes', 'suppliers'],
  emits: ['success', 'error'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const { createCar, updateCar } = Admin()
const isLoading = ref<boolean>(false)
const fileInput = ref<HTMLInputElement | null>(null)
const photoName = ref<string>('')
const form = ref({
   name: '',
   photo: '',
   type: 1,
   suppliers: [
      {id: 1}
   ],
   displacement: '',
   transmission: 'manual',
   meet_greet: '1'
})
const errors = ref({
   name: false,
   displacement: false,
   photo: ''
})

onMounted(() => {
   mounted() 
})

const mounted = async () => {

   if (props.car) {
      photoName.value = props.car.imageName ? props.car.imageName : ''
      form.value = {
         name: props.car.name,
         photo: '',
         type: props.car.type.id,
         suppliers: [
            {id: props.car.supplier.id}
         ],
         displacement: props.car.displacement,
         transmission: props.car.transmission,
         meet_greet: props.car.meetGreet
      }
   }
}

const handleChoosePhoto = () => {
   fileInput.value?.click()
}

const addSupplier = () => {

   const newSupplier = {id: 1}
   form.value.suppliers.push(newSupplier)
}

const uploadPhoto = (e: Event) => {

   form.value.photo = ''
   photoName.value = ''
   errors.value.photo = ''

   const inputElement = e.target as HTMLInputElement

   if (!inputElement.files || inputElement.files.length === 0) {
      return
   }

   const file = inputElement.files[0]
   const reader = new FileReader()

   if (!isImageFile(file)) {
      errors.value.photo = 'Upload a valid image file'
      return
   } else if (file.size > 3145728) {
      errors.value.photo = 'Image must be of size under 3MB'
      return
   }

   photoName.value = file.name

   reader.onloadend = () => {
      form.value.photo = reader.result as string
   }

   reader.readAsDataURL(file)
}

const validateForm = () : boolean => {

   let response = true

   if (form.value.name === '') {
      errors.value.name = true
      response = false
   }

   if (form.value.displacement === '') {
      errors.value.displacement = true
      response = false
   }

   return response 
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid) {
      
      if (props.car == null) {

         isLoading.value = true
         const response = await createCar(form.value)
         isLoading.value = false
         if (response.status == 201) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }  
      } else {

         isLoading.value = true
         const response = await updateCar(props.car.id, form.value)
         isLoading.value = false
         if (response.status == 200) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }
      }
   }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[9] || (_cache[9] = _createElementVNode("label", {
        for: "document-file",
        class: "block text-sm font-medium text-gray-700"
      }, " Car photo ", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[7] || (_cache[7] = _createElementVNode("svg", {
            class: "mx-auto h-12 w-12 text-gray-400",
            stroke: "currentColor",
            fill: "none",
            viewBox: "0 0 48 48",
            "aria-hidden": "true"
          }, [
            _createElementVNode("path", {
              d: "M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02",
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", {
              onClick: handleChoosePhoto,
              class: "relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 outline-none"
            }, [
              _cache[6] || (_cache[6] = _createElementVNode("button", { type: "button" }, "Click to upload a file", -1)),
              _createElementVNode("input", {
                class: "hidden",
                ref_key: "fileInput",
                ref: fileInput,
                type: "file",
                onChange: uploadPhoto,
                accept: "image/*"
              }, null, 544)
            ])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-xs text-gray-500" }, [
            _createElementVNode("span", null, "(Files up to 3MB)")
          ], -1)),
          (photoName.value != '')
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(photoName.value), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", {
        for: "name",
        class: _normalizeClass([!errors.value.name ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
      }, "Name", 2),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.name) = $event)),
        type: "text",
        class: _normalizeClass([!errors.value.name ? 'border-gray-300' : 'border-theme-error', "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm"])
      }, null, 2), [
        [_vModelText, form.value.name]
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[10] || (_cache[10] = _createElementVNode("label", {
        for: "stripe-id",
        class: "block text-sm font-medium text-gray-700"
      }, "Car type", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.type) = $event)),
        class: "block mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.carTypes, (carType, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: carType.id
          }, _toDisplayString(carType.name), 9, _hoisted_8))
        }), 128))
      ], 512), [
        [_vModelSelect, form.value.type]
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(form.value.suppliers, (eachSupplier) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_9, [
        _cache[11] || (_cache[11] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-700" }, "Supplier", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": ($event: any) => ((eachSupplier.id) = $event),
          class: "block overflow-hidden mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.suppliers, (supplier, index) => {
            return (_openBlock(), _createElementBlock("option", {
              key: index,
              value: supplier.id
            }, _toDisplayString(_unref(truncateText)(supplier.name, 40)), 9, _hoisted_11))
          }), 128))
        ], 8, _hoisted_10), [
          [_vModelSelect, eachSupplier.id]
        ])
      ]))
    }), 256)),
    _createElementVNode("div", { class: "flex items-center justify-between" }, [
      _createElementVNode("button", {
        type: "button",
        class: "group ml-auto flex items-center rounded-md bg-white p-1 outline-none"
      }, [
        _cache[12] || (_cache[12] = _createElementVNode("span", { class: "flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400" }, [
          _createElementVNode("svg", {
            class: "h-5 w-5",
            viewBox: "0 0 20 20",
            fill: "currentColor",
            "aria-hidden": "true"
          }, [
            _createElementVNode("path", { d: "M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" })
          ])
        ], -1)),
        _createElementVNode("span", {
          onClick: addSupplier,
          class: "ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500"
        }, "Add supplier")
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("label", {
        for: "stripe-id",
        class: _normalizeClass([!errors.value.displacement ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
      }, "Power (cc)", 2),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.displacement) = $event)),
        type: "number",
        class: _normalizeClass([!errors.value.name ? 'border-gray-300' : 'border-theme-error', "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm"])
      }, null, 2), [
        [_vModelText, form.value.displacement]
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _cache[14] || (_cache[14] = _createElementVNode("label", {
        for: "stripe-id",
        class: "block text-sm font-medium text-gray-700"
      }, "Transmission type", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.transmission) = $event)),
        class: "block overflow-hidden mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6"
      }, _cache[13] || (_cache[13] = [
        _createElementVNode("option", { value: "manual" }, "Manual", -1),
        _createElementVNode("option", { value: "automatic" }, "Automatic", -1)
      ]), 512), [
        [_vModelSelect, form.value.transmission]
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _cache[16] || (_cache[16] = _createElementVNode("label", {
        for: "stripe-id",
        class: "block text-sm font-medium text-gray-700"
      }, "Meet and greet", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.meet_greet) = $event)),
        class: "block overflow-hidden mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6"
      }, _cache[15] || (_cache[15] = [
        _createElementVNode("option", { value: "1" }, "Yes", -1),
        _createElementVNode("option", { value: "0" }, "No", -1)
      ]), 512), [
        [_vModelSelect, form.value.meet_greet]
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("button", {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (handleSubmit())),
        type: "button",
        class: "ml-auto mt-20 bg-indigo-600 py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 outline-none"
      }, " Save ")
    ])
  ], 64))
}
}

})