<template>

   <TopBanner layout="full-width" currentPage="Login" heading="Login" bannerClass="banner-auth" />
   <div class="py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
      <div class="flex flex-col w-full lg:w-theme-auth mx-auto gap-6">
         <div class="flex flex-col gap-2">
            <label for="login-username" class="uppercase text-small text-theme-gray-4">Username or email address *</label>
            <div class="flex flex-row items-center border border-theme-gray-7">
               <input v-model="email" type="text" id="login-username" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
            </div>
         </div>
         <div class="flex flex-col gap-2">
            <label for="login-password" class="uppercase text-small text-theme-gray-4">Password *</label>
            <div class="flex flex-row items-center border border-theme-gray-7">
               <input v-model="password" :type="passwordFieldtype" id="login-password" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
               <div @click="togglePasswordFieldType">
                  <font-awesome-icon icon="fa-solid fa-eye" class="text-theme-gray-4 cursor-pointer ml-auto mr-1" />
               </div>
            </div>
         </div>
         <div class="flex flex-col md:flex-row md:items-center gap-4 mt-2">
            <div @click="$router.push({ name: 'ForgetPassword' })" class="order-2 md:order-1 gap-4 uppercase rounded-full px-4 md:px-8 py-2 md:py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer transition-all ease-in-out duration-500">
               Lost your password
            </div>
            <div @click="handleLogin" :class="isLoading ? 'cursor-not-allowed opacity-40' : 'cursor-pointer hover:text-theme-active hover:bg-white'" class="order-1 md:order-2 gap-4 uppercase rounded-full w-full md:w-auto px-4 md:px-8 py-2 md:py-4 border-2 border-theme-active text-white bg-theme-active text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto transition-all ease-in-out duration-500">
               Login
            </div>
         </div>
      </div>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import { NotificationType } from '@/types/index'
import Auth from '@/composables/Auth'
import { handleApiError, saveAuthCredentials } from '@/utils/common'
import Notification from '@/widgets/Notification.vue'
import { useRouter } from 'vue-router'

const passwordFieldtype = ref<'password' | 'text'>('password')
const email = ref<string>('')
const password = ref<string>('')
const isLoading = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { loginUser }  = Auth()
const router = useRouter()

const togglePasswordFieldType = () :void => {
   passwordFieldtype.value = passwordFieldtype.value == 'password' ? 'text' : 'password'
}

const handleLogin = async () : Promise<void> => {

   if (email.value != '' && password.value != '' && !isLoading.value) {
      
      isLoading.value = true
      const response = await loginUser(email.value, password.value)
      isLoading.value = false
      if (response.status == 200) {
         const accountType = saveAuthCredentials(response.data)

         if (accountType == 'agent' || accountType == 'corporate') {
            router.push({name: 'AccountDashboard'})
         } else {
            //router.push({name: 'Home'})
            location.reload()
         }
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
         setTimeout(() => { notifClosed() }, 4000)
      }
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}

</script>