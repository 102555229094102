import { getBackendBaseUrl } from "@/utils/common"
import axios from "axios"
import { getUserToken } from "@/utils/common"

const baseUrl : string = getBackendBaseUrl()

const Auth = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getUserToken()}`,
         'Content-Type': 'application/json',
      }
   }

   const loginUser = async (email : string, password: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/login', { email: email, password: password })
         return response
      } catch (err : any) {
         return err
      }
   }

   const registerUser = async (fullname: string, email : string, phoneNumber: string, companyName: string, password: string, passwordConfirmation: string, type: string, recaptcha: any) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/register', { name: fullname, email: email, phoneNumber: phoneNumber, companyName: companyName, password: password, passwordConfirmation: passwordConfirmation, type: type, recaptcha: recaptcha })
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const registerSupplier = async (fullname: string, companyName: string, email : string, phoneNumber: string, message: string, recaptcha: any) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/supplier/register-request', { name: fullname, email: email, companyName: companyName, phoneNumber: phoneNumber, message: message, recaptcha: recaptcha })
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const registerAgent = async (fullname: string, companyName: string, email : string, phoneNumber: string, password: string, passwordConfirmation: string, recaptcha: any) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/agent/create', { name: fullname, email: email, companyName: companyName, phoneNumber: phoneNumber, password: password, passwordConfirmation: passwordConfirmation, type: 'agent', origin: window.location.origin, recaptcha: recaptcha })
         return response
      } catch (err : any) {
         return err
      }
   }

   const forgetPassword = async (email: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/forget-password', { email: email, origin: window.location.origin })
         return response
      } catch (err : any) {
         return err
      }
   }

   const checkResetToken = async (token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/reset-password/check-token', { token: token })
         return response
      } catch (err : any) {
         return err
      }
   }

   const checkAgentActivateAccountToken = async (token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/agent/check-token', { token: token })
         return response
      } catch (err : any) {
         return err
      }
   }

   const checkAgentPasswordSetupToken = async (token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/agent/sub-agents/check-token', { token: token })
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const checkCorporatePasswordSetupToken = async (token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/corporate/sub-users/check-token', { token: token })
         return response
      } catch (err : any) {
         return err
      }
   }

   const resetPassword = async (password: string, passwordConfirmation: string, token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/reset-password', { password: password, passwordConfirmation: passwordConfirmation, token: token })
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const setupPassword = async (password: string, passwordConfirmation: string) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + 'api/setup-password', { password: password, passwordConfirmation: passwordConfirmation }, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const setupAgentPassword = async (password: string, passwordConfirmation: string, token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/agent/sub-agents/setup-password', { password: password, passwordConfirmation: passwordConfirmation, token: token })
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const setupCorporatePassword = async (password: string, passwordConfirmation: string, token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/corporate/sub-users/setup-password', { password: password, passwordConfirmation: passwordConfirmation, token: token })
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      loginUser,
      registerUser,
      forgetPassword,
      checkResetToken,
      resetPassword,
      checkAgentPasswordSetupToken,
      setupAgentPassword,
      checkCorporatePasswordSetupToken,
      setupCorporatePassword,
      registerSupplier,
      setupPassword,
      registerAgent,
      checkAgentActivateAccountToken
   }
}

export default Auth