import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-1" }
const _hoisted_2 = {
  type: "button",
  class: "font-bold text-theme-gray-5 text-size-6 border-0 outline-none"
}
const _hoisted_3 = {
  key: 0,
  class: "z-10 max-h-[200px] overflow-y-auto origin-top-right absolute right-0 mt-4 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = {
  key: 1,
  class: "font-bold text-theme-gray-5 text-size-6"
}

import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { getHoursPerDay } from '@/utils/common'


export default /*@__PURE__*/_defineComponent({
  __name: 'DaysField',
  props: ['layout', 'type', 'data'],
  emits: ['selected'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const isDropOpen = ref<boolean>(false)
const days : number[] = [1,2,3,4,5,6,7,8,9,10]
const selectedDay = ref<number>(days[0])
const componentRef = ref()
const hoursPerDay : number = getHoursPerDay()

const toggleDrop = () => {
   isDropOpen.value = !isDropOpen.value
}

onMounted(() => {

   if (props.data && (props.data % 10 === 0)) {
      selectedDay.value = props.data / hoursPerDay
   }
})

watch(selectedDay, (newValue, oldValue) => {
   emit('selected', newValue * hoursPerDay)
})

const listener = (event: any) => {
   if (event.target !== componentRef.value) {
      //isDropOpen.value = false
   }
}

onMounted(() => {
   document.addEventListener('click', listener)
})

onBeforeUnmount(() => {
   document.removeEventListener('click', listener)
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative p-5", __props.layout == 'simple' ? 'col-span-7 md:col-span-2' : 'col-span-8'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "uppercase text-small text-theme-gray-2" }, "Days", -1)),
      (__props.layout == 'simple')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref_key: "componentRef",
            ref: componentRef,
            class: "inline-block text-left cursor-pointer",
            onClick: toggleDrop
          }, [
            _createElementVNode("button", _hoisted_2, _toDisplayString(selectedDay.value) + " day(s)", 1),
            (isDropOpen.value)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(days, (day, index) => {
                    return _createElementVNode("li", {
                      key: index,
                      onClick: ($event: any) => (selectedDay.value=day),
                      class: "py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer"
                    }, _toDisplayString(day) + " day(s) ", 9, _hoisted_4)
                  }), 64))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_font_awesome_icon, {
                size: "lg",
                icon: "fa-solid fa-chevron-down",
                class: "text-theme-active absolute right-0 bottom-1"
              })
            ])
          ], 512))
        : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(selectedDay.value) + " Day(s)", 1))
    ])
  ], 2))
}
}

})