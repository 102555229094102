import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8" }
const _hoisted_2 = { class: "mt-10 sm:mx-auto sm:w-full sm:max-w-sm" }
const _hoisted_3 = {
  class: "space-y-6",
  action: "#",
  method: "POST"
}
const _hoisted_4 = { class: "mt-2" }
const _hoisted_5 = { class: "mt-2" }

import { ref } from 'vue'
import AdminAuth from '@/composables/admin/Auth'
import { handleApiError, saveAdminAuthCredentials } from '@/utils/common'
import { NotificationType } from '@/types'
import { useRouter } from 'vue-router'
import Notification from '@/widgets/Notification.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminLogin',
  setup(__props) {

const email = ref<string>('')
const password = ref<string>('')
const isLoading = ref<boolean>(false)
const { login }  = AdminAuth()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const router = useRouter()

const handleLogin = async () => {

   if (email.value != '' && password.value != '') {
      
      isLoading.value = true
      const response = await login(email.value, password.value)
      isLoading.value = false
      if (response.status == 200) {
         saveAdminAuthCredentials(response.data)
         router.push({name: 'AdminDashboard'})
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
         setTimeout(() => { notifClosed() }, 4000)
      }
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "sm:mx-auto sm:w-full sm:max-w-sm" }, [
        _createElementVNode("img", {
          class: "mx-auto h-10 w-auto",
          src: "/assets/logo.png",
          alt: "Vehitra World"
        }),
        _createElementVNode("h2", { class: "mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900" }, "Sign in to your account")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("form", _hoisted_3, [
          _createElementVNode("div", null, [
            _cache[2] || (_cache[2] = _createElementVNode("label", {
              for: "email",
              class: "block text-sm font-medium leading-6 text-gray-900"
            }, "Email address", -1)),
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                type: "email",
                autocomplete: "email",
                class: "pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none"
              }, null, 512), [
                [_vModelText, email.value]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex items-center justify-between" }, [
              _createElementVNode("label", {
                for: "password",
                class: "block text-sm font-medium leading-6 text-gray-900"
              }, "Password")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
                type: "password",
                autocomplete: "current-password",
                class: "pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none"
              }, null, 512), [
                [_vModelText, password.value]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              onClick: handleLogin,
              class: _normalizeClass([isLoading.value ? 'cursor-not-allowed' : 'hover:bg-indigo-500', "flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm outline-none"]),
              type: "button"
            }, "Sign in", 2)
          ])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("p", { class: "mt-10 text-center text-sm text-gray-500" }, [
          _createTextVNode("Not a member?" + _toDisplayString(' ') + " "),
          _createElementVNode("a", {
            href: "#",
            class: "font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          }, "Start a 14 day free trial")
        ], -1))
      ])
    ]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"])
  ], 64))
}
}

})