import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex flex-row items-center w-full" }
const _hoisted_4 = {
  key: 1,
  class: "font-bold text-theme-gray-5 text-size-6"
}
const _hoisted_5 = {
  key: 2,
  class: "ml-auto"
}
const _hoisted_6 = {
  key: 4,
  class: "ml-auto"
}
const _hoisted_7 = {
  key: 0,
  class: "z-10 !mt-0 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "mt-1"
}
const _hoisted_10 = {
  key: 1,
  class: "mt-[0.13rem]"
}

import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import Location from '@/composables/Location'
import { handleApiError, sortPlacePredictions } from '@/utils/common'
import { GoogleLocationType } from '@/types/index'
import LoaderIcon from '@/components/LoaderIcon.vue'

const SEARCH_DELAY = 400

export default /*@__PURE__*/_defineComponent({
  __name: 'LocationField',
  props: ['drive', 'layout', 'data', 'label', 'type', 'hasError'],
  emits: ['addressSelected', 'addressEmpty'],
  setup(__props, { emit: __emit }) {

const { searchLocation, searchLocality } = Location()
const props = __props
const emit = __emit
const error = ref<string | false>(false)
const isLoading = ref<boolean>(false)
const address = ref<string>('')
const isAddressDropOpen = ref<boolean>(false)
const addresses = ref<GoogleLocationType[]>([])
const selectedAddress = ref<GoogleLocationType | null>(null)
let searchTimeout: ReturnType<typeof setTimeout> | null = null
const searchMode = ref<'airport' | 'full'>('full')

const handleAddressSelect = (value: GoogleLocationType) => {
   address.value = value.name
   selectedAddress.value = value
   isAddressDropOpen.value = false
}

const handleAddressSearch = async () => {
   const addr = address.value.toLowerCase().trim()

   if (addr.length > 2) {

      if (searchTimeout) {
         clearTimeout(searchTimeout);
      }

      searchTimeout = setTimeout(async () => {

         isLoading.value = true
         const response = searchMode.value == 'airport' ? await searchLocality(addr, 'airport') : await searchLocation(addr)
         isLoading.value = false

         if (response.status == 200) {

            let adds : GoogleLocationType[] = []
            if (searchMode.value == 'full') {

               const predictions = sortPlacePredictions(response.data.data)
               predictions.forEach((item: any) => {
                  adds.push({
                     id: item.id,
                     name: item.name,
                     isAirport: item.isAirport,
                     coordinates: {lat: null, lng: null}
                  })
               })
            } else {
               response.data.data.forEach((item: any) => {
                  adds.push({
                     id: item.id,
                     name: item.name,
                     isAirport: true,
                     coordinates: {lat: null, lng: null}
                  })
               })
            }

            addresses.value = adds
            isAddressDropOpen.value = adds.length ? true : false
         } else {
            error.value = handleApiError(response)
         }
         
      }, SEARCH_DELAY)

   }
}

watch(selectedAddress, (newValue, oldValue) => {
  emit('addressSelected', newValue, props.type)
})

watch(() => props.drive, (newValue, oldValue) => {
   //searchMode.value = newValue == 'distance' && props.type == 'pickUp' ? 'airport' : 'full'
   searchMode.value = 'full'
})

watch(address, (newValue, oldValue) => {

   if (newValue === '') {
      emit('addressSelected', null, props.type)
   }
})

const listener = (event: any) => {
   isAddressDropOpen.value = false
}

const handleClearInput = () => {
   selectedAddress.value = null
   address.value = ''
}

onMounted(() => {
   document.addEventListener('click', listener)

   if (props.data) {
      selectedAddress.value = {
         id: props.data.location.id,
         name: props.data.location.name,
         isAirport: props.data.location.isAirport,
         coordinates: {lat: null, lng: null}
      }

      address.value = props.data.location.name
   }
})

onBeforeUnmount(() => {
   document.removeEventListener('click', listener)
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative p-5", __props.layout == 'simple' ? 'col-span-7 md:col-span-2' : 'col-span-8'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([__props.hasError ? 'text-theme-error' : 'text-theme-gray-2', "flex gap-2 uppercase text-small"])
      }, [
        _createElementVNode("span", null, _toDisplayString(__props.label) + " " + _toDisplayString(searchMode.value == 'airport' ? '(airports only)' : ''), 1),
        (__props.hasError)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(required)"))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", _hoisted_3, [
        (__props.layout == 'simple')
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((address).value = $event)),
              onInput: handleAddressSearch,
              type: "text",
              placeholder: "Enter Airport, Hotel or Address",
              class: "flex-1 font-bold text-theme-gray-5 text-size-6 border-0 outline-none text-left pr-2"
            }, null, 544)), [
              [_vModelText, address.value]
            ])
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(address.value), 1)),
        (isLoading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(LoaderIcon)
            ]))
          : (selectedAddress.value && __props.layout == 'simple')
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                onClick: handleClearInput,
                class: "ml-auto"
              }, [
                _createVNode(_component_font_awesome_icon, {
                  size: "lg",
                  icon: "fa-solid fa-times-circle",
                  class: "text-theme-active cursor-pointer"
                })
              ]))
            : (__props.hasError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_font_awesome_icon, {
                    size: "1x",
                    icon: "fa-solid fa-circle-exclamation",
                    class: "text-theme-error"
                  })
                ]))
              : _createCommentVNode("", true)
      ]),
      (isAddressDropOpen.value && addresses.value.length)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(addresses.value, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                onClick: ($event: any) => (handleAddressSelect(item)),
                key: index,
                class: "flex flex-row items-start gap-2 py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer"
              }, [
                (item.isAirport)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createVNode(_component_font_awesome_icon, {
                        size: "1x",
                        icon: "fa-solid fa-plane"
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_10, [
                      _createVNode(_component_font_awesome_icon, {
                        size: "1x",
                        icon: "fa-solid fa-location-dot"
                      })
                    ])),
                _createElementVNode("span", null, _toDisplayString(item.name), 1)
              ], 8, _hoisted_8))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})