import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col pb-12 px-11 lg:w-theme-dw md:mx-auto md:px-0"
}
const _hoisted_2 = { class: "col-span-12 md:pb-12 flex flex-col items-center justify-center gap-6 mt-16" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = { class: "flex items-center justify-center absolute -left-3 w-[100px] h-[100px] rounded-full border border-theme-gray-7" }
const _hoisted_5 = {
  key: 1,
  class: "flex flex-col pb-12 px-11 lg:w-theme-dw md:mx-auto md:px-0"
}
const _hoisted_6 = {
  key: 0,
  class: "rounded-md bg-yellow-50 py-8 px-4 mb-12"
}
const _hoisted_7 = { class: "pb-24 pt-12 sm:px-6 sm:pb-32 sm:pt-18 lg:px-8 bg-gray-100" }
const _hoisted_8 = { class: "space-y-10 divide-y divide-gray-900/10" }
const _hoisted_9 = { class: "grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3" }
const _hoisted_10 = { class: "bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" }
const _hoisted_11 = { class: "px-4 py-6 sm:p-8" }
const _hoisted_12 = { class: "mt-10" }
const _hoisted_13 = { class: "grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6" }
const _hoisted_14 = { class: "sm:col-span-3" }
const _hoisted_15 = { class: "mt-2" }
const _hoisted_16 = {
  key: 0,
  class: "mt-1 text-sm text-red-600"
}
const _hoisted_17 = { class: "sm:col-span-3" }
const _hoisted_18 = { class: "mt-2" }
const _hoisted_19 = {
  key: 0,
  class: "grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3"
}
const _hoisted_20 = { class: "bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" }
const _hoisted_21 = { class: "px-4 py-6 sm:p-8" }
const _hoisted_22 = { class: "grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6" }
const _hoisted_23 = { class: "sm:col-span-3" }
const _hoisted_24 = { class: "mt-2 relative" }
const _hoisted_25 = {
  key: 0,
  class: "z-10 !mt-0 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5"
}
const _hoisted_26 = ["onClick"]
const _hoisted_27 = {
  key: 0,
  class: "mt-1"
}
const _hoisted_28 = {
  key: 1,
  class: "mt-[0.13rem]"
}
const _hoisted_29 = {
  key: 0,
  class: "mt-1 text-sm text-red-600"
}
const _hoisted_30 = { class: "sm:col-span-3" }
const _hoisted_31 = {
  for: "last-name",
  class: "block text-sm font-medium leading-6 text-gray-900"
}
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { class: "mt-2" }
const _hoisted_35 = {
  key: 0,
  class: "mt-1 text-sm text-red-600"
}
const _hoisted_36 = { class: "sm:col-span-3" }
const _hoisted_37 = { class: "mt-2" }
const _hoisted_38 = {
  key: 0,
  class: "mt-1 text-sm text-red-600"
}
const _hoisted_39 = { class: "sm:col-span-3" }
const _hoisted_40 = { class: "mt-2" }
const _hoisted_41 = {
  key: 0,
  class: "mt-1 text-sm text-red-600"
}
const _hoisted_42 = { class: "grid grid-cols-1 gap-x-8 pt-10 gap-y-8 md:grid-cols-3" }
const _hoisted_43 = { class: "bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" }
const _hoisted_44 = { class: "px-4 py-6 sm:p-8" }
const _hoisted_45 = { class: "grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6" }
const _hoisted_46 = { class: "sm:col-span-3" }
const _hoisted_47 = { class: "mt-2" }
const _hoisted_48 = {
  key: 0,
  class: "mt-1 text-sm text-red-600"
}
const _hoisted_49 = { class: "sm:col-span-3" }
const _hoisted_50 = { class: "mt-2" }
const _hoisted_51 = {
  key: 0,
  class: "mt-1 text-sm text-red-600"
}
const _hoisted_52 = { class: "sm:col-span-3" }
const _hoisted_53 = { class: "mt-2" }
const _hoisted_54 = {
  key: 0,
  class: "mt-1 text-sm text-red-600"
}
const _hoisted_55 = { class: "sm:col-span-6" }
const _hoisted_56 = { class: "mt-2" }
const _hoisted_57 = { class: "w-fit ml-auto mt-6" }

import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import Location from '@/composables/Location'
import { GoogleLocationType } from '@/types'
import { handleApiError, isValidEmail, sortPlacePredictions } from '@/utils/common'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import { LeadForm } from '@/types/lead'
import Lead from '@/composables/Lead'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
import { useRouter, parseQuery } from 'vue-router'
import { retrieveUserSearch, saveUserTempSearch } from '@/services/search-service'

const SEARCH_DELAY = 400

export default /*@__PURE__*/_defineComponent({
  __name: 'BusesCoaches',
  props: ['service'],
  setup(__props) {

const props = __props
const router = useRouter()
let searchTimeout: ReturnType<typeof setTimeout> | null = null
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { searchLocation } = Location()
const isBusy = ref<boolean>(false)
const { createLead } = Lead()
const days : number[] = [1,2,3,4,5,6,7,8,9,10]
const error = ref<string | false>(false)
const submited = ref<boolean>(false)
const trip = ref<any>({
   transfers: 0,
   chauffers: 0,
   items: []
})
const leadForm = ref<LeadForm>({
   service: 'buses_coaches',
   trip: trip.value,
   pickUp: {
      name: '',
      date: '',
      time: '',
      flightNumber: '',
      addresses: null,
      isAddressDropOpen: false
   },
   guest: {
      name: '',
      contactNumber: '',
      email: '',
      message: ''
   },
   people: 0,
   luggage: 0 
})

const errors = ref<any>({
   pickUp: {
      name: false,
      date: false,
      time: false,
      flightNumber: false
   },
   guest: {
      name: false,
      contactNumber: false,
      email: false
   },
   people: false
})

const dateConfig : any = {
   enableTime: false,
   altFormat: 'd-m-Y',
   altInput: true,
   dateFormat: 'd-m-Y'
}

const timeConfig : any = {
   enableTime: true,
   noCalendar: true,
   dateFormat: 'h:i K'
}

const handleEditSearch = () => {
   const search = retrieveUserSearch()
   saveUserTempSearch(search)
   router.push({name: 'Home'})
}

const handleServiceChange = () => {
   
}

const addItems = (type: string, diff: number) => {
   for (let i = 0; i < diff; i++) {
      let item = { type: type, pickUp: { name: '', date: '', time: '' }, dropOff: {name: ''}, days: 1, errors: {pickUpName: false, pickUpDate: false, pickUpTime: false, dropOffName: false} }
      trip.value.items.push(item)
   }
}

const removeItems = (type: string, diff: number) => {
   const excessTransferItems = trip.value.items.filter((item: any) => item.type === type).slice(diff)
   excessTransferItems.forEach((item: any) => {
      const indexToRemove = trip.value.items.indexOf(item)
      trip.value.items.splice(indexToRemove, 1)
   })
}

watch(() => trip.value.transfers, (newValue, oldValue) => {
   const transferItems = trip.value.items.filter((item: any) => item.type === 'transfer')
   const diff = newValue - transferItems.length

   if (diff > 0) {
      addItems('transfer', diff)
   } else if (diff < 0) {
      removeItems('transfer', diff)
   }
})

watch(() => trip.value.chauffers, (newValue, oldValue) => {
   const transferItems = trip.value.items.filter((item: any) => item.type === 'chauffer')
   const diff = newValue - transferItems.length

   if (diff > 0) {
      addItems('chauffer', diff)
   } else if (diff < 0) {
      removeItems('chauffer', diff)
   }
})

const handleAddressSearch = async (item: any, type: 'pick-up' | 'drop-off') => {
   const target = type == 'pick-up' ? item.pickUp : item.dropOff
   const addr = target.name.toLowerCase().trim()

   if (addr.length > 2) {

      if (searchTimeout) {
         clearTimeout(searchTimeout)
      }

      searchTimeout = setTimeout(async () => {

         const response = await searchLocation(addr)
         if (response.status == 200) {

            let adds : GoogleLocationType[] = []
            const predictions = sortPlacePredictions(response.data.data)
            predictions.forEach((item: any) => adds.push({ id: item.id, name: item.name, isAirport: item.isAirport, coordinates: {lat: null, lng: null} }))
            target.addresses = adds
            target.isAddressDropOpen = adds.length ? true : false
         } else {
            error.value = handleApiError(response)
         }
         
      }, SEARCH_DELAY)
   }
}

const handleAddressSelect = (item: any, address: GoogleLocationType, type: 'pick-up' | 'drop-off') => {
   const target = type == 'pick-up' ? item.pickUp : item.dropOff

   target.name = address.name

   if (target.isAddressDropOpen) {
      target.isAddressDropOpen = false
   }

   if (item.isAddressDropOpen) {
      item.isAddressDropOpen = false
   }
}

const validateForm = () => {

   let valid = true

   if (leadForm.value.guest.name == '') {
      valid = false
      errors.value.guest.name = true
   }

   if (leadForm.value.guest.contactNumber == '') {
      valid = false
      errors.value.guest.contactNumber = true
   }

   if (leadForm.value.service != 'plan_trip') {

      if (leadForm.value.pickUp.name == '') {
         valid = false
         errors.value.pickUp.name = true
      }
   
      if (leadForm.value.pickUp.date == '') {
         valid = false
         errors.value.pickUp.date = true
      }

      if (leadForm.value.pickUp.time == '') {
         valid = false
         errors.value.pickUp.time = true
      }
   
      if (leadForm.value.pickUp.flightNumber == '' && leadForm.value.service == 'meet_greet') {
         valid = false
         errors.value.pickUp.flightNumber = true
      }
   }

   if (leadForm.value.guest.email == '') {
      valid = false
      errors.value.guest.email = 'Required'
   } else if (!isValidEmail(leadForm.value.guest.email)) {
      valid = false
      errors.value.guest.email = 'Email is invalid'
   }

   if ((leadForm.value.service == 'meet_greet' || leadForm.value.service == 'buses_coaches') && (leadForm.value.people <= 0)) {
      valid = false
      errors.value.people = true
   }

   if (leadForm.value.service == 'plan_trip') {
      valid = validateTrips(valid)
   }

   return valid
}

const validateTrips = (isValid: boolean) : boolean => {

   let valid = isValid

   trip.value.items.forEach((item: any) => {

      let dropOffName = false
      let pickUpDate = false
      let pickUpTime = false
      let pickUpName = false

      if (item.pickUp.name == '') {
         valid = false
         pickUpName = true
      } 

      if (item.pickUp.date == null || item.pickUp.date == '') {
         valid = false
         pickUpDate = true
      }

      if (item.pickUp.time == null || item.pickUp.time == '') {
         valid = false
         pickUpTime = true
      }

      if (item.type == 'transfer' && item.dropOff.name == '') {
         valid = false
         dropOffName = true
      }

      item.errors = { pickUpName: pickUpName, pickUpDate: pickUpDate, pickUpTime: pickUpTime, dropOffName: dropOffName }
   })

   return valid
}

const handleSubmit = async () => {

   resetErrors()
   const isValid = validateForm()

   if (isValid && !isBusy.value) {

      isBusy.value = true
      const response = await createLead(leadForm.value)
      isBusy.value = false

      if (response.status == 201) {
         submited.value = true
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      }
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}

const resetErrors = () => {
   errors.value = {
      pickUp: { name: false, date: false, time: false },
      guest:  {name: false, contactNumber: false, email: false},
      people: false,
      luggage: false
   }
}

const listener = () => {
   trip.value.items.forEach((item: any) => {
      if (item.type == 'transfer') {
         item.pickUp.isAddressDropOpen = false
         item.dropOff.isAddressDropOpen = false
      } else if (item.type == 'chauffer') {
         item.pickUp.isAddressDropOpen = false
      }
   })

   leadForm.value.pickUp.isAddressDropOpen = false
}

onMounted(() => {
   document.addEventListener('click', listener)
})

onBeforeUnmount(() => {
   document.removeEventListener('click', listener)
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[28] || (_cache[28] = _createStaticVNode("<section id=\"mainbanner\" class=\"bg-center bg-no-repeat bg-cover banner-img-busesncoaches\"><div class=\"flex flex-col mx-auto w-full lg:w-theme-dw py-24 px-11 md:px-0\"><h2 class=\"font-bold text-size-4 md:text-size-6 uppercase text-white mb-4\">Ride in Comfort, Arrive in Style: Your Premier Buses and Coaches Service Awaits!</h2><div class=\"flex flex-col text-white mb-12\"><div class=\"text-head-md-3 md:text-head-lg\">Seamless Travel Solutions for Every Occasion</div><div class=\"text-head-md-3 md:text-head-lg\">Choose Luxury, Choose Convenience with Our Exclusive Buses and Coaches Service</div></div></div></section>", 1)),
    (submited.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_font_awesome_icon, {
                  size: "2xl",
                  icon: "fa-solid fa-check",
                  class: "text-theme-active"
                })
              ]),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "bg-theme-gray-1 w-[100px] h-[100px] rounded-full" }, null, -1))
            ]),
            _cache[12] || (_cache[12] = _createElementVNode("h3", { class: "text-theme-gray-5 text-head-md-2 md:text-head-xl text-center flex flex-col gap-3" }, [
              _createElementVNode("div", null, "Thank You"),
              _createElementVNode("div", null, "Your details have been sent to our team")
            ], -1)),
            _cache[13] || (_cache[13] = _createElementVNode("h5", { class: "text-head-sm text-center text-theme-gray-5" }, "Our representative will contact you shortly", -1))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (__props.service == '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", { class: "flex flex-col md:flex-row items-center ml-3 w-full" }, [
                  _cache[14] || (_cache[14] = _createElementVNode("div", { class: "flex flex-col" }, [
                    _createElementVNode("h3", { class: "text-sm font-medium text-yellow-800" }, "The route you entered is not available at the moment"),
                    _createElementVNode("div", { class: "mt-2 text-sm text-yellow-700 flex w-full items-center" }, [
                      _createElementVNode("p", null, "Please fill out the form below. Our operations team will try their best to help you")
                    ])
                  ], -1)),
                  _createElementVNode("div", {
                    onClick: handleEditSearch,
                    class: "rounded-lg text-sm mr-auto mt-4 md:mt-0 md:ml-auto md:mr-4 whitespace-nowrap font-medium text-yellow-800 hover:text-yellow-900 cursor-pointer p-2 border border-yellow-800"
                  }, " Edit My Search ")
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _cache[17] || (_cache[17] = _createElementVNode("div", { class: "px-4 sm:px-0" }, [
                  _createElementVNode("h2", { class: "text-base font-semibold leading-7 text-gray-900" }, "Buses & Caoches"),
                  _createElementVNode("p", { class: "mt-1 text-sm leading-6 text-gray-600" }, "Choose the No of People and Luggage")
                ], -1)),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _cache[15] || (_cache[15] = _createElementVNode("label", { class: "block text-sm font-medium leading-6 text-gray-900" }, "No of people *", -1)),
                          _createElementVNode("div", _hoisted_15, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((leadForm.value.people) = $event)),
                              type: "number",
                              min: "0",
                              class: _normalizeClass([errors.value.people ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300', "block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"])
                            }, null, 2), [
                              [_vModelText, leadForm.value.people]
                            ])
                          ]),
                          (errors.value.people)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_16, "Required"))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _cache[16] || (_cache[16] = _createElementVNode("label", { class: "block text-sm font-medium leading-6 text-gray-900" }, "Estimated luggage bags", -1)),
                          _createElementVNode("div", _hoisted_18, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((leadForm.value.luggage) = $event)),
                              type: "number",
                              min: "0",
                              class: "block pl-2 w-full text-gray-900 ring-gray-300 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
                            }, null, 512), [
                              [_vModelText, leadForm.value.luggage]
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              (leadForm.value.service != 'plan_trip')
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _cache[22] || (_cache[22] = _createElementVNode("div", { class: "px-4 sm:px-0" }, [
                      _createElementVNode("h2", { class: "text-base font-semibold leading-7 text-gray-900" }, "Pick up"),
                      _createElementVNode("p", { class: "mt-1 text-sm leading-6 text-gray-600" }, "Provide pick up details")
                    ], -1)),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("div", _hoisted_23, [
                            _cache[18] || (_cache[18] = _createElementVNode("label", {
                              for: "first-name",
                              class: "block text-sm font-medium leading-6 text-gray-900"
                            }, "Pick up location *", -1)),
                            _createElementVNode("div", _hoisted_24, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((leadForm.value.pickUp.name) = $event)),
                                onInput: _cache[3] || (_cache[3] = ($event: any) => (handleAddressSearch(leadForm.value, 'pick-up'))),
                                type: "text",
                                class: _normalizeClass([errors.value.pickUp.name ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300', "block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"])
                              }, null, 34), [
                                [_vModelText, leadForm.value.pickUp.name]
                              ]),
                              (leadForm.value.pickUp.isAddressDropOpen && leadForm.value.pickUp.addresses && leadForm.value.pickUp.addresses.length)
                                ? (_openBlock(), _createElementBlock("ul", _hoisted_25, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(leadForm.value.pickUp.addresses, (address, index) => {
                                      return (_openBlock(), _createElementBlock("li", {
                                        onClick: ($event: any) => (handleAddressSelect(leadForm.value, address, 'pick-up')),
                                        key: index,
                                        class: "flex flex-row items-start gap-2 py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer"
                                      }, [
                                        (address.isAirport)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_27, [
                                              _createVNode(_component_font_awesome_icon, {
                                                size: "1x",
                                                icon: "fa-solid fa-plane"
                                              })
                                            ]))
                                          : (_openBlock(), _createElementBlock("span", _hoisted_28, [
                                              _createVNode(_component_font_awesome_icon, {
                                                size: "1x",
                                                icon: "fa-solid fa-location-dot"
                                              })
                                            ])),
                                        _createElementVNode("span", null, _toDisplayString(address.name), 1)
                                      ], 8, _hoisted_26))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            (errors.value.pickUp.name)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_29, "Required"))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("label", _hoisted_31, [
                              _cache[19] || (_cache[19] = _createTextVNode("Flight Number ")),
                              (leadForm.value.service != 'meet_greet')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_32, "(optional)"))
                                : (_openBlock(), _createElementBlock("span", _hoisted_33, " *"))
                            ]),
                            _createElementVNode("div", _hoisted_34, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((leadForm.value.pickUp.flightNumber) = $event)),
                                type: "text",
                                class: "block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
                              }, null, 512), [
                                [_vModelText, leadForm.value.pickUp.flightNumber]
                              ]),
                              (errors.value.pickUp.flightNumber)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_35, "Required"))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_36, [
                            _cache[20] || (_cache[20] = _createElementVNode("label", {
                              for: "last-name",
                              class: "block text-sm font-medium leading-6 text-gray-900"
                            }, "Date *", -1)),
                            _createElementVNode("div", _hoisted_37, [
                              _createVNode(_unref(flatPickr), {
                                modelValue: leadForm.value.pickUp.date,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((leadForm.value.pickUp.date) = $event)),
                                config: dateConfig,
                                class: _normalizeClass([errors.value.pickUp.date ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300', "w-full block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"]),
                                placeholder: "Select pick-up date"
                              }, null, 8, ["modelValue", "class"])
                            ]),
                            (errors.value.pickUp.date)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_38, "Required"))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_39, [
                            _cache[21] || (_cache[21] = _createElementVNode("label", {
                              for: "last-name",
                              class: "block text-sm font-medium leading-6 text-gray-900"
                            }, "Time *", -1)),
                            _createElementVNode("div", _hoisted_40, [
                              _createVNode(_unref(flatPickr), {
                                modelValue: leadForm.value.pickUp.time,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((leadForm.value.pickUp.time) = $event)),
                                config: timeConfig,
                                class: _normalizeClass([errors.value.pickUp.time ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300', "w-full block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"]),
                                placeholder: "Select pick-up time"
                              }, null, 8, ["modelValue", "class"]),
                              (errors.value.pickUp.time)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_41, "Required"))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_42, [
                _cache[27] || (_cache[27] = _createElementVNode("div", { class: "px-4 sm:px-0" }, [
                  _createElementVNode("h2", { class: "text-base font-semibold leading-7 text-gray-900" }, "Guest Details"),
                  _createElementVNode("p", { class: "mt-1 text-sm leading-6 text-gray-600" }, "Provide the guest details")
                ], -1)),
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("div", _hoisted_45, [
                      _createElementVNode("div", _hoisted_46, [
                        _cache[23] || (_cache[23] = _createElementVNode("label", { class: "block text-sm font-medium leading-6 text-gray-900" }, "Full name *", -1)),
                        _createElementVNode("div", _hoisted_47, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((leadForm.value.guest.name) = $event)),
                            type: "text",
                            class: _normalizeClass([errors.value.guest.name ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300', "block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"])
                          }, null, 2), [
                            [_vModelText, leadForm.value.guest.name]
                          ])
                        ]),
                        (errors.value.guest.name)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_48, "Required"))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_49, [
                        _cache[24] || (_cache[24] = _createElementVNode("label", {
                          for: "last-name",
                          class: "block text-sm font-medium leading-6 text-gray-900"
                        }, "Contact Number *", -1)),
                        _createElementVNode("div", _hoisted_50, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((leadForm.value.guest.contactNumber) = $event)),
                            type: "text",
                            class: _normalizeClass([errors.value.guest.contactNumber ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300', "block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"])
                          }, null, 2), [
                            [_vModelText, leadForm.value.guest.contactNumber]
                          ])
                        ]),
                        (errors.value.guest.contactNumber)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_51, "Required"))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_52, [
                        _cache[25] || (_cache[25] = _createElementVNode("label", {
                          for: "email",
                          class: "block text-sm font-medium leading-6 text-gray-900"
                        }, "Email address *", -1)),
                        _createElementVNode("div", _hoisted_53, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((leadForm.value.guest.email) = $event)),
                            type: "email",
                            class: _normalizeClass([errors.value.guest.email ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300', "block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"])
                          }, null, 2), [
                            [_vModelText, leadForm.value.guest.email]
                          ])
                        ]),
                        (errors.value.guest.email)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_54, _toDisplayString(errors.value.guest.email), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_55, [
                        _cache[26] || (_cache[26] = _createElementVNode("label", {
                          for: "country",
                          class: "block text-sm font-medium leading-6 text-gray-900"
                        }, "Message (optional)", -1)),
                        _createElementVNode("div", _hoisted_56, [
                          _withDirectives(_createElementVNode("textarea", {
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((leadForm.value.guest.message) = $event)),
                            rows: "6",
                            class: "block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
                          }, null, 512), [
                            [_vModelText, leadForm.value.guest.message]
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_57, [
            _createElementVNode("button", {
              onClick: handleSubmit,
              type: "button",
              class: _normalizeClass([isBusy.value ? 'opacity-70 cursor-not-allowed' : 'hover:bg-indigo-500', "rounded-md bg-indigo-600 px-12 py-2 text-sm font-semibold text-white shadow-sm outline-none"])
            }, "Submit", 2)
          ]),
          _createVNode(Notification, {
            show: notif.value.show,
            type: notif.value.type,
            message: notif.value.message,
            messageDetails: notif.value.messageDetails,
            onClosed: notifClosed
          }, null, 8, ["show", "type", "message", "messageDetails"])
        ]))
  ], 64))
}
}

})