import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 gap-4 divide-y divide-gray-300 w-full bg-gray-100 p-4 my-12" }
const _hoisted_2 = { class: "col-span-1 flex flex-col gap-4 pt-4" }
const _hoisted_3 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_4 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_5 = { class: "grid grid-cols-2" }
const _hoisted_6 = { class: "col-span-1 font-bold" }
const _hoisted_7 = { class: "grid grid-cols-2" }
const _hoisted_8 = { class: "col-span-1 font-bold" }
const _hoisted_9 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_10 = { class: "grid grid-cols-2" }
const _hoisted_11 = { class: "col-span-1 font-bold" }
const _hoisted_12 = { class: "grid grid-cols-2" }
const _hoisted_13 = { class: "col-span-1 font-bold" }
const _hoisted_14 = {
  key: 0,
  class: "col-span-1 flex flex-col gap-4 pt-4"
}
const _hoisted_15 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_16 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_17 = { class: "grid grid-cols-2" }
const _hoisted_18 = { class: "col-span-1 font-bold" }
const _hoisted_19 = { class: "grid grid-cols-2" }
const _hoisted_20 = { class: "col-span-1 font-bold" }
const _hoisted_21 = { class: "grid grid-cols-2" }
const _hoisted_22 = { class: "col-span-1 font-bold" }
const _hoisted_23 = { class: "grid grid-cols-2" }
const _hoisted_24 = { class: "col-span-1 font-bold" }
const _hoisted_25 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_26 = { class: "grid grid-cols-2" }
const _hoisted_27 = { class: "col-span-1 font-bold" }
const _hoisted_28 = { class: "col-span-1 flex flex-col gap-4 pt-4" }
const _hoisted_29 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_30 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_31 = { class: "grid grid-cols-2" }
const _hoisted_32 = { class: "col-span-1 font-bold" }
const _hoisted_33 = { class: "grid grid-cols-2" }
const _hoisted_34 = { class: "col-span-1 font-bold" }
const _hoisted_35 = {
  key: 0,
  class: "flex flex-col gap-4 col-span-2 md:col-span-1"
}
const _hoisted_36 = { class: "grid grid-cols-2" }
const _hoisted_37 = { class: "col-span-1 font-bold" }
const _hoisted_38 = {
  key: 1,
  class: "flex flex-col gap-4 col-span-2 md:col-span-1"
}
const _hoisted_39 = { class: "grid grid-cols-2" }
const _hoisted_40 = { class: "col-span-1 font-bold" }
const _hoisted_41 = { class: "col-span-1 flex flex-col gap-4 pt-4" }
const _hoisted_42 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_43 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_44 = { class: "grid grid-cols-2" }
const _hoisted_45 = { class: "col-span-1 font-bold" }
const _hoisted_46 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_47 = { class: "grid grid-cols-2" }
const _hoisted_48 = { class: "col-span-1 font-bold" }
const _hoisted_49 = { class: "col-span-1 flex flex-col gap-4 pt-4" }
const _hoisted_50 = { class: "grid grid-cols-2" }
const _hoisted_51 = { class: "flex flex-col gap-4 col-span-2" }
const _hoisted_52 = { class: "list-disc pl-4" }

import { ref } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'Voucher',
  props: ['data'],
  setup(__props) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "col-span-1 flex flex-row items-center" }, [
      _createElementVNode("img", {
        class: "w-[235px]",
        src: "/assets/logo.png",
        alt: ""
      }),
      _createElementVNode("div", { class: "ml-auto text-size-6" }, "Booking Voucher")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Name:", -1)),
            _createElementVNode("div", _hoisted_6, _toDisplayString(__props.data.contact.name), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Email:", -1)),
            _createElementVNode("div", _hoisted_8, _toDisplayString(__props.data.contact.email), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Phone number:", -1)),
            _createElementVNode("div", _hoisted_11, _toDisplayString(__props.data.contact.phone), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Comments:", -1)),
            _createElementVNode("div", _hoisted_13, _toDisplayString(__props.data.contact.comments), 1)
          ])
        ])
      ])
    ]),
    (__props.data.car)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Car:", -1)),
                _createElementVNode("div", _hoisted_18, _toDisplayString(__props.data.car.name), 1)
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Type:", -1)),
                _createElementVNode("div", _hoisted_20, _toDisplayString(__props.data.car.type.name), 1)
              ]),
              _createElementVNode("div", _hoisted_21, [
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Bags:", -1)),
                _createElementVNode("div", _hoisted_22, _toDisplayString(__props.data.car.type.bags), 1)
              ]),
              _createElementVNode("div", _hoisted_23, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Passengers:", -1)),
                _createElementVNode("div", _hoisted_24, _toDisplayString(__props.data.car.type.passengers), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Doors:", -1)),
                _createElementVNode("div", _hoisted_27, _toDisplayString(__props.data.car.type.doors), 1)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Pick Up:", -1)),
            _createElementVNode("div", _hoisted_32, _toDisplayString(__props.data.search.pickUp.location.name), 1)
          ]),
          _createElementVNode("div", _hoisted_33, [
            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Date and time:", -1)),
            _createElementVNode("div", _hoisted_34, _toDisplayString(__props.data.search.pickUp.date) + " - " + _toDisplayString(__props.data.search.pickUp.time), 1)
          ])
        ]),
        (__props.data.search.type == 'hourly')
          ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, [
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Booking hours:", -1)),
                _createElementVNode("div", _hoisted_37, _toDisplayString(__props.data.search.hours), 1)
              ])
            ]))
          : (__props.data.search.type == 'distance')
            ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _cache[13] || (_cache[13] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Drop Off:", -1)),
                  _createElementVNode("div", _hoisted_40, _toDisplayString(__props.data.search.dropOff.location.name), 1)
                ])
              ]))
            : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_41, [
      _createElementVNode("div", _hoisted_42, [
        _createElementVNode("div", _hoisted_43, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "grid grid-cols-2" }, [
            _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Payment method:"),
            _createElementVNode("div", { class: "col-span-1 font-bold" }, "Card")
          ], -1)),
          _createElementVNode("div", _hoisted_44, [
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Payment status:", -1)),
            _createElementVNode("div", _hoisted_45, _toDisplayString(__props.data.payment.status), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_46, [
          _createElementVNode("div", _hoisted_47, [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Total amount:", -1)),
            _createElementVNode("div", _hoisted_48, _toDisplayString(__props.data.totalAmount) + "USD", 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_49, [
      _createElementVNode("div", _hoisted_50, [
        _createElementVNode("div", _hoisted_51, [
          _cache[19] || (_cache[19] = _createElementVNode("h2", { class: "font-bold" }, "Cancellation and ammendement policy", -1)),
          _createElementVNode("ul", _hoisted_52, [
            _cache[18] || (_cache[18] = _createElementVNode("li", null, "No show charges will be 100%", -1)),
            _createElementVNode("li", null, [
              _cache[17] || (_cache[17] = _createTextVNode("If you cancel your booking before 12/11/2023 your cancellation charges will be 0. However after that date the cancellation charges will apply as per our ")),
              _createElementVNode("span", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({name: 'TermsConditions'})))
              }, "Terms and Conditions")
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})