import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full mb-5 relative" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = {
  key: 0,
  class: "ml-auto mr-1"
}
const _hoisted_4 = {
  key: 0,
  class: "z-10 !mt-0 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "flex flex-row items-start py-2 justify-center border-b border-solid text-theme-gray-4"
}
const _hoisted_7 = {
  key: 0,
  class: "w-full mb-5 relative"
}
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = {
  key: 0,
  class: "ml-auto mr-1"
}
const _hoisted_10 = {
  key: 0,
  class: "z-10 !mt-0 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 0,
  class: "flex flex-row items-start py-2 justify-center border-b border-solid text-theme-gray-4"
}
const _hoisted_13 = { class: "w-full mb-5" }
const _hoisted_14 = { class: "flex" }

import { onMounted, ref } from 'vue'
import { Locality } from '@/types/index'
import Admin from '@/composables/admin/index'
import { handleApiError, resetErrors } from '@/utils/common'
import Location from '@/composables/Location'
import LoaderIcon from '@/components/LoaderIcon.vue'

const SEARCH_DELAY = 400

export default /*@__PURE__*/_defineComponent({
  __name: 'SaveCarLocation',
  props: ['mode', 'carId', 'locationType'],
  emits: ['success', 'error'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const isLoading = ref<boolean>(false)
const isLoadingTwo = ref<boolean>(false)
const isDropOpen = ref<boolean>(false)
const isDropTwoOpen = ref<boolean>(false)
const locationName = ref<string>('')
const locationTwoName = ref<string>('')
const locations = ref<Locality[]>([])
const locationsTwo = ref<Locality[]>([])
const selectedLocation = ref<Locality | null>(null)
const selectedLocationTwo = ref<Locality | null>(null)
const rate = ref<number | null>(null)
let searchTimeout: ReturnType<typeof setTimeout> | null = null
const { searchLocality } = Location()
const { createCarChaufferLocation, createCarTransferLocation } = Admin()

onMounted(() => {
   mounted() 
})

const mounted = () => {
   
}

const errors = ref({
   location: false,
   locationTwo: false,
   rate: false
})

const handleSelect = (index: number, value: Locality) => {

   if (index ==1) {

      locationName.value = value.name
      selectedLocation.value = value
      isDropOpen.value = false
   } else if (index == 2) {

      locationTwoName.value = value.name
      selectedLocationTwo.value = value
      isDropTwoOpen.value = false
   }
}

const validateForm = () : boolean => {

   let response = true

   if (selectedLocation.value === null) {
      errors.value.location = true
      response = false
   }

   if (selectedLocationTwo.value === null && props.locationType == 'transfer') {
      errors.value.locationTwo = true
      response = false
   }

   return response
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid) {

      isLoading.value = true

      let response
      if (props.locationType == 'chauffer') {

         response = await createCarChaufferLocation(props.carId, selectedLocation.value as Locality, rate.value as number)
      } else if (props.locationType == 'transfer') {

         response = await createCarTransferLocation(props.carId, selectedLocation.value as Locality, selectedLocationTwo.value as Locality, rate.value as number)
      }
      isLoading.value = false

      if (response.status == 201) {
         emit('success')
      } else {
         const error = handleApiError(response)
         emit('error', error)
      }
   }
}

const handleSearch = async (index: number, mode: string) => {

   let addr = ''

   if (index == 1) {
      addr = locationName.value.toLowerCase().trim()
   } else if (index == 2) {
      addr = locationTwoName.value.toLowerCase().trim()
   }

   if (addr.length > 2) {

      if (searchTimeout) {
         clearTimeout(searchTimeout);
      }

      searchTimeout = setTimeout(async () => {

         if (index == 1) {
            isLoading.value = true
         } else if (index == 2) {
            isLoadingTwo.value = true
         }

         const response = await searchLocality(addr, mode)
         
         if (index == 1) {
            isLoading.value = false
         } else if (index == 2) {
            isLoadingTwo.value = false
         }

         if (response.status == 200) {
            let adds : Locality[] = []
            response.data.data.forEach((item: any) => {
               adds.push({
                  id: item.id,
                  name: item.name,
                  type: item.type,
                  bounds: item.bounds,
               })
            })

            if (index == 1) {
               locations.value = adds
               isDropOpen.value = true
            } else if (index == 2) {
               locationsTwo.value = adds
               isDropTwoOpen.value = true
            }
         } else {
            
         }
         
      }, SEARCH_DELAY)

   }
}

const resetDropdowns = () => {
   isDropOpen.value = false
   isDropTwoOpen.value = false
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    onClick: resetDropdowns,
    class: "flex flex-col"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", {
          for: "location",
          class: _normalizeClass([!errors.value.location ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
        }, _toDisplayString(__props.locationType == 'chauffer' ? 'Pick up (city/town)' : 'Pick up (downtown/airport)'), 3),
        _createElementVNode("div", {
          class: _normalizeClass([!errors.value.location ? 'border-gray-300' : 'border-theme-error', "flex flex-row items-center h-10 border"])
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((locationName).value = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (handleSearch(1, __props.locationType == 'chauffer' ? 'city_town' : 'city_town_airport'))),
            type: "text",
            class: "mt-1 pb-1 outline-none rounded-sm w-full block pl-2 text-gray-500 text-sm"
          }, null, 544), [
            [_vModelText, locationName.value]
          ]),
          (isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(LoaderIcon)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      (isDropOpen.value)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locations.value, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                onClick: ($event: any) => (handleSelect(1, item)),
                key: index,
                class: "flex flex-row items-start gap-2 py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer"
              }, [
                _createElementVNode("span", null, _toDisplayString(item.name), 1)
              ], 8, _hoisted_5))
            }), 128)),
            (locations.value.length == 0)
              ? (_openBlock(), _createElementBlock("li", _hoisted_6, "No results"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (__props.locationType == 'transfer')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", {
              for: "location",
              class: _normalizeClass([!errors.value.locationTwo ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
            }, _toDisplayString('Drop off (downtown/airport)'), 2),
            _createElementVNode("div", {
              class: _normalizeClass([!errors.value.locationTwo ? 'border-gray-300' : 'border-theme-error', "flex flex-row items-center h-10 border"])
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((locationTwoName).value = $event)),
                onInput: _cache[3] || (_cache[3] = ($event: any) => (handleSearch(2, 'city_town_airport'))),
                type: "text",
                class: "mt-1 pb-1 outline-none rounded-sm w-full block pl-2 text-gray-500 text-sm"
              }, null, 544), [
                [_vModelText, locationTwoName.value]
              ]),
              (isLoadingTwo.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(LoaderIcon)
                  ]))
                : _createCommentVNode("", true)
            ], 2)
          ]),
          (isDropTwoOpen.value)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locationsTwo.value, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    onClick: ($event: any) => (handleSelect(2, item)),
                    key: index,
                    class: "flex flex-row items-start gap-2 py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer"
                  }, [
                    _createElementVNode("span", null, _toDisplayString(item.name), 1)
                  ], 8, _hoisted_11))
                }), 128)),
                (locationsTwo.value.length == 0)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_12, "No results"))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("label", {
        for: "rate",
        class: _normalizeClass([!errors.value.rate ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
      }, _toDisplayString(__props.locationType == 'chauffer' ? 'Rate per hour' : 'Rate'), 3),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((rate).value = $event)),
        type: "number",
        class: _normalizeClass([!errors.value.rate ? 'border-gray-300' : 'border-theme-error', "mt-1 h-10 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm"])
      }, null, 2), [
        [_vModelText, rate.value]
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("button", {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (handleSubmit())),
        type: "button",
        class: "ml-auto mt-20 bg-theme-active py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white outline-none"
      }, " Save ")
    ])
  ]))
}
}

})