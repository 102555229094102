import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref, watch, onMounted } from 'vue'
import Dashboard from '@/components/account/dashboard.vue'
import { isAdminLoggedIn, isLoggedIn } from '@/utils/common'
import Agent from '@/composables/admin/agent'
import { Nav } from '@/types/admin';
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountDashboard',
  props: ['agent'],
  setup(__props) {

const props = __props
const auth = isLoggedIn()
const { agentProfile } = Agent()
const breadcrump = ref<Nav[] | null>(null)
const router = useRouter()

onMounted(async () => {

   await prepareBreadcrump()
})

const prepareBreadcrump = async () : Promise<void> => {

   let nav : Nav[] = []

   if (auth.user.type == 'agent') {
      
      nav.push({ title: 'Agent Dashboard', link: null })
      const response = await agentProfile()
      if (response.status == 200) {
         
         const company = response.data.data.company.charAt(0).toUpperCase() + response.data.data.company.slice(1)
         nav.push({ title: company, link: router.resolve({ name: 'AccountDashboard' }).href })
         if (props.agent != '') {
            
            for (const [key, value] of Object.entries(response.data.data.sub_agents)) {
               if (value == props.agent) {
                  nav.push({ title: key, link: null })
               }
            }
         }
      }
   } else if (auth.user.type == 'corporate') {
      nav.push({ title: 'Corporate Dashboard', link: null })
   }

   breadcrump.value = nav
}

watch(() => props.agent, (newVallue, oldValue) => {
   prepareBreadcrump()
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Dashboard, {
    user: _unref(auth).user,
    agent: __props.agent,
    breadcrump: breadcrump.value
  }, null, 8, ["user", "agent", "breadcrump"]))
}
}

})