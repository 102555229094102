import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "mt-8 flow-root" }
const _hoisted_3 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_4 = { class: "flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_5 = { class: "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" }
const _hoisted_6 = { class: "flex gap-2 w-full justify-end" }
const _hoisted_7 = { class: "p-input-icon-left" }
const _hoisted_8 = { class: "flex items-center gap-3" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]

import { ref, onMounted, watch } from 'vue'
import SlideOver from '@/widgets/SlideOver.vue'
import Notification from '@/widgets/Notification.vue'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import { NotificationType } from '@/types/index'
import { Page } from '@/types/page'
import Admin from '@/composables/admin'
import { handleApiError } from '@/utils/common'
import SavePage from '@/components/admin/SavePage.vue'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const isLoading = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const filters = ref()
const loading = ref<boolean>(false)
const dt = ref()
const openConfirmModal = ref<boolean>(false)
const openSaveDialog = ref<boolean>(false)
const pages = ref<Page[] | null>(null)
const deleteItem = ref<number>(0)
const editPage = ref<Page | null>(null)
const router = useRouter()
const { allPages, removePage } = Admin()

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
   }
}

const handleConfirmClose = () => openConfirmModal.value = false

const notifClosed = () : void => {
   notif.value.show = false
}

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = (event: any) => {
   dt.value.exportCSV()
}

const askingConfirmed = async () => {
   if (!isLoading.value && deleteItem.value) {
      isLoading.value = true
      const response = await removePage(deleteItem.value)

      if (response.status == 204) {
         await fetchPages()
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
         setTimeout(() => { notifClosed() }, 4000)
      }

      isLoading.value = false
      openConfirmModal.value = false
   }  
}

const handleAddPage = () => {
   editPage.value = null
   openSaveDialog.value = true
}

const handleEditPage = (data: any) => {
   editPage.value = data
   openSaveDialog.value = true
}

const handleViewPage = (data: any) => {
   const routeUrl = router.resolve({name: 'CustomPage', params: { title: data.title_slug } }).href
   window.open(routeUrl, '_blank')
}

const handleSavePageSuccess = async () => {
   openSaveDialog.value = false
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'The page has been added successfully'}
   setTimeout(() => { notifClosed() }, 4000)
   
   await fetchPages()
}

const handleSavePageError = (error: string) => {
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
   setTimeout(() => { notifClosed() }, 4000)
}

const handleDeletePage = (id: number) => {
   deleteItem.value = id
   openConfirmModal.value = true
}

const fetchPages = async () => {

   isLoading.value = true
   const response = await allPages()
   isLoading.value = false

   if (response.status == 200) {
      pages.value = response.data
   } else {
      const error = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      setTimeout(() => { notifClosed() }, 4000)
   }
}

onMounted(() => {

   fetchPages()
})


return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", { class: "sm:flex sm:items-center" }, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "sm:flex-auto" }, [
          _createElementVNode("h1", { class: "text-base font-semibold leading-6 text-gray-900" }, "Pages"),
          _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "A list of all the landing pages created in our database")
        ], -1)),
        _createElementVNode("div", { class: "mt-4 sm:ml-16 sm:mt-0 sm:flex-none" }, [
          _createElementVNode("button", {
            onClick: handleAddPage,
            type: "button",
            class: "block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }, " Add Page ")
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_DataTable, {
                exportFilename: "pages",
                ref_key: "dt",
                ref: dt,
                class: "text-sm",
                filters: filters.value,
                "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => ((filters).value = $event)),
                value: pages.value,
                paginator: "",
                showGridlines: "",
                rows: 10,
                dataKey: "id",
                filterDisplay: "menu",
                loading: loading.value,
                globalFilterFields: ['title']
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, [
                      _createVNode(_component_InputText, {
                        modelValue: filters.value['global'].value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value['global'].value) = $event)),
                        placeholder: "Keyword Search"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createVNode(_component_Button, {
                      type: "button",
                      icon: "pi pi-filter-slash",
                      label: "Clear",
                      outlined: "",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (clearFilter()))
                    }),
                    _createVNode(_component_Button, {
                      icon: "pi pi-external-link",
                      label: "Export",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (exportCSV($event)))
                    })
                  ])
                ]),
                empty: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" No pages added yet ")
                ])),
                loading: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" Loading bookings data. Please wait. ")
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "title",
                    header: "Title"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.title), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "banner_heading",
                    header: "Heading"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.banner_heading), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "banner_sub_heading",
                    header: "Sub Heading"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.banner_sub_heading), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "created_at",
                    header: "Created At"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.created_at), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    header: "Actions",
                    exportable: false,
                    class: "w-[22rem]"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("button", {
                          onClick: ($event: any) => (handleViewPage(data)),
                          type: "button",
                          class: "rounded rounded-bg bg-theme-button2-bg text-theme-button2-txt text-center w-[100px] py-2 uppercase text-xs"
                        }, " View ", 8, _hoisted_9),
                        _createElementVNode("button", {
                          onClick: ($event: any) => (handleEditPage(data)),
                          type: "button",
                          class: "rounded rounded-bg bg-theme-button4-bg text-theme-button4-txt text-center w-[100px] py-2 uppercase text-xs"
                        }, " Edit ", 8, _hoisted_10),
                        _createElementVNode("button", {
                          onClick: ($event: any) => (handleDeletePage(data.id)),
                          type: "button",
                          class: "rounded rounded-bg bg-theme-button5-bg text-theme-button5-txt text-center w-[100px] py-2 uppercase text-xs"
                        }, " Delete ", 8, _hoisted_11)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["filters", "value", "loading"])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(SlideOver, {
      show: openSaveDialog.value,
      onClosed: _cache[4] || (_cache[4] = ($event: any) => (openSaveDialog.value = false))
    }, {
      content: _withCtx(() => [
        _createVNode(SavePage, {
          page: editPage.value,
          onSuccess: handleSavePageSuccess,
          onError: handleSavePageError
        }, null, 8, ["page"])
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"]),
    _createVNode(ConfirmationModal, {
      show: openConfirmModal.value,
      onClosed: handleConfirmClose
    }, {
      buttons: _withCtx(() => [
        _createElementVNode("button", {
          onClick: askingConfirmed,
          type: "button",
          class: _normalizeClass([isLoading.value ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer', "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto"])
        }, "Yes, delete", 2),
        _createElementVNode("button", {
          onClick: _cache[5] || (_cache[5] = ($event: any) => (openConfirmModal.value = false)),
          type: "button",
          class: "mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto"
        }, "Cancel")
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})