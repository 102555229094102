
export const getGoogleApiKey = () : string => {
   return process.env.VUE_APP_GOOGLE_API_KEY || 'AIzaSyDeErkUhQusB0l1DXCyjI_WBjHP6M5dN2U'
}

export const getBackendBaseUrl = () : string => {

   const url = window.location.href

   if (url.includes('localhost')) {
      return process.env.VUE_APP_BACKEND_BASE_URL || 'http://127.0.0.1:8000/'
   } 

   return process.env.VUE_APP_BACKEND_BASE_URL || 'https://vehitraworld.com/backend/public/'
}

export const getSessionPrefix = () : string => {
   return process.env.VUE_APP_SESSION_PREFIX || 'vehitra'
}

export const getHoursPerDay = () : number => {
   return 10
}

export const generateTimeArray = () : string[] =>  {
   const times: string[] = []
   let currentHour: number = 6
   let currentMinute: number = 0

   while (currentHour < 22 || (currentHour === 22 && currentMinute === 0)) {
      const formattedTime: string = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`
      times.push(formattedTime)
      currentMinute += 30

      if (currentMinute >= 60) {
         currentMinute = 0
         currentHour += 1
      }
   }
   
   return times
}

export const formattedDateTime = (input: number) => {
   const date = new Date(input * 1000)
   
   const day = ('0' + date.getDate()).slice(-2)
   const month = ('0' + (date.getMonth() + 1)).slice(-2)
   const year = date.getFullYear()
   const hours = ('0' + (date.getHours() % 12 || 12)).slice(-2)
   const minutes = ('0' + date.getMinutes()).slice(-2)
   const seconds = ('0' + date.getSeconds()).slice(-2)
   const period = date.getHours() < 12 ? 'AM' : 'PM'

   return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds} ${period}`
}

export const validateTimeValue = (evt:any) : void | boolean => {
   evt = (evt) ? evt : window.event
   var charCode = (evt.which) ? evt.which : evt.keyCode
   if (((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 58) || evt.target.value.length >= 5) {
      evt.preventDefault()
   } else {
      return true
   }
}

export const sortPlacePredictions = (predictions: any) : any => {
   return predictions.sort((a:any, b:any) => {
      const isAirportA = a.isAirport;
      const isAirportB = b.isAirport;

      if (isAirportA && !isAirportB) {
        return -1;
      } else if (isAirportB && !isAirportA) {
        return 1;
      } else {
        return 0;
      }
   })
}

export const getIconForType = (types: string[]) : string => {
   if (types.includes('airport')) {
      return 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/airport-71.png'
   } else {
      return 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png'
   }
}

export const resetErrors = (errors: any) : void => {
   
   for (const key in errors) {
      if (Object.hasOwnProperty.call(errors, key)) {
        const subError = errors[key];
        if (typeof subError === 'object') {
          for (const subKey in subError) {
            if (Object.hasOwnProperty.call(subError, subKey)) {
              subError[subKey] = false;
            }
          }
        } else {
          errors[key] = false;
        }
      }
    }
}

export const getDiffInHours = (date: string) => {
   const [datePart, timePart] = date.split(' ')
   const [day, month, year] = datePart.split('-')
   const [hours, minutes] = timePart.split(':')
   const monthIndex = parseInt(month, 10) - 1
   const parsedDate = new Date(parseInt(year), monthIndex, parseInt(day), parseInt(hours, 10), parseInt(minutes, 10))
   const targetTimestamp = parsedDate.getTime()
   const currentTimestamp = new Date().getTime()
   const differenceInSeconds = Math.floor((targetTimestamp - currentTimestamp) / 1000)

   return Math.round(differenceInSeconds / 3600)
}

export function isImageFile(file : File) : boolean {
   
   const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
   return allowedTypes.includes(file.type)
}

export const handleApiError = (error : any) : string => {
   
   let message = ''
   if(error.response){
      if (error.response.data) {
         if (Array.isArray(error.response.data)) {
            message = error.response.data[0]
         } else if (error.response.data.error) {   
            message = error.response.data.error
         } else if (typeof error.response.data === 'object') {
            const objectKeys = Object.keys(error.response.data)
            if (objectKeys.length > 0) {
               const firstKey = objectKeys[0]
               message = `${firstKey}: ${error.response.data[firstKey]}`
            } else {
               message = 'unknown error'
            }
         } else {
            message = 'unknown error'
         }
      } else {

         message = error.response.statusText
      }

      return `status code: ${error.response.status} - message: ${message}`
   }

   return error.message
}

export function isLoggedIn() : any {
   const prefix = getSessionPrefix()
   const auth = localStorage.getItem(`${prefix}_auth`)
   return auth ? JSON.parse(auth) : null
}

export function isAdminLoggedIn() : any {
   const prefix = getSessionPrefix()
   const auth = localStorage.getItem(`${prefix}_admin_auth`)
   return auth ? JSON.parse(auth) : null
}

export function getAdminToken() : string | null {
   const prefix = getSessionPrefix()
   const auth = localStorage.getItem(`${prefix}_admin_auth`)
   return auth ? JSON.parse(auth).token : null
}

export function getUserToken() : string | null {
   const prefix = getSessionPrefix()
   const auth = localStorage.getItem(`${prefix}_auth`)
   return auth ? JSON.parse(auth).token : null
}

export function truncateText(input: string, limit: number) : string {
   if (input.length > limit) {
      return input.substring(0, limit) + '...'
   }
   return input
}

export const getFirstName = (fullName: string) : string => {
   const [firstName] = fullName.split(" ")
   return firstName
}

export const saveAuthCredentials = (data : any) : string => {
   const prefix = getSessionPrefix()
   localStorage.setItem(`${prefix}_auth`, JSON.stringify({
      'token' : data.token, 
      'user' : {
         'id' : data.user.id, 
         'firstName' : data.user.first_name, 
         'lastName' : data.user.last_name,
         'name' : data.user.name, 
         'email' : data.user.email_address, 
         'photo' : data.user.photo,
         'commission' : data.user.commission,
         'type' : data.user.account_type.type.type,
         'sub_type': data.user.account_type.type['sub-type'] ? data.user.account_type.type['sub-type'] : null,
         'has_setup_password': data.user.has_setup_password
      }
   }))

   return data.user.account_type.type.type
}

export const checkUserPasswordSetup = () : number | null => {
   const prefix = getSessionPrefix()
   const auth = JSON.parse(localStorage.getItem(`${prefix}_auth`) as string)

   return auth && auth.user != null ? auth.user.has_setup_password : null
}

export const updateAuthUser = (user : any) : void => {
   const prefix = getSessionPrefix()
   const auth = JSON.parse(localStorage.getItem(`${prefix}_auth`) as string)
   
   localStorage.setItem(`${prefix}_auth`, JSON.stringify({
      'token' : auth.token, 
      'user' : {
         'id' : auth.user.id, 
         'firstName' : user.first_name, 
         'lastName' : user.last_name,
         'name' : user.name, 
         'email' : auth.user.email_address,
         'photo' : user.photo,
         'type' : auth.user.type,
         'commission' : user.commission,
         'sub_type': auth.user.sub_type
      }
   }))
}

export const saveAdminAuthCredentials = (data : any) : void => {
   const prefix = getSessionPrefix()
   localStorage.setItem(`${prefix}_admin_auth`, JSON.stringify({
      'token' : data.token, 
      'admin' : {
         'id' : data.user.id, 
         'name' : data.user.name, 
         'email' : data.user.email,
         'type': 'admin'
      }
   }))
}

export const isMakePaymentVisible = (date : any) : boolean => {
   var inputDate = date
   var inputDateObj = new Date(inputDate.split("-").reverse().join("-"))
   var today = new Date()
   var timeDifference = inputDateObj.getTime() - today.getTime()
   var daysDifference = timeDifference / (1000 * 3600 * 24)
   
   if (daysDifference > 2) {
      return true
   } 

   return false
}

export const getAuthUserAccountType = () : string => {
   const prefix = getSessionPrefix()
   const auth = localStorage.getItem(`${prefix}_auth`)

   return auth ? JSON.parse(auth).user.type : null
}

export const removeAuthCredentials = (type: string) : void => {
   const prefix = getSessionPrefix()
   if (type == 'admin') {
      localStorage.removeItem(`${prefix}_admin_auth`)
   } else {
      localStorage.removeItem(`${prefix}_auth`)
   }
}

export function isValidEmail (email: string) : boolean {
   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
   return emailRegex.test(email)
}

export function isValidCardNumber (input: string) : boolean {
   const regex = /^[0-9]{16}$/
   return regex.test(input)
}

export function getBookingNumber() : string | null {
   const prefix = getSessionPrefix()
   const session = localStorage.getItem(`${prefix}_booking_number`)
   return session ? session : null
}

export function saveBookingNumber(number: string) : void {
   const prefix = getSessionPrefix()
   localStorage.setItem(`${prefix}_booking_number`, number)
}

export function getPaymentSession() : string | null {
   const prefix = getSessionPrefix()
   const session = localStorage.getItem(`${prefix}_booking_session`)
   return session ? session : null
}

export function savePaymentSession(session: string) : void {
   const prefix = getSessionPrefix()
   localStorage.setItem(`${prefix}_booking_session`, session)
}

export function getPaymentCode() : string | null {
   const prefix = getSessionPrefix()
   const code = localStorage.getItem(`${prefix}_payment_code`)
   return code ? code : null
}

export function savePaymentCode(code: string) : void {
   const prefix = getSessionPrefix()
   localStorage.setItem(`${prefix}_payment_code`, code)
}

export function getPaymentTransaction() : string | null {
   const prefix = getSessionPrefix()
   const session = localStorage.getItem(`${prefix}_booking_transaction`)
   return session ? session : null
}

export function savePaymentTransaction(transaction: string) : void {
   const prefix = getSessionPrefix()
   localStorage.setItem(`${prefix}_booking_transaction`, transaction)
}

export function getDealId() : string | null {
   const prefix = getSessionPrefix()
   const session = localStorage.getItem(`${prefix}_crm_deal`)
   return session ? session : null
}

export function saveDealId(dealId: string) : void {
   const prefix = getSessionPrefix()
   localStorage.setItem(`${prefix}_crm_deal`, dealId)
}

export const savePaymentWallet = (value: string) : void => {
   const prefix = getSessionPrefix()
   localStorage.setItem(`${prefix}_payment_wallet`, value)
}

export const getPaymentWallet = () : string | null => {
   const prefix = getSessionPrefix()
   const wallet = localStorage.getItem(`${prefix}_payment_wallet`)
   return wallet ? wallet : null
}

export function disableScroll() : void {
   document.body.style.overflow = 'hidden'
}

export function isVideoFile(file: File): boolean {
   const allowedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg']
   return allowedVideoTypes.includes(file.type)
}

export function enableScroll() : void {
   document.body.style.overflow = 'auto'
}

export function clearSessionData() : void {
   localStorage.removeItem('vehitra_booking_transaction')
   localStorage.removeItem('vehitra_booking_session')
   localStorage.removeItem('vehitra_user_search')
   localStorage.removeItem('vehitra_booking_number')
   localStorage.removeItem('vehitra_selected_car')
   localStorage.removeItem('vehitra_payment_code')
   localStorage.removeItem('vehitra_crm_deal')
   localStorage.removeItem('vehitra_payment_wallet')
}

export const logout = (router: any) => {
   const auth = isLoggedIn()
   const adminAuth = isAdminLoggedIn()
   const authUser = auth ? auth.user : adminAuth.admin

   removeAuthCredentials(authUser.type)
   if (authUser.type == 'admin') {
      window.location.href = '/admin/login'
   } else {
      window.location.href = '/login'
   }
}

export const loadScript = (src: any, callback: any) => {
   var script = document.createElement('script')
   script.type = 'text/javascript'
   script.src = src
   script.onload = callback
   document.head.appendChild(script)
}