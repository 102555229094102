import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full mb-5" }
const _hoisted_2 = { class: "flex" }

import { ref, onMounted } from 'vue'
import Admin from '@/composables/admin/index'
import { resetErrors, handleApiError } from '@/utils/common'


export default /*@__PURE__*/_defineComponent({
  __name: 'SaveAgent',
  props: ['agent'],
  emits: ['success', 'error'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const { updateAgent } = Admin()
const isLoading = ref<boolean>(false)
const form = ref({
   loyaltyThreshold: 0
})
const errors = ref({
   loyaltyThreshold: false
})

onMounted(() => {
   mounted() 
})

const mounted = async () => {

   if (props.agent) {
      form.value = {
         loyaltyThreshold: props.agent.loyalty.threshold
      }
   }
}

const validateForm = () : boolean => {

   let response = true
   
   if (form.value.loyaltyThreshold == 0) {
      errors.value.loyaltyThreshold = true
      response = false
   }

   return response 
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid) {
      
      isLoading.value = true
      const response = await updateAgent(props.agent.id, form.value)
      isLoading.value = false
      if (response.status == 200) {
         emit('success')
      } else {
         const error = handleApiError(response)
         emit('error', error)
      }
   }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        for: "name",
        class: _normalizeClass([!errors.value.loyaltyThreshold ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
      }, "Loyalty Threshold", 2),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.loyaltyThreshold) = $event)),
        type: "text",
        class: _normalizeClass([!errors.value.loyaltyThreshold ? 'border-gray-300 text-gray-500' : 'border-theme-error text-theme-error', "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-sm"])
      }, null, 2), [
        [_vModelText, form.value.loyaltyThreshold]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (handleSubmit())),
        type: "button",
        class: "ml-auto mt-20 bg-indigo-600 py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 outline-none"
      }, " Save ")
    ])
  ], 64))
}
}

})