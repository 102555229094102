import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-10 px-4 sm:px-6 lg:px-24" }
const _hoisted_2 = { class: "mt-8 flow-root" }
const _hoisted_3 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_4 = { class: "inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_5 = { class: "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" }

import { ref, onMounted } from 'vue'
import BookingsTable from '@/components/account/BookingsTable.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Bookings',
  props: ['subUserId'],
  setup(__props) {

const props = __props 

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "sm:flex sm:items-center" }, [
      _createElementVNode("div", { class: "sm:flex-auto" }, [
        _createElementVNode("h1", { class: "text-base font-semibold leading-6 text-gray-900" }, "Bookings"),
        _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "A list of all the bookings created under your account")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(BookingsTable, { subUserId: __props.subUserId }, null, 8, ["subUserId"])
          ])
        ])
      ])
    ])
  ]))
}
}

})