import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomePage from '@/pages/Home.vue'
import BookingWizard from '@/pages/BookingWizard.vue'
import BookingLead from '@/pages/BookingLead.vue'
import BookingTest from '@/pages/BookingTest.vue'
import Login from '@/pages/auth/Login.vue'
import Signup from '@/pages/auth/Signup.vue'
import ForgetPassword from '@/pages/auth/ForgetPassword.vue'
import CustomPage from '@/pages/CustomPage.vue'
import ResetPassword from '@/pages/auth/ResetPassword.vue'
import ContactUs from '@/pages/ContactUs.vue'
import TermsConditions from '@/pages/TermsConditions.vue'
import AboutUs from '@/pages/AboutUs.vue'
import PrivacyPolicy from '@/pages/PrivacyPolicy.vue'
import OurServices from '@/pages/OurServices.vue'
import AccountDashboard from '@/pages/account/AccountDashboard.vue'
import AdminLogin from '@/pages/admin/AdminLogin.vue'
import AdminDashboard from '@/pages/admin/AdminDashboard.vue'
import AdminCars from '@/pages/admin/cars/index.vue'
import AdminAgents from '@/pages/admin/agents/index.vue'
import AdminCustomPage from '@/pages/admin/pages/index.vue'
import AdminBookings from '@/pages/admin/bookings/index.vue'
import AdminLocations from '@/pages/admin/locations/index.vue'
import AdminCarLocations from '@/pages/admin/cars/locations.vue'
import AdminSettings from '@/pages/admin/Settings.vue'
import BookingVoucher from '@/pages/BookingVoucher.vue'
import SubAgents from '@/pages/agents/SubAgents.vue'
import SubUsers from '@/pages/corporate/SubUsers.vue'
import AgentPasswordSetup from '@/pages/auth/AgentPasswordSetup.vue'
import AgentActivateAccount from '@/pages/auth/AgentActivateAccount.vue'
import CorporatePasswordSetup from '@/pages/auth/CorporatePasswordSetup.vue'
import UserBookings from '@/pages/users/Bookings.vue'
import UserBookingVoucher from '@/pages/users/BookingVoucher.vue'
import UserBookingInvoice from '@/pages/users/UserBookingInvoice.vue'
import UserSettings from '@/pages/users/Settings.vue'

import MeetGreet from '@/pages/MeetGreet.vue' //Meet & Greet
import Planmytrip from '@/pages/Planmytrip.vue' // Plan my trip
import BusesCoaches from '@/pages/BusesCoaches.vue' // Buses and Coaches


import { isLoggedIn, getAuthUserAccountType, isAdminLoggedIn } from '@/utils/common'

const routes: Array<RouteRecordRaw> = [
   {
      path: '/',
      name: 'Home',
      component: HomePage,
      meta: { 
         title: "Home", 
         layout: 'web'
      },
      props: true
   },
   {
      path: '/booking/:step',
      name: 'BookingWizard',
      component: BookingWizard,
      meta: { 
         title: "Make a booking", 
         layout: 'web'
      },
      props: true
   },
   {
      path: '/pages/:title',
      name: 'CustomPage',
      component: CustomPage,
      meta: { 
         title: "Page", 
         layout: 'web'
      },
      props: true
   },
   {
      path: '/booking/lead',
      name: 'BookingLead',
      component: BookingLead,
      meta: { 
         title: "Booking lead", 
         layout: 'web'
      },
      props: true
   },
   {
      path: '/booking/meetngreet',
      name: 'MeetGreet',
      component: MeetGreet,
      meta: { 
         title: "Meet & Greet", 
         layout: 'web'
      },
      props: true
   },{
      path: '/booking/planmytrip',
      name: 'Planmytrip',
      component: Planmytrip,
      meta: { 
         title: "Multiple Requests", 
         layout: 'web'
      },
      props: true
   },{
      path: '/booking/busesncoaches',
      name: 'BusesCoaches',
      component: BusesCoaches,
      meta: { 
         title: "Buses & Caoches", 
         layout: 'web'
      },
      props: true
   },
   {
      path: '/booking/checkout',
      name: 'BookingTest',
      component: BookingTest,
      meta: { 
         title: "Make a booking", 
         layout: 'web'
      }
   },
   {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { 
         title: "Log in", 
         layout: 'web'
      }
   },
   {
      path: '/signup/:type',
      name: 'Signup',
      component: Signup,
      meta: { 
         title: "Sign up", 
         layout: 'web'
      },
      props: true
   },
   {
      path: '/forget-password',
      name: 'ForgetPassword',
      component: ForgetPassword,
      meta: { 
         title: "Forget password", 
         layout: 'web'
      }
   },
   {
      path: '/reset-password/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: { 
         title: "Reset password", 
         layout: 'web'
      }, 
      props: true
   },
   {
      path: '/contact-us',
      name: 'ContactUs',
      component: ContactUs,
      meta: { 
         title: "Contact us", 
         layout: 'web'
      }
   },
   {
      path: '/terms-and-conditions',
      name: 'TermsConditions',
      component: TermsConditions,
      meta: { 
         title: "Terms and conditions", 
         layout: 'web'
      }
   },
   {
      path: '/about-us',
      name: 'AboutUs',
      component: AboutUs,
      meta: { 
         title: "About us", 
         layout: 'web'
      }
   },
   {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
      meta: { 
         title: "Privacy policy", 
         layout: 'web'
      }
   },
   {
      path: '/our-services',
      name: 'OurServices',
      component: OurServices,
      meta: { 
         title: "Our services", 
         layout: 'web'
      }
   },
   {
      path: '/account/dashboard/:agent?',
      name: 'AccountDashboard',
      component: AccountDashboard,
      meta: { 
         title: "My dashboard", 
         layout: 'account'
      },
      props: true
   },
   {
      path: '/booking-voucher',
      name: 'BookingVoucher',
      component: BookingVoucher,
      meta: { 
         title: "Download Voucher", 
         layout: 'web'
      }
   },
   {
      path: '/admin/login',
      name: 'AdminLogin',
      component: AdminLogin,
      meta: {
         layout: 'admin',
         isProtected: false
      }
   },
   {
      path: '/admin/dashboard',
      name: 'AdminDashboard',
      component: AdminDashboard,
      meta: {
         layout: 'admin',
         isProtected: true
      }
   },
   {
      path: '/admin/cars',
      name: 'AdminCars',
      component: AdminCars,
      meta: {
         layout: 'admin',
         isProtected: true
      }
   },
   {
      path: '/admin/agents',
      name: 'AdminAgents',
      component: AdminAgents,
      meta: {
         layout: 'admin',
         isProtected: true
      }
   },
   {
      path: '/admin/pages',
      name: 'AdminCustomPage',
      component: AdminCustomPage,
      meta: {
         layout: 'admin',
         isProtected: true
      }
   },
   {
      path: '/admin/bookings',
      name: 'AdminBookings',
      component: AdminBookings,
      meta: {
         layout: 'admin',
         isProtected: true
      }
   },
   {
      path: '/admin/locations',
      name: 'AdminLocations',
      component: AdminLocations,
      meta: {
         layout: 'admin',
         isProtected: true
      }
   },
   {
      path: '/admin/cars/:id/locations',
      name: 'AdminCarLocation',
      component: AdminCarLocations,
      meta: {
         layout: 'admin',
         isProtected: true
      },
      props: true
   },
   {
      path: '/admin/settings',
      name: 'AdminSettings',
      component: AdminSettings,
      meta: {
         layout: 'admin',
         isProtected: true
      },
      props: true
   },
   {
      path: '/agent/sub-agents',
      name: 'SubAgents',
      component: SubAgents,
      meta: {
         layout: 'account',
         isProtected: true
      }
   },
   {
      path: '/corporate/sub-users',
      name: 'SubUsers',
      component: SubUsers,
      meta: {
         layout: 'account',
         isProtected: true
      }
   },
   {
      path: '/agent/password-setup/:token',
      name: 'AgentPasswordSetup',
      component: AgentPasswordSetup,
      meta: { 
         title: "Setup your account password",
         layout: 'web'
      }, 
      props: true
   },
   {
      path: '/agent/activate-account/:token',
      name: 'AgentActivateAccount',
      component: AgentActivateAccount,
      meta: { 
         title: "Activate your account",
         layout: 'web'
      }, 
      props: true
   },
   {
      path: '/corporate/password-setup/:token',
      name: 'CorporatePasswordSetup',
      component: CorporatePasswordSetup,
      meta: { 
         title: "Setup your account password",
         layout: 'web'
      }, 
      props: true
   },
   {
      path: '/:userType/bookings/:subUserId?',
      name: 'UserBookings',
      component: UserBookings,
      meta: {
         layout: 'account',
         isProtected: true
      },
      props: true
   },
   {
      path: '/bookings/:number/voucher',
      name: 'UserBookingVoucher',
      component: UserBookingVoucher,
      meta: {
         layout: 'account',
         isProtected: true
      },
      props: true
   },
   {
      path: '/bookings/:number/invoice',
      name: 'UserBookingInvoice',
      component: UserBookingInvoice,
      meta: {
         layout: 'account',
         isProtected: true
      },
      props: true
   },
   {
      path: '/:userType/settings',
      name: 'UserSettings',
      component: UserSettings,
      meta: {
         layout: 'account',
         isProtected: true
      }
   },
]

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes,
   scrollBehavior(to, from, savedPosition) {
      return { top: 0 };
   },
})

router.beforeEach( (to, from, next) => {

   const auth = isLoggedIn()
   const adminAuth = isAdminLoggedIn()

   if (to.meta.title) {
      document.title = to.meta.title as string
   }

   if (auth && (to.name == 'Login' || to.name == 'ForgetPassword')) {

      next(false)
      router.push({name: 'AccountDashboard'})
   } else if (auth && to.meta.layout == 'account') {
      const accountType = getAuthUserAccountType()

      if (accountType != 'agent' && accountType != 'corporate') {
         next(false)
         router.push({name: 'Home'})
      }
   } else if (!auth && to.meta.layout == 'account') {

      if (adminAuth && to.name == 'UserBookingVoucher') {

      } else {
         next(false)
         router.push({name: 'Login'})
      }
   }

   if (!adminAuth && to.meta.layout === 'admin' && to.meta.isProtected === true) {
      next(false)
      router.push({name: 'AdminLogin'})
   } else if (adminAuth && to.meta.layout === 'admin' && (to.name == 'AdminLogin')) {
      next(false)
      router.push({name: 'AdminDashboard'})
   }
   
   next(true)
})

export default router
