<template>

   <div v-if="!invalidType">
      <TopBanner layout="full-width" currentPage="Signup" heading="Signup" bannerClass="banner-auth" />
      <div v-if="success == ''" class="py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
         <div class="flex flex-col w-full lg:w-theme-auth mx-auto gap-6">
            <div class="flex flex-col gap-2">
               <label for="signup-fullname" :class="form.fullName.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small">Full name *</label>
               <div :class="form.fullName.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
                  <input v-model="form.fullName.value" type="text" id="signup-fullname" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
               </div>
               <span v-if="form.fullName.error" class="text-ef-error">{{ form.fullName.errorMessage }}</span>
            </div>
            <div v-if="type != 'web'" class="flex flex-col gap-2">
               <label for="signup-companyname" :class="form.companyName.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small">Company name *</label>
               <div :class="form.companyName.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
                  <input v-model="form.companyName.value" type="text" id="signup-companyname" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
               </div>
               <span v-if="form.companyName.error" class="text-ef-error">{{ form.companyName.errorMessage }}</span>
            </div>
            <div class="flex flex-col gap-2">
               <label for="signup-email" :class="form.email.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small">Email *</label>
               <div :class="form.email.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
                  <input v-model="form.email.value" type="email" id="signup-email" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
               </div>
               <span v-if="form.email.error" class="text-ef-error">{{ form.email.errorMessage }}</span>
            </div>
            <div v-if="type != 'supplier'" class="flex flex-col gap-2">
               <label for="signup-password" :class="form.password.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small">Password *</label>
               <div :class="form.password.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
                  <input v-model="form.password.value" :type="passwordFieldtype" id="signup-password" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
                  <div @click="togglePasswordFieldType">
                     <font-awesome-icon icon="fa-solid fa-eye" class="text-theme-gray-4 cursor-pointer ml-auto mr-1" />
                  </div>
               </div>
               <span v-if="form.password.error" class="text-ef-error">{{ form.password.errorMessage }}</span>
            </div>
            <div v-if="type !== 'supplier'" class="flex flex-col gap-2">
               <label for="signup-password-confirmaiton" :class="form.passwordConfirmation.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small">Confirm Password *</label>
               <div :class="form.passwordConfirmation.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
                  <input v-model="form.passwordConfirmation.value" :type="passwordFieldtype" id="signup-password-confirmation" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
                  <div @click="togglePasswordFieldType">
                     <font-awesome-icon icon="fa-solid fa-eye" class="text-theme-gray-4 cursor-pointer ml-auto mr-1" />
                  </div>
               </div>
               <span v-if="form.passwordConfirmation.error" class="text-ef-error">{{ form.passwordConfirmation.errorMessage }}</span>
            </div>
            <div v-if="type != 'web'" class="flex flex-col gap-2">
               <label for="signup-phonenumber" :class="form.phone.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small">Phone Number *</label>
               <div class="flex flex-row items-center border border-theme-gray-7">
                  <div class="relative w-full">
                     <CountrySearchablePhoneField @phoneNumber="handlePhoneInput" class="h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6 px-2" />
                  </div>
               </div>
               <span v-if="form.phone.error" class="text-ef-error">{{ form.phone.errorMessage }}</span>
            </div>
            <div v-if="type == 'supplier'" class="flex flex-col gap-2">
               <label for="signup-message" class="uppercase text-small text-theme-gray-4">Message *</label>
               <div class="flex flex-row items-center border border-theme-gray-7">
                  <textarea v-model="form.message.value" :type="passwordFieldtype" id="signup-message" class="w-full outline-none text-theme-gray-5 p-2 h-[200px] md:h-[300px] font-bold text-size-4 md:text-size-6"></textarea>
               </div>
            </div>
            <div class="flex flex-col justify-end gap-2">
               <div class="ml-auto google-recaptcha my-4"></div>
               <p v-if="recaptchaError" class="text-ef-error ml-auto">{{ recaptchaError }}</p>
            </div>
            <div class="flex flex-col md:flex-row md:items-center gap-4 mb-2">
               <div v-if="type != 'supplier'" @click="$router.push({ name: 'Login' })" class="order-2 md:order-1 gap-4 uppercase rounded-full w-full md:w-auto px-4 md:px-8 py-2 md:py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer transition-all ease-in-out duration-500">
                  I already have an account
               </div>
               <div @click="handleSignup" :class="isLoading ? 'cursor-not-allowed opacity-40' : 'cursor-pointer hover:text-theme-active hover:bg-white'" class="order-1 md:order-2 gap-4 uppercase rounded-full w-full md:w-auto px-4 md:px-8 py-2 md:py-4 border-2 border-theme-active text-white bg-theme-active text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto transition-all ease-in-out duration-500">
                  {{ type == 'supplier' ? 'Submit' : 'Signup' }}
               </div>
            </div>
         </div>
      </div>
      <div v-else>
         <div class="flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
            <div class="flex flex-col md:flex-row items-center bg-green-100 px-12 py-10 gap-6">
               <div class="relative bg-green-300 rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                  <font-awesome-icon size="lg" icon="fa-solid fa-check-double" class="text-green-800" />
               </div>
               <div class="flex flex-col">
                  <h4 class="font-bold text-size-6 text-green-600">Successful</h4>
                  <p class="font-light text-size-6 text-green-600 font-semibold">{{ success }}</p>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div v-else>
     <div class="flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
         <div class="flex flex-col md:flex-row items-center bg-theme-gray-7 px-12 py-10 gap-6">
            <div class="relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
               <span class="font-icon theme-icon warning-icon text-theme-error absolute -top-[23px] -left-[13px] text-[47px]"></span>
            </div>
            <div class="flex flex-col gap-3">
               <h4 class="font-bold text-size-6 text-theme-error">404 Error</h4>
               <p class="font-light text-size-6 text-theme-error font-semibold">The url you are visiting is invalid and cannot be used to display anything</p>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import CountrySearchablePhoneField from '@/components/CountrySearchablePhoneField.vue'
import { handleApiError, isValidEmail, loadScript } from '@/utils/common'
import Auth from '@/composables/Auth'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
// @ts-ignore
import $ from 'jquery'
import router from '@/router'

const props = defineProps(['type'])
const { registerSupplier, registerAgent, registerUser }  = Auth()
const isLoading = ref<boolean>(false)
const invalidType = ref<boolean>(false)
const recaptchaError = ref<any>(null)
const success = ref<string>('')
const passwordFieldtype = ref<'password' | 'text'>('password')
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const form = ref<any>({
   fullName: { value: '', error: false, errorMessage: '' },
   companyName: { value: '', error: false, errorMessage: '' },
   email: { value: '', error: false, errorMessage: '' },
   phone: { value: '', error: false, errorMessage: '' },
   password: { value: '', error: false, errorMessage: '' },
   passwordConfirmation: { value: '', error: false, errorMessage: '' },
   message: { value: '', error: false, errorMessage: '' },
})

const togglePasswordFieldType = () => {
   passwordFieldtype.value = passwordFieldtype.value == 'password' ? 'text' : 'password'
}

const handlePhoneInput = (input: any) => {

   if (input === false) {

      form.value.phone.value = ''
      form.value.phone.error = true
      form.value.phone.errorMessage = 'Phone is required'
   } else {

      form.value.phone.value = input
      form.value.phone.error = false
   }
}

const validateForm = () : boolean => {
   let error : boolean = false
   const recaptcha = $('textarea[name="g-recaptcha-response"]')

   if (!recaptcha.length || recaptcha.val() == '' || !recaptcha.val()) {
      error = true
      recaptchaError.value = 'Please validate the recaptcha'
   } else {
      recaptchaError.value = null
   }

   if (form.value.fullName.value == '') {
      form.value.fullName.error = true
      form.value.fullName.errorMessage = 'Full name is required'
      error = true
   }

   if (form.value.email.value == '') {
      form.value.email.error = true
      form.value.email.errorMessage = 'Email is required'
      error = true
   } else if (!isValidEmail(form.value.email.value)) {
      form.value.email.error = true
      form.value.email.errorMessage = 'Email is invalid'
      error = true
   }

   if (props.type !== 'web') {
      
      if (form.value.phone.value === '') {
         form.value.phone.error = true
         form.value.phone.errorMessage = 'Phone number is required'
      }else if (form.value.phone.value.length < 11) {
         form.value.phone.error = true
         form.value.phone.errorMessage = 'Phone number is invalid'
      }

      if (form.value.companyName.value == '') {
         form.value.companyName.error = true
         form.value.companyName.errorMessage = 'Company name is required'
         error = true
      }
   }

   if (props.type !== 'supplier') {

      if (form.value.password.value == '') {
         form.value.password.error = true
         form.value.password.errorMessage = 'Password is required'
         error = true
      } else if (form.value.password.value.length < 6) {
         form.value.password.error = true
         form.value.password.errorMessage = 'Password must be at least 6 characters long'
         error = true
      }else if (form.value.passwordConfirmation.value == '') {
         form.value.passwordConfirmation.error = true
         form.value.passwordConfirmation.errorMessage = 'Password confirmation is required'
         error = true
      } else if (form.value.passwordConfirmation.value != form.value.password.value) {
         form.value.password.error = true
         form.value.passwordConfirmation.error = true
         form.value.password.errorMessage = 'Password does not match with password confirmation'
         error = true
      }
   }

   if (form.value.fullName.value == '') {
      form.value.fullName.error = true
      form.value.fullName.errorMessage = 'Full name is required'
      error = true
   }

   return error
}

const resetFormErrors = () :void => {
   Object.keys(form.value).forEach((key: string) => {
      form.value[key].error = false
      form.value[key].errorMessage = ''
   })

   success.value = ''
}

const resetForm = () :void => {
   Object.keys(form.value).forEach((key: string) => {
      form.value[key].value = ''
   })
}

const handleSignup = async () => {

   resetFormErrors()
   const error = validateForm()
   if (!error && !isLoading.value) {
      isLoading.value = true
      const recaptcha = $('textarea[name="g-recaptcha-response"]').val()

      if (props.type == 'supplier') {
         const response = await registerSupplier(form.value.fullName.value, form.value.companyName.value, form.value.email.value, form.value.phone.value, form.value.message.value, recaptcha)
         
         if (response.status == 201) {
            const messageDetails = props.type == 'supplier' ? 'We have notified our support team. You will hear from us soon' : 'You have created an account with us'
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: messageDetails}
            setTimeout(() => { notifClosed() }, 4000)
            resetForm()
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
         }

         isLoading.value = false
      } else {
         const response = props.type == 'agent' 
            ? await registerAgent(form.value.fullName.value, form.value.companyName.value, form.value.email.value, form.value.phone.value, form.value.password.value, form.value.passwordConfirmation.value, recaptcha)
            : await registerUser(form.value.fullName.value, form.value.email.value, form.value.phone.value, form.value.companyName.value, form.value.password.value, form.value.passwordConfirmation.value, props.type, recaptcha)
         if (response.status == 201) {
            if (props.type == 'agent') {
               success.value = 'You have created an account with us. Please check your email to activate your account'
               setTimeout(() => { notifClosed() }, 4000)
               resetForm()
            } else {
               router.push({name: 'Login'})
            }
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
         }

         isLoading.value = false
      }
   }
}

const notifClosed = () => {
   notif.value.show = false
}

onMounted(() => {

   var recaptchaScriptUrl = 'https://www.google.com/recaptcha/api.js'
   $('.google-recaptcha').html('<div class="g-recaptcha" data-sitekey="6LdgLVspAAAAAH4Y109CAIfw26hm_onZFQsycv1v"></div>')
   loadScript(recaptchaScriptUrl, function () {})

   if (props.type != 'agent' && props.type != 'supplier' && props.type != 'web') {
      invalidType.value = true
      notif.value.show = true
   }
})

</script>