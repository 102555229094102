import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-[800px] relative" }
const _hoisted_2 = {
  key: 1,
  class: "absolute top-0 left-0 bg-no-repeat bg-cover bg-center w-full h-full"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex items-start flex-1 w-full mx-auto md:w-theme-landing-d mx-auto relative" }
const _hoisted_5 = { class: "flex flex-col gap-6 my-auto z-10" }
const _hoisted_6 = {
  key: 0,
  class: "text-white text-size-8 animate__animated slide-down"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "px-5 opacity-90 max-w-[400px] py-8 bg-white absolute right-0 top-1/2 transform -translate-y-[50%] rounded-md"
}
const _hoisted_10 = { class: "w-full" }
const _hoisted_11 = { class: "flex flex-col w-full mx-auto gap-6" }
const _hoisted_12 = { class: "flex flex-col gap-2" }
const _hoisted_13 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_14 = { class: "flex flex-row items-center gap-3" }
const _hoisted_15 = { class: "flex-1 flex flex-col gap-2" }
const _hoisted_16 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_17 = { class: "flex-1 flex flex-col gap-2" }
const _hoisted_18 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_19 = { class: "flex flex-col gap-2" }
const _hoisted_20 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_21 = { class: "relative w-full" }
const _hoisted_22 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_23 = { class: "flex flex-col gap-2" }
const _hoisted_24 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_25 = { class: "flex flex-col md:items-end gap-4" }
const _hoisted_26 = { class: "flex flex-col justify-end gap-2" }
const _hoisted_27 = {
  key: 0,
  class: "text-ef-error ml-auto"
}
const _hoisted_28 = {
  key: 0,
  class: "w-full bg-theme-gray-7 py-20"
}
const _hoisted_29 = { class: "flex flex-col md:w-theme-landing-d mx-auto gap-8" }
const _hoisted_30 = { class: "flex flex-col" }
const _hoisted_31 = { class: "font-extra-bold leading-[50px] text-head-xl mt-4 mb-8 tracking-wider" }
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = { class: "bg-white flex w-full py-20 md:w-theme-landing-d mx-auto gap-20" }
const _hoisted_34 = { class: "flex flex-1 flex-col gap-8" }
const _hoisted_35 = { class: "flex flex-col" }
const _hoisted_36 = { class: "flex gap-8 py-10 border-b border-gray-100 mb-10" }
const _hoisted_37 = { class: "flex flex-col my-auto gap-3" }
const _hoisted_38 = { class: "flex items-center gap-3" }
const _hoisted_39 = { class: "flex items-center" }
const _hoisted_40 = { class: "flex items-center gap-3" }
const _hoisted_41 = { class: "flex items-center" }
const _hoisted_42 = { class: "flex items-center gap-3" }
const _hoisted_43 = { class: "flex items-center" }

import CountrySearchablePhoneField from '@/components/CountrySearchablePhoneField.vue'
import 'animate.css'
import { handleApiError, loadScript } from '@/utils/common'
import Admin from '@/composables/admin'
import { onMounted, ref } from 'vue'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
import { Page } from '@/types/page'
// @ts-ignore
import $ from 'jquery'


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomPage',
  props: ['title'],
  setup(__props) {

const props = __props
const recaptchaError = ref<any>(null)
const { getPageDetails } = Admin()
const isLoading = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const page = ref<Page>()
const form = ref<any>({
   fullName: { value: '', error: false, errorMessage: '' },
   companyName: { value: '', error: false, errorMessage: '' },
   email: { value: '', error: false, errorMessage: '' },
   phone: { value: '', error: false, errorMessage: '' },
   password: { value: '', error: false, errorMessage: '' },
   message: { value: '', error: false, errorMessage: '' },
})
const passwordFieldtype = ref<'password' | 'text'>('password')

const togglePasswordFieldType = () => {
   passwordFieldtype.value = passwordFieldtype.value == 'password' ? 'text' : 'password'
}

const handlePhoneInput = (input: any) => {

   if (input === false) {

      form.value.phone.value = ''
      form.value.phone.error = true
      form.value.phone.errorMessage = 'Phone is required'
   } else {

      form.value.phone.value = input
      form.value.phone.error = false
   }
}

const handleButtonClick = () => {
   window.location.href = page.value?.bannerButtonUrl as string
}

const fetchPageDetails = async () => {
   const response = await getPageDetails(props.title)
   if (response.status == 200) {
      page.value = {
         title: response.data.page.title,
         bannerHeading: response.data.page.banner_heading,
         bannerSubHeading: response.data.page.banner_sub_heading,
         bannerInfoText: response.data.page.banner_text,
         bannerButtonText: response.data.page.banner_button_text,
         bannerButtonUrl: response.data.page.banner_button_url,
         sectionTitle: response.data.page.section_title,
         sectionInfoText: response.data.page.section_info_text,
         mediaType: response.data.page.media_type,
         mediaSource: response.data.page.media_source,
         titleSlug: response.data.page.title_slug,
         showSupplierForm: response.data.page.show_supplier_form,
      }
   } else {
      const errorMessage = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: errorMessage}
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}

onMounted(async () => {

   var recaptchaScriptUrl = 'https://www.google.com/recaptcha/api.js'
   $('.google-recaptcha').html('<div class="g-recaptcha" data-sitekey="6LdgLVspAAAAAH4Y109CAIfw26hm_onZFQsycv1v"></div>')
   loadScript(recaptchaScriptUrl, function () {})

   await fetchPageDetails()

   $('.slide-up').addClass('animate__slideInUp')
   $('.slide-down').addClass('animate__slideInDown')
})

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (page.value && page.value.mediaType == 'image')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "absolute top-0 left-0 bg-no-repeat bg-cover bg-center w-full h-full",
            style: _normalizeStyle({ backgroundImage: `url(${page.value?.mediaSource})` })
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("img", {
              class: "w-full h-full",
              src: "/assets/img/hero_overlay_3.png",
              alt: ""
            }, null, -1)
          ]), 4))
        : (page.value && page.value.mediaType == 'video')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("video", {
                muted: "",
                autoplay: "",
                loop: "",
                class: "w-full h-full",
                src: page.value?.mediaSource
              }, null, 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (page.value?.bannerSubHeading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(page.value?.bannerSubHeading), 1))
            : _createCommentVNode("", true),
          (page.value?.bannerHeading)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "text-head-bigg leading-[70px] font-extra-bold text-white animate__animated slide-down",
                innerHTML: page.value?.bannerHeading.replace(/\n/g, '<br>')
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true),
          (page.value?.bannerInfoText)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "flex flex-col text-white opacity-90 text-size-7 gap-2 animate__animated slide-up",
                innerHTML: page.value?.bannerInfoText.replace(/\n/g, '<br>')
              }, null, 8, _hoisted_8))
            : _createCommentVNode("", true),
          (page.value?.bannerButtonText && page.value?.bannerButtonText != '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                onClick: handleButtonClick,
                class: "uppercase slide-up animate__animated bg-theme-active text-white w-[350px] text-size-8 tracking-wider cursor-pointer font-bold text-center py-4"
              }, _toDisplayString(page.value?.bannerButtonText), 1))
            : _createCommentVNode("", true)
        ]),
        (page.value?.showSupplierForm)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("label", {
                      for: "signup-fullname",
                      class: _normalizeClass([form.value.fullName.error ? 'text-ef-text' : 'text-theme-gray-4', "uppercase text-small font-bold text-black"])
                    }, "Full name *", 2),
                    _createElementVNode("div", {
                      class: _normalizeClass([form.value.fullName.error ? 'border-ef-outline' : 'border-theme-gray-7', "flex flex-row items-center border"])
                    }, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.fullName.value) = $event)),
                        type: "text",
                        id: "signup-fullname",
                        class: "w-full outline-none text-theme-gray-5 p-1 pl-2 h-[30px] md:h-[40px] font-bold text-xs md:text-sm"
                      }, null, 512), [
                        [_vModelText, form.value.fullName.value]
                      ])
                    ], 2),
                    (form.value.fullName.error)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(form.value.fullName.errorMessage), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("label", {
                        for: "signup-companyname",
                        class: _normalizeClass([form.value.companyName.error ? 'text-ef-text' : 'text-theme-gray-4', "uppercase text-small font-bold text-black"])
                      }, "Company name *", 2),
                      _createElementVNode("div", {
                        class: _normalizeClass([form.value.companyName.error ? 'border-ef-outline' : 'border-theme-gray-7', "flex flex-row items-center border"])
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.companyName.value) = $event)),
                          type: "text",
                          id: "signup-companyname",
                          class: "w-full outline-none text-theme-gray-5 p-1 pl-2 h-[30px] md:h-[40px] font-bold text-xs md:text-sm"
                        }, null, 512), [
                          [_vModelText, form.value.companyName.value]
                        ])
                      ], 2),
                      (form.value.companyName.error)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(form.value.companyName.errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("label", {
                        for: "signup-email",
                        class: _normalizeClass([form.value.email.error ? 'text-ef-text' : 'text-theme-gray-4', "uppercase text-small font-bold text-black"])
                      }, "Email *", 2),
                      _createElementVNode("div", {
                        class: _normalizeClass([form.value.email.error ? 'border-ef-outline' : 'border-theme-gray-7', "flex flex-row items-center border"])
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.email.value) = $event)),
                          type: "email",
                          id: "signup-email",
                          class: "w-full outline-none text-theme-gray-5 p-1 pl-2 h-[30px] md:h-[40px] font-bold text-xs md:text-sm"
                        }, null, 512), [
                          [_vModelText, form.value.email.value]
                        ])
                      ], 2),
                      (form.value.email.error)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(form.value.email.errorMessage), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("label", {
                      for: "signup-phonenumber",
                      class: _normalizeClass([form.value.phone.error ? 'text-ef-text' : 'text-theme-gray-4', "uppercase text-small font-bold text-black"])
                    }, "Phone Number *", 2),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(CountrySearchablePhoneField, {
                          sleek: true,
                          phoneNumber: "handlePhoneInput",
                          class: "h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6 px-2"
                        })
                      ])
                    ]),
                    (form.value.phone.error)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(form.value.phone.errorMessage), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", {
                      for: "signup-message",
                      class: "uppercase text-small text-theme-gray-4 font-bold text-black"
                    }, "Message *", -1)),
                    _createElementVNode("div", _hoisted_24, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.message.value) = $event)),
                        type: "text",
                        id: "signup-message",
                        class: "w-full outline-none text-theme-gray-5 p-1 pl-2 h-[30px] md:h-[40px] font-bold text-xs md:text-sm"
                      }, null, 512), [
                        [_vModelText, form.value.message.value]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mt-6 ml-auto google-recaptcha" }, null, -1)),
                      (recaptchaError.value)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_27, _toDisplayString(recaptchaError.value), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass([isLoading.value ? 'cursor-not-allowed opacity-40' : 'cursor-pointer hover:text-theme-active hover:bg-white', "order-1 md:order-2 gap-4 uppercase w-full md:w-auto px-4 py-2 border-2 border-theme-active text-white bg-theme-active text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto transition-all ease-in-out duration-500"])
                    }, " Submit ", 2)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (page.value?.sectionTitle || page.value?.sectionInfoText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, _toDisplayString(page.value?.sectionTitle), 1),
              _createElementVNode("div", {
                class: "text-gray-500",
                innerHTML: page.value?.sectionInfoText.replace(/\n/g, '<br>')
              }, null, 8, _hoisted_32)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_33, [
      _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"flex flex-1 relative\" data-v-4ab68f5b><div class=\"absolute -top-3 left-12\" data-v-4ab68f5b><div class=\"box-animate-one h-1/2\" data-v-4ab68f5b><img src=\"/assets/img/about_3_2.jpg\" alt=\"\" data-v-4ab68f5b></div><div class=\"box-animate-two bg-white p-2 h-1/2\" data-v-4ab68f5b><img src=\"/assets/img/about_3_3.jpg\" alt=\"\" data-v-4ab68f5b></div></div><div class=\"ml-auto\" data-v-4ab68f5b><img src=\"/assets/img/about_3_1.jpg\" alt=\"\" data-v-4ab68f5b></div></div>", 1)),
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("div", _hoisted_35, [
          _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"flex items-center\" data-v-4ab68f5b><img src=\"/assets/img/menu-1-svgrepo-com.svg\" alt=\"\" data-v-4ab68f5b><div class=\"uppercase text-theme-active font-medium text-size-6\" data-v-4ab68f5b>More About Us</div></div><div class=\"font-extra-bold leading-[50px] text-head-xl mt-4 mb-8 tracking-wider\" data-v-4ab68f5b> For Over 3 Decades, We Have Been Providing The<br data-v-4ab68f5b>Best Transport Services. </div><p class=\"text-gray-500\" data-v-4ab68f5b> We address our offer to all who want to travel in comfortable conditions. We have top-class vehicles, so our customers can be assured of comfort and safety. </p>", 3)),
          _createElementVNode("div", _hoisted_36, [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex flex-col items-center justify-center text-center gap-2 max-w-[120px] py-5 border border-gray-100 shadow-custom-symetric" }, [
              _createElementVNode("div", { class: "text-theme-active font-extra-bold text-head-lg" }, "30+"),
              _createElementVNode("div", { class: "text-gray-500 leading-[25px]" }, "Years Experience")
            ], -1)),
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa-solid fa-circle",
                    class: "text-[7px] text-theme-active"
                  })
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("div", null, "Professionally Trained Chauffeurs", -1))
              ]),
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("div", _hoisted_41, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa-solid fa-circle",
                    class: "text-[7px] text-theme-active"
                  })
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("div", null, "24/7 Full-Service Transportation", -1))
              ]),
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("div", _hoisted_43, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa-solid fa-circle",
                    class: "text-[7px] text-theme-active"
                  })
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("div", null, "Global network of Transportation Partners", -1))
              ])
            ])
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "uppercase bg-theme-active text-white w-fit px-8 text-size-8 tracking-wider cursor-pointer font-bold text-center py-4" }, " Learn More ", -1))
        ])
      ])
    ]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"])
  ], 64))
}
}

})