import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "sm:flex sm:items-center" }
const _hoisted_3 = { class: "mt-4 sm:ml-16 sm:mt-0 sm:flex-none" }
const _hoisted_4 = { class: "mt-8 flow-root" }
const _hoisted_5 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_6 = { class: "flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_7 = { class: "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" }
const _hoisted_8 = { class: "flex gap-2 w-full justify-end" }
const _hoisted_9 = { class: "p-input-icon-left" }
const _hoisted_10 = { class: "rounded rounded-bg text-center min-w-[85px] py-2 uppercase text-xs bg-theme-button4-bg text-theme-button4-txt" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "flex items-center gap-3" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]

import { ref, onMounted } from 'vue'
import { Car, CarType, NotificationType, Supplier } from '@/types/index'
import Admin from '@/composables/admin/index'
import { handleApiError, logout } from '@/utils/common'
import SlideOver from '@/widgets/SlideOver.vue'
import SaveCar from '@/components/admin/SaveCar.vue'
import SaveCarLocation from '@/components/admin/SaveCarLocation.vue'
import Notification from '@/widgets/Notification.vue'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import router from '@/router'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const { allCarTypes, allSuppliers, createCar, removeCar } = Admin()
const cars = ref<Car[] | null>(null)
const { allCars } = Admin()
const isLoading = ref<boolean>(false)
const openSaveDialog = ref<boolean>(false)
const openLocationDialog = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const filters = ref()
const loading = ref<boolean>(false)
const dt = ref()
const carTypes = ref<CarType[] | null>(null)
const suppliers = ref<Supplier[] | null>(null)
const carsTypeOptions = ref<string[] | null | undefined>(null)
const supplierOptions = ref<string[] | null | undefined>(null)
const openConfirmModal = ref<boolean>(false)
const deleteItem = ref<number>(0)
const editCar = ref<CarType | null>(null)

onMounted(async () => {

   await fetchCars()  

   const response = await allCarTypes()
   if (response.status == 200) {
      carTypes.value = response.data
      carsTypeOptions.value = carTypes.value?.map((carType: any) => carType.name as string)
   }

   const responsee = await allSuppliers()
   if (responsee.status == 200) {
      suppliers.value = responsee.data
      supplierOptions.value = suppliers.value?.map((supplier: any) => supplier.name as string)
   }
})

const fetchCars = async () => {
   isLoading.value = true
   const response = await allCars()
   isLoading.value = false
   if (response.status == 200) {
      
      cars.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const handleEditCar = (data: CarType) => {

   editCar.value = data
   openSaveDialog.value = true
}

const handleAddCar = () => {
   editCar.value = null
   openSaveDialog.value = true
}

const handleAddCarLocation = () => {
   openLocationDialog.value = true
}

const handleSaveCarSuccess = async () => {
   openSaveDialog.value = false
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Your car has been added successfully'}
   setTimeout(() => { notifClosed() }, 4000)
   
   await fetchCars()
}

const handleSaveCarError = (error: string) => {
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
   setTimeout(() => { notifClosed() }, 4000)
}

const notifClosed = () : void => {
   notif.value.show = false
}

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "type.name": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "supplier.name": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
   }
}

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = (event: any) => {
   dt.value.exportCSV()
}

const handleConfirmClose = () => openConfirmModal.value = false

const handleDeleteCar = (id: number) => {

   deleteItem.value = id
   openConfirmModal.value = true
}

const askingConfirmed = async () => {
   if (!isLoading.value && deleteItem.value) {
      isLoading.value = true
      const response = await removeCar(deleteItem.value)

      if (response.status == 204) {
         await fetchCars()
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
         setTimeout(() => { notifClosed() }, 4000)
      }

      isLoading.value = false
      openConfirmModal.value = false
   }  
}


return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "sm:flex-auto" }, [
          _createElementVNode("h1", { class: "text-base font-semibold leading-6 text-gray-900" }, "Cars"),
          _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "A list of all the cars created in our database")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (handleAddCar())),
            type: "button",
            class: "block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }, " Add Car ")
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_DataTable, {
                exportFilename: "cars",
                ref_key: "dt",
                ref: dt,
                class: "text-sm",
                filters: filters.value,
                "onUpdate:filters": _cache[4] || (_cache[4] = ($event: any) => ((filters).value = $event)),
                value: cars.value,
                paginator: "",
                showGridlines: "",
                rows: 10,
                dataKey: "id",
                filterDisplay: "menu",
                loading: loading.value,
                globalFilterFields: ['name', 'type.name', 'supplier.name']
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, [
                      _createVNode(_component_InputText, {
                        modelValue: filters.value['global'].value,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filters.value['global'].value) = $event)),
                        placeholder: "Keyword Search"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createVNode(_component_Button, {
                      type: "button",
                      icon: "pi pi-filter-slash",
                      label: "Clear",
                      outlined: "",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (clearFilter()))
                    }),
                    _createVNode(_component_Button, {
                      icon: "pi pi-external-link",
                      label: "Export",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (exportCSV($event)))
                    })
                  ])
                ]),
                empty: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode(" No locations added yet ")
                ])),
                loading: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode(" Loading bookings data. Please wait. ")
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "name",
                    header: "Name"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.name), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "type.name",
                    header: "Type",
                    class: "w-[12rem]"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(data.type.name), 1)
                    ]),
                    filter: _withCtx(({ filterModel }) => [
                      _createVNode(_component_Dropdown, {
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                        options: (carsTypeOptions.value as string[]),
                        placeholder: "Select One",
                        class: "p-column-filter",
                        showClear: ""
                      }, {
                        option: _withCtx((slotProps) => [
                          _createTextVNode(_toDisplayString(slotProps.option), 1)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "supplier.name",
                    header: "Supplier"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.supplier.name), 1)
                    ]),
                    filter: _withCtx(({ filterModel }) => [
                      _createVNode(_component_Dropdown, {
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                        options: (supplierOptions.value as string[]),
                        placeholder: "Select One",
                        class: "p-column-filter",
                        showClear: ""
                      }, {
                        option: _withCtx((slotProps) => [
                          _createTextVNode(_toDisplayString(slotProps.option), 1)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "supplier.name",
                    header: "Carimage"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createElementVNode("img", {
                        class: "rounded-lg",
                        src: data.image && data.image != '' ? data.image : '/assets/nocarfound.jpeg',
                        alt: "",
                        width: "150"
                      }, null, 8, _hoisted_11)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    header: "Actions",
                    exportable: false,
                    class: "w-[22rem]"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("button", {
                          onClick: ($event: any) => (handleEditCar(data)),
                          type: "button",
                          class: "rounded rounded-bg bg-theme-button4-bg text-theme-button4-txt text-center w-[100px] py-2 uppercase text-xs"
                        }, " Edit ", 8, _hoisted_13),
                        _createElementVNode("button", {
                          onClick: ($event: any) => (_ctx.$router.push({name: 'AdminCarLocation', params: {id: data.id}})),
                          type: "button",
                          class: "rounded rounded-bg bg-theme-button1-bg text-theme-button1-txt text-center w-[100px] py-2 uppercase text-xs"
                        }, " Locations ", 8, _hoisted_14),
                        _createElementVNode("button", {
                          onClick: ($event: any) => (handleDeleteCar(data.id)),
                          type: "button",
                          class: "rounded rounded-bg bg-theme-button5-bg text-theme-button5-txt text-center w-[100px] py-2 uppercase text-xs"
                        }, " Delete ", 8, _hoisted_15)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["filters", "value", "loading"])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(SlideOver, {
      show: openSaveDialog.value,
      onClosed: _cache[5] || (_cache[5] = ($event: any) => (openSaveDialog.value = false))
    }, {
      content: _withCtx(() => [
        _createVNode(SaveCar, {
          car: editCar.value,
          carTypes: carTypes.value,
          suppliers: suppliers.value,
          onSuccess: handleSaveCarSuccess,
          onError: handleSaveCarError
        }, null, 8, ["car", "carTypes", "suppliers"])
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(SlideOver, {
      show: openLocationDialog.value,
      onClosed: _cache[6] || (_cache[6] = ($event: any) => (openLocationDialog.value = false))
    }, {
      content: _withCtx(() => [
        _createVNode(SaveCarLocation)
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"]),
    _createVNode(ConfirmationModal, {
      show: openConfirmModal.value,
      onClosed: handleConfirmClose
    }, {
      buttons: _withCtx(() => [
        _createElementVNode("button", {
          onClick: askingConfirmed,
          type: "button",
          class: _normalizeClass([isLoading.value ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer', "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto"])
        }, "Yes, delete", 2),
        _createElementVNode("button", {
          onClick: _cache[7] || (_cache[7] = ($event: any) => (openConfirmModal.value = false)),
          type: "button",
          class: "mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto"
        }, "Cancel")
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})