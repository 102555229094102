import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0"
}
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7" }
const _hoisted_4 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_5 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_6 = { class: "col-span-2 md:col-span-1" }
const _hoisted_7 = { class: "col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_8 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_9 = { class: "grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7" }
const _hoisted_10 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_11 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_12 = { class: "col-span-2 md:col-span-1" }
const _hoisted_13 = { class: "col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_14 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_15 = {
  key: 0,
  class: "text-theme-error mt-4"
}
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "flex flex-col py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0" }
const _hoisted_18 = { class: "grid grid-cols-2 mt-12" }
const _hoisted_19 = { class: "col-span-1 min-h-96 border border-gray-300" }
const _hoisted_20 = { class: "overflow-auto" }
const _hoisted_21 = { class: "col-span-1 min-h-96 border-r border-b border-t border-gray-300" }
const _hoisted_22 = { class: "overflow-auto" }

import { onMounted, ref, nextTick } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import RideDetails from '@/components/booking/RideDetails.vue'
import ChooseVehicle from '@/components/booking/ChooseVehicle.vue'
import ContactDetails from '@/components/booking/ContactDetails.vue'
import Finish from '@/components/booking/Finish.vue'
import { BookingStep, DriveType } from '@/types'
import { BOOKING_STEPS } from '@/utils/constants'
import { decodeFromQuery } from '@/services/search-service'
import { getBackendBaseUrl, isValidCardNumber, resetErrors, saveBookingNumber, savePaymentSession, savePaymentTransaction } from '@/utils/common'
import Payment from '@/composables/Payment'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
import { useRouter } from 'vue-router'
import creditCardType from 'credit-card-type'
// @ts-ignore
import $ from 'jquery'


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingTest',
  setup(__props) {

const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { createSession, updateSession, createToken, initiateAuthentication, authenticatePayer } = Payment()
const paymentDetails = ref<any>({
   card: {
      name: '',
      number: '',
      cvc: '',
      expiration: ''
   },
   token: '',
   session: null,
   order: null,
   transaction: null
})
const errors = ref<any>({
   cardName: null,
   cardNumber: null,
   cardCVC: null,
   cardExpiration: null,
   responseError: null
})
const isLoading = ref<boolean>(false)
const initiateResponsee = ref<any>(null)
const authenticatePayerResponsee = ref<any>(null)
const html = ref<any>(null)
const formContainer = ref(null)
const router = useRouter()

const checkAndCreateSession = async () : Promise<{status: boolean, session: string | null}> => {

   const sessionResponse = await createSession()
   if (sessionResponse.status == 200) {
      savePaymentSession(sessionResponse.data.session)
      return {status: true, session: sessionResponse.data.session}
   } else {
      return {status: false, session: null}
   }
}

onMounted(async () => {

   const sessionResponse = await checkAndCreateSession()
   if (!sessionResponse.status) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'Payment cannot be processed at the moment'}
   } else {
      paymentDetails.value.session = sessionResponse.session
   }
})

const handleBookingProceed = async () => {
   
   resetErrors(errors.value)
   let isValid = validateForm()

   if (isValid) {

      const bookingNumber = 'FRT65r67YHUI'
      saveBookingNumber(bookingNumber)
      paymentDetails.value.card.brand = getCardBrand(paymentDetails.value.card.number.toString())
      paymentDetails.value.order = bookingNumber
      isLoading.value = true

      const tokenResponse = await createToken(paymentDetails.value)
      if (tokenResponse.status == 201) {
         const sessionResponse = await updateSession(paymentDetails.value.session, tokenResponse.data.token, bookingNumber as string)
         
         if (sessionResponse.status == 200) {
            paymentDetails.value.transaction = sessionResponse.data.transaction
            savePaymentTransaction(paymentDetails.value.transaction)
            //initiate3DSAuthentication(paymentDetails.value.order, paymentDetails.value.transaction)
            const initiateResponse = await initiateAuthentication(paymentDetails.value.session, bookingNumber, paymentDetails.value.transaction)
            initiateResponsee.value = JSON.stringify(initiateResponse.data.response)
            
            if (initiateResponse.data.response.transaction.authenticationStatus == 'AUTHENTICATION_AVAILABLE'){
               const authenticatePayerResponse = await authenticatePayer(paymentDetails.value.session, bookingNumber, `${paymentDetails.value.transaction}`)
               authenticatePayerResponsee.value = JSON.stringify(authenticatePayerResponse.data.response)
               const response = authenticatePayerResponse.data.response
               if (response && response.response && response.response.gatewayRecommendation == 'PROCEED') {
                  const action = response.authentication.redirect.customizedHtml['3ds2'].acsUrl
                  const inputValue = response.authentication.redirect.customizedHtml['3ds2'].cReq
                  html.value = `<form id="threedsChallengeRedirectForm" target="challengeFrame" action="${action}" method="post"><input type="hidden" name="creq" value="${inputValue}"><iframe id="challengeFrame" name="challengeFrame" width="100%" height="500" ></iframe>`
                  
                  await nextTick()
                  const form = document.getElementById('threedsChallengeRedirectForm') as HTMLFormElement | null
                  if (form) {
                     form.submit()
                  }
               } else {
                  console.log('payer authentication request finished but returned a different than expected response')
               }
            } else {
               console.log('initiate authentication request finished but returned a different than expected response')
            }
         } else {
            console.log(sessionResponse)
         }
      } else {
         console.log(tokenResponse)
      }
   }
}

const validateForm = () => {
   
   let response = true
   
   if (paymentDetails.value.card.name === '') {
      errors.value.cardName = true
      response = false
   }

   if (paymentDetails.value.card.number === '') {
      errors.value.cardNumber = 'Required'
      response = false
   } else if (!isValidCardNumber(paymentDetails.value.card.number)) {
      errors.value.cardNumber = 'Invalid'
      response = false
   }

   if (paymentDetails.value.card.cvc === '') {
      errors.value.cardCVC = true
      response = false
   }

   if (paymentDetails.value.card.expiration === '') {
      errors.value.cardExpiration = true
      response = false
   }

   return response
}

const getCardBrand = (cardNumber: string) => {
   const cardType = creditCardType(cardNumber);

   if (cardType.length > 0) {
      return cardType[0].niceType
   }

   return 'Unknown'
}

const notifClosed = () : void => {
   notif.value.show = false
}

window.addEventListener('message', function(event) {

   const backendUrl = getBackendBaseUrl()
   if (backendUrl.includes(event.origin)) {

      if (event.data === 'redirect') {
         router.push({name: 'BookingWizard', params:{step: 'finish'}})
      }
   }
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TopBanner, {
      layout: "normal",
      currentPage: "Booking",
      heading: "Booking",
      bannerClass: "banner-booking"
    }),
    (!html.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass([!errors.value.cardName ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                }, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", null, "Card holder name *", -1)),
                  (errors.value.cardName)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, "(required)"))
                    : _createCommentVNode("", true)
                ], 2),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((paymentDetails.value.card.name) = $event)),
                  type: "text",
                  class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                }, null, 512), [
                  [_vModelText, paymentDetails.value.card.name]
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", {
                    class: _normalizeClass([!errors.value.cardNumber ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                  }, [
                    _cache[5] || (_cache[5] = _createElementVNode("span", null, "Card number *", -1)),
                    (errors.value.cardNumber)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(" + _toDisplayString(errors.value.cardNumber) + ")", 1))
                      : _createCommentVNode("", true)
                  ], 2),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((paymentDetails.value.card.number) = $event)),
                    type: "number",
                    class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                  }, null, 512), [
                    [_vModelText, paymentDetails.value.card.number]
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", {
                  class: _normalizeClass([!errors.value.cardCVC ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                }, [
                  _cache[6] || (_cache[6] = _createElementVNode("span", null, "CVC *", -1)),
                  (errors.value.cardCVC)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, "(required)"))
                    : _createCommentVNode("", true)
                ], 2),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((paymentDetails.value.card.cvc) = $event)),
                  type: "text",
                  class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                }, null, 512), [
                  [_vModelText, paymentDetails.value.card.cvc]
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", {
                    class: _normalizeClass([!errors.value.cardExpiration ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                  }, [
                    _cache[7] || (_cache[7] = _createElementVNode("span", null, "Expiration *", -1)),
                    (errors.value.cardExpiration)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, "(required)"))
                      : _createCommentVNode("", true)
                  ], 2),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((paymentDetails.value.card.expiration) = $event)),
                    type: "month",
                    class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                  }, null, 512), [
                    [_vModelText, paymentDetails.value.card.expiration]
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", {
              onClick: handleBookingProceed,
              class: _normalizeClass([isLoading.value ? 'cursor-not-allowed opacity-60' : 'hover:bg-white hover:text-theme-active cursor-pointer', "gap-4 mt-12 uppercase rounded-full px-8 py-4 border-2 border-theme-active bg-theme-active text-white text-size-2 flex items-center justify-center w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500"])
            }, [
              _cache[8] || (_cache[8] = _createElementVNode("span", null, "Reserve", -1)),
              _createElementVNode("span", null, [
                _createVNode(_component_font_awesome_icon, {
                  size: "lg",
                  icon: "fa-solid fa-chevron-right"
                })
              ])
            ], 2),
            (errors.value.responseError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(errors.value.responseError), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          innerHTML: html.value,
          ref_key: "formContainer",
          ref: formContainer,
          class: "challenge-form mx-auto flex items-center justify-center py-12"
        }, null, 8, _hoisted_16)),
    _createElementVNode("div", _hoisted_17, [
      _cache[11] || (_cache[11] = _createElementVNode("h2", { class: "font-bold text-head-sm" }, "Checkout API Response", -1)),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "text-center mt-2" }, "Initiate Authentication Response", -1)),
          _createElementVNode("div", _hoisted_20, _toDisplayString(initiateResponsee.value), 1)
        ]),
        _createElementVNode("div", _hoisted_21, [
          _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "text-center mt-2" }, "Authenticate Payer Response", -1)),
          _createElementVNode("div", _hoisted_22, _toDisplayString(authenticatePayerResponsee.value), 1)
        ])
      ])
    ]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"])
  ], 64))
}
}

})