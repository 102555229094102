import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "mt-8 flow-root" }
const _hoisted_3 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_4 = { class: "flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_5 = { class: "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" }
const _hoisted_6 = { class: "flex gap-2 w-full justify-end" }
const _hoisted_7 = { class: "p-input-icon-left" }
const _hoisted_8 = { class: "flex items-center gap-3" }
const _hoisted_9 = ["onClick"]

import { ref, onMounted } from 'vue'
import { NotificationType } from '@/types/index'
import { Agent } from '@/types/admin'
import Admin from '@/composables/admin/index'
import SlideOver from '@/widgets/SlideOver.vue'
import Notification from '@/widgets/Notification.vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import SaveAgent from '@/components/admin/SaveAgent.vue'
import { MenuItems } from '@headlessui/vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const { allAgents } = Admin()
const agents = ref<Agent[] | null>(null)
const isLoading = ref<boolean>(false)
const openDialog = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const filters = ref()
const loading = ref<boolean>(false)
const dt = ref()
const editAgent = ref<Agent | null>(null)

onMounted(async () => {

   await fetchAgents()
})

const fetchAgents = async () => {

   const response = await allAgents()
   if (response.status == 200) {

      let items : Agent[] = []
      response.data.forEach((item: any) => {
         items.push({
            id: item.id,
            name: item.name,
            email: item.email,
            commission: item.commission,
            phone: item.phone,
            address: item.address,
            isParent: item.is_parent,
            loyalty: {
               redeemed: item.loyalty.redeemed,
               threshold: item.loyalty?.threshold,
            }
         })
      })

      agents.value = items
   }
}

const handleEdit = (data: Agent) => {

   editAgent.value = data
   openDialog.value = true
}

const handleSaveSuccess = async () => {
   openDialog.value = false
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Agent has been saved successfully'}
   setTimeout(() => { notifClosed() }, 4000)
   
   await fetchAgents()
}

const handleSaveError = (error: string) => {
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
   setTimeout(() => { notifClosed() }, 4000)
}

const notifClosed = () : void => {
   notif.value.show = false
}

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
   }
}

initFilters()

const clearFilter = () => {
   initFilters()
}

const handleConfirmClose = () => openDialog.value = false


return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "sm:flex sm:items-center" }, [
        _createElementVNode("div", { class: "sm:flex-auto" }, [
          _createElementVNode("h1", { class: "text-base font-semibold leading-6 text-gray-900" }, "Cars"),
          _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "A list of all the agents in our database")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_DataTable, {
                ref_key: "dt",
                ref: dt,
                class: "text-sm",
                filters: filters.value,
                "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((filters).value = $event)),
                value: agents.value,
                paginator: "",
                showGridlines: "",
                rows: 10,
                dataKey: "id",
                filterDisplay: "menu",
                loading: loading.value,
                globalFilterFields: []
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, [
                      _createVNode(_component_InputText, {
                        modelValue: filters.value['global'].value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value['global'].value) = $event)),
                        placeholder: "Keyword Search"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createVNode(_component_Button, {
                      type: "button",
                      icon: "pi pi-filter-slash",
                      label: "Clear",
                      outlined: "",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (clearFilter()))
                    })
                  ])
                ]),
                empty: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" No agents yet ")
                ])),
                loading: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Loading agents data. Please wait. ")
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "name",
                    header: "Name"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.name), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "email",
                    header: "Email"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.email), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "commission",
                    header: "Commission (%)"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.commission), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "loyalty.threshold",
                    header: "Loyalty Threshold"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.loyalty.threshold), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    header: "Actions",
                    exportable: false,
                    class: "w-[22rem]"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("button", {
                          onClick: ($event: any) => (handleEdit(data)),
                          type: "button",
                          class: "rounded rounded-bg bg-theme-button4-bg text-theme-button4-txt text-center w-[100px] py-2 uppercase text-xs"
                        }, " Edit Loyalty ", 8, _hoisted_9)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["filters", "value", "loading"])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(SlideOver, {
      show: openDialog.value,
      onClosed: _cache[3] || (_cache[3] = ($event: any) => (openDialog.value = false))
    }, {
      content: _withCtx(() => [
        _createVNode(SaveAgent, {
          agent: editAgent.value,
          onSuccess: handleSaveSuccess,
          onError: handleSaveError
        }, null, 8, ["agent"])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})