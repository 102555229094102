import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "z-50 fixed top-0 w-full h-full left-0"
}

import { ref } from 'vue'
import LoaderIcon from '@/components/LoaderIcon.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Loader',
  props: ['show'],
  setup(__props) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (__props.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "bg-white h-full w-full absolute top-0 left-0" }, null, -1),
        _createElementVNode("div", { class: "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" }, [
          _createElementVNode("img", {
            class: "rounded-lg",
            src: '/assets/loader.gif',
            alt: "",
            width: "150"
          })
        ], -1)
      ])))
    : _createCommentVNode("", true)
}
}

})