import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-span-12 md:col-span-8 flex flex-col gap-6" }
const _hoisted_2 = { class: "flex flex-col gap-2" }
const _hoisted_3 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_4 = { class: "flex flex-col gap-2" }
const _hoisted_5 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_6 = { class: "flex flex-col gap-2" }
const _hoisted_7 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_8 = { class: "flex flex-col gap-2" }
const _hoisted_9 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_10 = { class: "flex flex-col justify-end gap-2" }
const _hoisted_11 = {
  key: 0,
  class: "text-ef-error ml-auto"
}

import { onMounted, ref } from 'vue'
import { NotificationType } from '@/types'
import { handleApiError, isValidEmail, loadScript } from '@/utils/common'
import Notification from '@/widgets/Notification.vue'
import Contact from '@/composables/Contact'
// @ts-ignore
import $ from 'jquery'


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactForm',
  setup(__props) {

const isLoading = ref<boolean>(false)
const recaptchaError = ref<any>(null)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const form = ref<any>({
   name: { value: '', error: false, errorMessage: '' },
   email: { value: '', error: false, errorMessage: '' },
   subject: { value: '', error: false, errorMessage: '' },
   message: { value: '', error: false, errorMessage: '' },
})
const { sendContactNotification } = Contact()

const validateForm = () : boolean => {
   let error : boolean = false
   const recaptcha = $('textarea[name="g-recaptcha-response"]')

   if (!recaptcha.length || recaptcha.val() == '' || !recaptcha.val()) {
      error = true
      recaptchaError.value = 'Please validate the recaptcha'
   } else {
      recaptchaError.value = null
   }

   if (form.value.name.value == '') {
      form.value.name.error = true
      form.value.name.errorMessage = 'Name is required'
      error = true
   }

   if (form.value.subject.value == '') {
      form.value.subject.error = true
      form.value.subject.errorMessage = 'Subject is required'
      error = true
   }

   if (form.value.email.value == '') {
      form.value.email.error = true
      form.value.email.errorMessage = 'Email is required'
      error = true
   } else if (!isValidEmail(form.value.email.value)) {
      form.value.email.error = true
      form.value.email.errorMessage = 'Email is invalid'
      error = true
   }

   return error
}

const resetFormErrors = () :void => {
   Object.keys(form.value).forEach((key: string) => {
      form.value[key].error = false
      form.value[key].errorMessage = ''
   })
}

const resetForm = () :void => {
   Object.keys(form.value).forEach((key: string) => {
      form.value[key].value = ''
   })
}

const handleSubmit = async () => {

   resetFormErrors()
   const error = validateForm()
   if (!error && !isLoading.value) {
      isLoading.value = true
      const recaptcha = $('textarea[name="g-recaptcha-response"]').val()

      const response = await sendContactNotification(form.value.name.value, form.value.email.value, form.value.subject.value, form.value.message.value, recaptcha)
   
      if (response.status == 201) {
         notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'We have notified our support team. You will hear from us soon'}
         setTimeout(() => { notifClosed() }, 4000)
         resetForm()
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
      }

      isLoading.value = false
   }
}

const notifClosed = () => {
   notif.value.show = false
}

onMounted(() => {

   var recaptchaScriptUrl = 'https://www.google.com/recaptcha/api.js'
   $('.google-recaptcha').html('<div class="g-recaptcha" data-sitekey="6LdgLVspAAAAAH4Y109CAIfw26hm_onZFQsycv1v"></div>')
   loadScript(recaptchaScriptUrl, function () {})
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-head-sm font-light text-theme-gray-4" }, "Please fill out the form below with questions or comments", -1)),
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("label", {
          for: "contact-name",
          class: "text-size-6 font-light text-theme-gray-4"
        }, "Your name", -1)),
        _createElementVNode("div", {
          class: _normalizeClass([form.value.name.error ? 'border-ef-outline' : 'border-theme-gray-7', "flex flex-row items-center border"])
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.name.value) = $event)),
            type: "text",
            id: "contact-name",
            class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
          }, null, 512), [
            [_vModelText, form.value.name.value]
          ])
        ], 2),
        (form.value.name.error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(form.value.name.errorMessage), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("label", {
          for: "contact-email",
          class: "text-size-6 font-light text-theme-gray-4"
        }, "Your email", -1)),
        _createElementVNode("div", {
          class: _normalizeClass([form.value.email.error ? 'border-ef-outline' : 'border-theme-gray-7', "flex flex-row items-center border"])
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.email.value) = $event)),
            type: "text",
            id: "contact-email",
            class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
          }, null, 512), [
            [_vModelText, form.value.email.value]
          ])
        ], 2),
        (form.value.email.error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(form.value.email.errorMessage), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[6] || (_cache[6] = _createElementVNode("label", {
          for: "contact-subject",
          class: "text-size-6 font-light text-theme-gray-4"
        }, "Your subject", -1)),
        _createElementVNode("div", {
          class: _normalizeClass([form.value.subject.error ? 'border-ef-outline' : 'border-theme-gray-7', "flex flex-row items-center border"])
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.subject.value) = $event)),
            type: "text",
            id: "contact-subject",
            class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
          }, null, 512), [
            [_vModelText, form.value.subject.value]
          ])
        ], 2),
        (form.value.subject.error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(form.value.subject.errorMessage), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[7] || (_cache[7] = _createElementVNode("label", {
          for: "contact-name",
          class: "text-size-6 font-light text-theme-gray-4"
        }, "Your message (optional)", -1)),
        _createElementVNode("div", _hoisted_9, [
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.message.value) = $event)),
            class: "w-full outline-none text-theme-gray-5 p-2 h-[250px] font-bold text-size-4 md:text-size-6"
          }, null, 512), [
            [_vModelText, form.value.message.value]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mt-6 ml-auto google-recaptcha" }, null, -1)),
        (recaptchaError.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(recaptchaError.value), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        onClick: handleSubmit,
        class: _normalizeClass([isLoading.value ? 'cursor-not-allowed opacity-40' : 'cursor-pointer hover:text-theme-active hover:bg-white transition-all ease-in-out duration-500', "gap-4 uppercase rounded-full w-full md:w-auto px-4 md:px-8 py-4 border-2 border-theme-active text-white bg-theme-active text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto"])
      }, " Submit ", 2)
    ]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"])
  ], 64))
}
}

})